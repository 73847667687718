import { Divider, Form, FormInstance, InputNumber, Select } from 'antd'
import { useState } from 'react'
import { useListingCommissionFeeTranslation } from '../../hooks/translation/useListingCommissionFeeTrans'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'

interface props {
  form: FormInstance<any>
}
export const ListingCommissionFee: React.FC<props> = ({ form }) => {
  const [comissionFeeType, setComissionFeeType] = useState(form.getFieldValue('commisionPercent'))

  const {
    selectComissionFeeText,
    defineNewComissionStructureText,
    defineNewComissionFeeText,
    defineNewComissionTypeWarningText,
    commissionPerecentText,
  } = useListingCommissionFeeTranslation()

  const { settlementText } = useListingBasicTrans()

  return (
    <>
      <Divider>{settlementText}</Divider>
      <Form.Item name="commisionPercent" label={commissionPerecentText}>
        <Select
          placeholder={selectComissionFeeText}
          onChange={(comissionFeeType: string) => {
            setComissionFeeType(comissionFeeType)
            comissionFeeType === '2.9% + 30c'
              ? form.setFieldsValue({ additionalServiceFee: 'on' })
              : form.setFieldsValue({ additionalServiceFee: 'off' })
          }}
        >
          <Select.Option value="2.9% + 30c">2.9% + 30c</Select.Option>
          <Select.Option value={10.0}>10%</Select.Option>
          <Select.Option value="defineNew">{defineNewComissionStructureText}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="additionalServiceFee" hidden>
        <span />
      </Form.Item>
      {comissionFeeType === 'defineNew' && (
        <>
          <Form.Item
            name="customComissionFee"
            label={defineNewComissionFeeText}
            rules={[{ required: true, message: defineNewComissionTypeWarningText }]}
          >
            <InputNumber step="0.01" precision={2} min={0} max={100} />
          </Form.Item>
        </>
      )}
    </>
  )
}
