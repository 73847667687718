export const calculateTransactionInterval = (startDate: moment.Moment, endDate: moment.Moment): string => {
  const diff = endDate.diff(startDate, 'days')
  if (diff <= 3) {
    return 'HOUR'
  } else if (diff > 3 && diff <= 60) {
    return 'DAY'
  } else if (diff > 60 && diff <= 120) {
    return 'WEEK'
  } else if (diff > 120) {
    return 'MONTH'
  }
  return 'DAY'
}
