import { Checkbox, Col, Form, Input, Select } from 'antd'
import { usePricingSchemasTranslation } from '../../../hooks/translation/usePricingSchemaTranslation'
import { InfoTextWrapper } from '../../../atom/price'
import { Tenant } from '../../../models/tenant'
import { ListingInfo, PricingSchema } from '../../../models/price'
import { redInfoWithISVG } from '../../../assets/svg/info'
import { usePlanTranslation } from '../../../hooks/translation/usePlanTranslation'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { ReactElement, useEffect } from 'react'
import { GetListings } from 'services/data-provider/listing'
import { usePricingSchemas } from 'hooks/usePricingSchemas'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelector } from 'hooks/useSelector'
import { NewListing } from 'models/listing'
import CoreBadge from 'components/badge/Badge'
import { ListingsResponse } from 'models/http'
import { useAppState } from 'state'
import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'

interface props {
  tenant: Tenant
  listings: NewListing[]
  nameExists: boolean | undefined
  selectedPricingSchema?: PricingSchema
  allListings: NewListing[]
  type: string
  nameCheck: (value: string, selectedPricingSchema?: PricingSchema) => void
  getPricingNames: (tenantId: number) => void
  setListings: (listings: NewListing[]) => void
  setAllListings: (listings: NewListing[]) => void
  defaultListings?: NewListing[]
  disableChargerId?: boolean
}

export const NameSectionModal: React.FC<props> = ({
  tenant,
  listings,
  selectedPricingSchema,
  nameExists,
  allListings,
  type,
  nameCheck,
  getPricingNames,
  setListings,
  setAllListings,
  defaultListings,
  disableChargerId,
}) => {
  const { isMockUpEnabled } = useAppState()
  const { nameInUseText, selectedListingsInfoText, selectedListingsText } = usePricingSchemasTranslation()
  const { nameText } = usePlanTranslation()
  const { tenantText } = useGeneralTranslation()
  const { chargerIdsText } = useChargerTranslation()
  const { setError, setLoading } = usePricingSchemas()
  const {
    multiListingTitleAndIdHandleChange,
    multiListingTitleAndIdDebounceFetcher,
    multiListingTitleAndIdHandleValue,
    multiHandlePlaceholder,
    siteLabelCleaner,
  } = useSelector()

  const formatAppliedListingToListings = (appliedListings?: ListingInfo[]) => {
    return appliedListings?.map((listing) => ({
      id: listing.listingId,
      title: listing.listingTitle,
    }))
  }

  const currentDefaultValues = defaultListings
    ? defaultListings
    : type === 'create'
    ? allListings
    : formatAppliedListingToListings(selectedPricingSchema?.appliedListings)

  useEffect(() => {
    getPricingNames(Number(tenant.id))
    if (defaultListings) return

    if (tenant) {
      GetListings(isMockUpEnabled, { defaultTenant: tenant, allListings: true })
        .then((listings: NewListing[] | ListingsResponse) => {
          if (Array.isArray(listings)) {
            setAllListings(listings)
          }
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false))
    }
  }, [defaultListings])

  useEffect(() => {
    if (currentDefaultValues && !listings.length) {
      setListings((currentDefaultValues as NewListing[]) || [])
    }
  }, [])

  const renderListingOption = (
    listing: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={listing.id} value={listing.id} label={listing.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.title === listing.title) > -1}
      >
        {listing.title}
      </Checkbox>
    </Select.Option>
  )

  return (
    <>
      <Col span={10}>
        <Form.Item label={<>{tenantText}:</>} style={{ marginBottom: '25px' }}>
          <CoreBadge title={siteLabelCleaner(tenant?.combineName || tenant?.displayName || tenant?.name)} />
        </Form.Item>
      </Col>
      <Form.Item
        className="name-title"
        rules={[{ required: true }]}
        validateStatus={nameExists ? 'error' : ''}
        label={<>{nameText}:</>}
        name="name"
        style={{ marginRight: '10px', marginBottom: '16px' }}
      >
        <Input
          onChange={(e) => nameCheck(e.target.value, selectedPricingSchema)}
          placeholder={nameText}
          style={{ fontSize: '14px', paddingLeft: '12px' }}
        />
      </Form.Item>
      {nameExists && (
        <InfoTextWrapper>
          <div style={{ marginBottom: '8px' }}>{redInfoWithISVG}</div>{' '}
          <span className="danger-text">{nameInUseText}</span>
        </InfoTextWrapper>
      )}
      <Form.Item
        label={<>{chargerIdsText}:</>}
        name="listing"
        className="listing"
        //help={selectedListingsInfoText}
        help={
          listings.length
            ? `${selectedListingsText}${listings.map(({ title }) => title).join(', ') || ''}`
            : selectedListingsInfoText
        }
      >
        <ReusableSelector
          showSearch={true}
          onClear={() => setListings([])}
          isSingle={false}
          showMagnifySVG={true}
          maxTagCount={0}
          isDebounceFetcher={true}
          disabled={!!defaultListings || disableChargerId}
          handlePlaceholder={multiHandlePlaceholder('title', 'Charger IDs')}
          debounceFetcher={multiListingTitleAndIdDebounceFetcher}
          customWidth="252px"
          dropdownClassName={'not-in-modal-selector dropdown-large-font'}
          placeholder={chargerIdsText}
          dropDownList={allListings}
          defaultValues={currentDefaultValues}
          oneTimeUpdateDefaultValues={type !== 'create'}
          selectAllOptions={true}
          onOptionsChange={(listing) => setListings(listing)}
          handleOptionChange={multiListingTitleAndIdHandleChange}
          renderOption={renderListingOption}
          handleValue={multiListingTitleAndIdHandleValue}
          isMockUpEnabled={isMockUpEnabled}
        />
      </Form.Item>
    </>
  )
}
