import { addQueryString } from 'helpers/url'
import { log } from '../../logger'
import { apiClient } from './client'
import { TenantPartnersResponse } from 'models/http'
import { mockPartnersRefResponse } from 'mock/tenant-partner-mock'

export async function FindPartners(isMockUpEnabled: boolean, name?: string): Promise<TenantPartnersResponse> {
  let url = '/partners'

  let queryParams: { [key: string]: string | boolean } = {}

  if (name) {
    queryParams['name'] = `${name}`
  }
  url = addQueryString(url, queryParams)

  log('fetching partners', { url })
  if (isMockUpEnabled) {
    return Promise.resolve(mockPartnersRefResponse)
  }
  return apiClient(url, {})
}
