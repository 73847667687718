import { Button, Form, InputNumber, Select } from 'antd'
import { NewListing } from '../../models/listing'
import { useAppState } from '../../state'
import { MustBeRequired } from '../rules/rules'
import { layout } from '../../atom/form/modal-layout'
import { AlertError } from '../error'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { useListingCommissionFeeTranslation } from 'hooks/translation/useListingCommissionFeeTrans'
import { useListingAuthorTranslation } from 'hooks/translation/useListingAuthorTrans'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { useTenantTabPricingTranslation } from 'hooks/translation/useTenantTabPricingTranslation'
import { useListingSettlement } from 'hooks/useListingSettlement'
import { UpdateListingWrapper } from 'atom/listings'

interface props {
  listings: NewListing[]
  onOk: () => void
}

export const UpdateListingSettlement: React.FC<props> = ({ listings, onOk }) => {
  const { language } = useAppState()
  const [form] = Form.useForm()
  const {
    error,
    loading,
    comissionFeeType,
    stripeAccountOptions,
    initialValues,
    onFinish,
    setComissionFeeType,
  } = useListingSettlement(form, listings, onOk)
  const { applyText } = useGeneralTranslation()
  const { commissionFeeText } = usePricingSchemasTranslation()
  const { stripeAccountText } = useTenantTabPricingTranslation()
  const { selectStripeAccountText } = useListingAuthorTranslation()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }
  const {
    selectComissionFeeText,
    defineNewComissionStructureText,
    swtchCommissionFeeText,
  } = useListingCommissionFeeTranslation()

  return (
    <>
      <AlertError error={error} />
      <Form
        form={form}
        {...chooseLayout()}
        onFinish={onFinish}
        labelCol={{ span: 7.5 }}
        initialValues={initialValues}
        labelWrap={true}
        labelAlign="left"
      >
        <UpdateListingWrapper>
          <Form.Item
            name="commission_percent"
            label={commissionFeeText}
            rules={[MustBeRequired()]}
            className="commission-percent"
          >
            <Select
              placeholder={selectComissionFeeText}
              onChange={(comissionFeeType: string) => {
                setComissionFeeType(comissionFeeType)
                comissionFeeType === '2.9% + 30c'
                  ? form.setFieldsValue({ additionalServiceFee: 'on' })
                  : form.setFieldsValue({ additionalServiceFee: 'off' })
              }}
            >
              <Select.Option value="2.9% + 30c">2.9% + 30c</Select.Option>
              <Select.Option value="10%">10%</Select.Option>
              <Select.Option value="define_new">{defineNewComissionStructureText}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="additional_service_fee" hidden>
            <span />
          </Form.Item>
          {comissionFeeType === 'define_new' && (
            <>
              <Form.Item className="define-new" name="custom_commission_fee" label={<>{swtchCommissionFeeText}:</>}>
                <InputNumber step={2} precision={2} min={0} max={100} controls={false} />
              </Form.Item>
            </>
          )}
          <Form.Item label={stripeAccountText} name="stripe_account">
            <Select
              className="listing-author-selector"
              showSearch
              disabled={loading}
              style={{ minWidth: 175 }}
              placeholder={selectStripeAccountText}
              dropdownMatchSelectWidth={false}
              options={stripeAccountOptions}
              loading={loading}
            />
          </Form.Item>
        </UpdateListingWrapper>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {applyText}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
