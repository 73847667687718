import { Button, Checkbox, Form, Select, Spin, Switch, Tooltip } from 'antd'
import { ReactElement, useState } from 'react'
import { UserPermissions, userInviteInfo } from '../../../models/user'
import { TenantRef } from '../../../models/tenant'
import { useUserTranslation } from '../../../hooks/translation/useUserTranslation'
import { addSVG } from '../../../assets/svg/add'
import { DropdownWrapper, SelectWrapper, SvgSpacing, SwitchLabel } from '../../../atom/user-edit'
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { useAppState } from '../../../state'
import {
  AddMoreWrapper,
  AdminViewBody,
  AdminViewInputBody,
  LabelWrapper,
  MobileBody,
  TenantInputBodyAdminView,
  PermissionWrapper,
  TenantInputMobileBody,
} from '../../../atom/user-invite'
import { HorizontalLine } from '../../../atom/horizontal-line'
import { theme } from 'theme'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { useSelector } from 'hooks/useSelector'
import { DiscountSchema } from 'models/discount'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { GetAllAccessPlans } from 'services/data-provider/access-plan'
import { AccessPlan } from 'models/access-plan'
import { AccessPlanObj } from '../user-edit-redesign'

interface props {
  tenantInputs: number
  tenants: TenantRef[]
  tenantDropdownData: any
  userPermissions: UserPermissions[]
  loading: boolean
  allAccessPlans: AccessPlanObj
  setTenantInputs: (tenantInputs: any) => void
  setTenantDropdownData: (tenantDropdownData: any) => void
  setUserPermissions: (userPermissions: UserPermissions[]) => void
  setChanged: (disableSave: boolean) => void
  setAllAccessPlans: (allAccessPlans: { [key: number]: AccessPlan[] }) => void
}

const EditForm: React.FC<props> = ({
  tenantInputs,
  tenants,
  tenantDropdownData,
  userPermissions,
  loading,
  allAccessPlans,
  setTenantInputs,
  setTenantDropdownData,
  setUserPermissions,
  setChanged,
  setAllAccessPlans,
}) => {
  const [form] = Form.useForm()
  const {
    tenantsText,
    driverText,
    roleText,
    managerText,
    viewerText,
    chargerOperatorText,
    addPermissionsText,
    tooltipText,
    discountPlanText,
  } = useUserTranslation()
  const { accessPlanText } = useTenantTranslation()
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const {
    siteLabelCleaner,
    multiDiscountDebounceFetcher,
    multiDiscountHandleChange,
    multiDiscountPlaceholder,
    multiHandlePlaceholder,
    multiHandleChange,
    renderMultiOptions,
    multiAccessPlanHandleValue,
  } = useSelector()
  const isMobile = IsMobile()
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: theme.colors.primary }} spin />

  const [resetSelector, setResetSelector] = useState<{ [index: number]: boolean }>({})
  //To filter out charger operator if the user is not admin
  const userPermissionInfo = userInviteInfo.filter(
    (item) => currentUser?.role === 'admin' || item !== 'charger_operator',
  )
  const roleTextTransform = {
    none: '-',
    manager: managerText,
    charger_operator: chargerOperatorText,
    viewer: viewerText,
  }

  const onClearSelector = (index: number) => () => {
    const updatedResetSelector = {
      ...resetSelector,
      [index]: false,
    }
    setResetSelector(updatedResetSelector)
  }

  const renderOption = (tenant: TenantRef, index: any): ReactElement => {
    return (
      <Select.Option style={{ marginTop: '10px' }} key={tenant.id} value={siteLabelCleaner(tenant.combineName)}>
        <DropdownWrapper selected={userPermissions[index].name === siteLabelCleaner(tenant.combineName)}>
          {siteLabelCleaner(tenant.combineName)}
        </DropdownWrapper>
      </Select.Option>
    )
  }

  const handleRoleChange = (value: string, index: number) => {
    setChanged(true)
    const tempData = [...userPermissions]
    tempData[index] = {
      ...tempData[index],
      access: value,
    }
    setUserPermissions([...tempData])
  }

  const handleDriverChecked = (index: number) => {
    setChanged(true)
    const tempData = userPermissions
    tempData[index] = {
      ...tempData[index],
      driver: !tempData[index].driver,
    }
    setUserPermissions([...tempData])
  }

  const handleDiscount = (selectedDiscounts: DiscountSchema[], index: number) => {
    setChanged(true)
    const tempData = userPermissions
    tempData[index] = { ...tempData[index], discountPlan: selectedDiscounts }
    setUserPermissions([...tempData])
  }

  const handleAccessPlan = (accessPlans: AccessPlan[], index: number) => {
    setChanged(true)
    const tempData = userPermissions
    tempData[index] = { ...tempData[index], accessPlan: accessPlans }
    setUserPermissions([...tempData])
  }

  const handleTenantChange = (value: string, tenantId: string, index: number) => {
    setChanged(true)
    const tempData = userPermissions

    GetAllAccessPlans(isMockUpEnabled, Number(tenantId))
      .then((resp) => {
        if (!('error' in resp)) {
          setAllAccessPlans({ ...allAccessPlans, [tenantId]: resp })
        }
      })
      .catch((err) => console.log(err))

    tempData[index] = {
      name: value,
      id: tenantId,
      access: tempData[index].access,
      driver: tempData[index].driver,
    }
    setUserPermissions([...tempData])
    const tempTenantsArr = tenants.filter((tenant) => value !== siteLabelCleaner(tenant.combineName))
    tenants.filter((singleTenant) => {
      if (value === siteLabelCleaner(singleTenant.combineName)) {
        return setTenantDropdownData({
          ...tenantDropdownData,
          [(index + 1) as keyof typeof tenantDropdownData]: [singleTenant, ...tempTenantsArr],
        })
      } else return null
    })
    const updatedResetSelector = {
      ...resetSelector,
      [index]: true,
    }
    setResetSelector(updatedResetSelector)
  }

  const renderDiscountOption = (
    { id, name }: DiscountSchema,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={name} label={name}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.name === name) > -1}
        >
          {name}
        </Checkbox>
      </Select.Option>
    )
  }

  return (
    <>
      {!isMobile ? (
        <Form form={form} labelAlign="right" layout="vertical">
          <>
            <HorizontalLine />
            <Spin spinning={loading} indicator={antIcon} style={{ background: 'white', top: '7px' }}>
              <AdminViewInputBody>
                <TenantInputBodyAdminView>
                  {userPermissions.map((data, tenantInputIndex) => {
                    return (
                      <>
                        {tenantDropdownData[1] && tenantInputIndex === 0 && (
                          <PermissionWrapper>
                            <div style={{ display: 'flex' }}>
                              <Form.Item
                                className="tenant-select"
                                label={<LabelWrapper className="regular-cap">{tenantsText}</LabelWrapper>}
                              >
                                <SelectWrapper>
                                  <Select
                                    dropdownMatchSelectWidth={false}
                                    showSearch
                                    onChange={(value, { key }: any) => handleTenantChange(value, key, tenantInputIndex)}
                                    value={data.name?.length ? data.name : undefined}
                                    placeholder={tenantsText}
                                  >
                                    {tenantDropdownData[
                                      (tenantInputIndex + 1) as keyof typeof tenantDropdownData
                                    ].map((v: any) => renderOption(v, tenantInputIndex))}
                                  </Select>
                                </SelectWrapper>
                              </Form.Item>
                              <Form.Item
                                className="role-select"
                                key="role"
                                label={<LabelWrapper className="regular-cap">{roleText}</LabelWrapper>}
                              >
                                <SelectWrapper>
                                  <Select
                                    showSearch
                                    onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                                    defaultValue={data.access}
                                    value={data.access?.length ? data.access : undefined}
                                    placeholder={roleText}
                                  >
                                    {userPermissionInfo.map((role) => (
                                      <Select.Option key={role} value={role}>
                                        <DropdownWrapper selected={data.access === role}>
                                          {roleTextTransform[role]}
                                        </DropdownWrapper>
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </SelectWrapper>
                              </Form.Item>
                            </div>
                            <AdminViewBody>
                              <Form.Item
                                className="access-plan-select"
                                label={
                                  <LabelWrapper className="regular-cap">
                                    {driverText} {accessPlanText}
                                    <Tooltip
                                      overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                                      placement="top"
                                      title={tooltipText}
                                    >
                                      <ExclamationCircleFilled
                                        style={{
                                          fontSize: theme.fontSizes[3],
                                          marginLeft: '5px',
                                          color: theme.colors.lightGray,
                                        }}
                                      />
                                    </Tooltip>
                                  </LabelWrapper>
                                }
                              >
                                <ReusableSelector
                                  showSearch
                                  onOptionsChange={(option) => handleAccessPlan(option, tenantInputIndex)}
                                  isSingle={false}
                                  placeholder={accessPlanText}
                                  customHeight="32px"
                                  customWidth="221px"
                                  showMagnifySVG={false}
                                  maxTagCount={0}
                                  isDebounceFetcher={false}
                                  defaultValues={
                                    data.accessPlan
                                      ? data.accessPlan?.filter(
                                          (plan) =>
                                            plan.accessType === 'restricted' ||
                                            (plan as any)['access_type'] === 'restricted',
                                        )
                                      : []
                                  }
                                  dropDownList={allAccessPlans[data.id]
                                    ?.filter((plan) => plan.accessType === 'restricted')
                                    ?.map((item) => ({ ...item, label: item.name }))}
                                  handlePlaceholder={multiHandlePlaceholder('name', accessPlanText)}
                                  handleOptionChange={multiHandleChange('id')}
                                  renderOption={renderMultiOptions({
                                    val: 'id',
                                    label: 'name',
                                  })}
                                  handleValue={multiAccessPlanHandleValue}
                                  isMockUpEnabled={isMockUpEnabled}
                                  reset={resetSelector[tenantInputIndex]}
                                  onClear={onClearSelector(tenantInputIndex)}
                                />
                              </Form.Item>
                              <Form.Item
                                className="discount-schema-select"
                                key="discount-schema"
                                label={<LabelWrapper className="regular-cap">{discountPlanText}</LabelWrapper>}
                              >
                                <ReusableSelector
                                  showSearch
                                  onOptionsChange={(option) => handleDiscount(option, tenantInputIndex)}
                                  defaultValues={data.discountPlan ?? []}
                                  dropDownList={data.discountPlan ?? []}
                                  reset={resetSelector[tenantInputIndex]}
                                  onClear={onClearSelector(tenantInputIndex)}
                                  isSingle={false}
                                  placeholder={discountPlanText}
                                  showMagnifySVG={false}
                                  maxTagCount={0}
                                  isDebounceFetcher={true}
                                  handlePlaceholder={multiDiscountPlaceholder}
                                  debounceFetcher={multiDiscountDebounceFetcher(parseFloat(data.id))}
                                  handleOptionChange={multiDiscountHandleChange}
                                  renderOption={renderDiscountOption}
                                  isMockUpEnabled={isMockUpEnabled}
                                />
                              </Form.Item>
                            </AdminViewBody>
                          </PermissionWrapper>
                        )}
                        {tenantDropdownData[(tenantInputIndex + 1) as keyof typeof tenantDropdownData] &&
                          tenantInputIndex > 0 && (
                            <PermissionWrapper>
                              <div style={{ display: 'flex' }}>
                                <Form.Item
                                  className="tenant-select"
                                  label={<LabelWrapper className="regular-cap">{tenantsText}</LabelWrapper>}
                                >
                                  <SelectWrapper>
                                    <Select
                                      dropdownMatchSelectWidth={false}
                                      showSearch
                                      onChange={(value, { key }: any) =>
                                        handleTenantChange(value, key, tenantInputIndex)
                                      }
                                      value={data.name.length ? data.name : undefined}
                                      placeholder={tenantsText}
                                    >
                                      {tenantDropdownData[
                                        (tenantInputIndex + 1) as keyof typeof tenantDropdownData
                                      ].map((v: any) => renderOption(v, tenantInputIndex))}
                                    </Select>
                                  </SelectWrapper>
                                </Form.Item>
                                <Form.Item
                                  className="role-select"
                                  key="role"
                                  label={<LabelWrapper className="regular-cap">{roleText}</LabelWrapper>}
                                >
                                  <SelectWrapper>
                                    <Select
                                      onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                                      defaultValue={data.access.length ? data.access : undefined}
                                      value={data.access.length ? data.access : undefined}
                                      placeholder={roleText}
                                    >
                                      {userPermissionInfo.map((role) => (
                                        <Select.Option key={role} value={role}>
                                          <DropdownWrapper selected={data.access === role}>
                                            {roleTextTransform[role]}
                                          </DropdownWrapper>
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </SelectWrapper>
                                </Form.Item>
                              </div>
                              <AdminViewBody key={tenantInputIndex}>
                                <Form.Item
                                  className="access-plan-select"
                                  label={
                                    <LabelWrapper className="regular-cap">
                                      {driverText} {accessPlanText}
                                      <Tooltip
                                        overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                                        placement="top"
                                        title={tooltipText}
                                      >
                                        <ExclamationCircleFilled
                                          style={{
                                            fontSize: theme.fontSizes[3],
                                            marginLeft: '5px',
                                            color: theme.colors.lightGray,
                                          }}
                                        />
                                      </Tooltip>
                                    </LabelWrapper>
                                  }
                                >
                                  <ReusableSelector
                                    showSearch
                                    onOptionsChange={(option) => handleAccessPlan(option, tenantInputIndex)}
                                    isSingle={false}
                                    placeholder={accessPlanText}
                                    customHeight="32px"
                                    customWidth="221px"
                                    showMagnifySVG={false}
                                    maxTagCount={0}
                                    isDebounceFetcher={false}
                                    defaultValues={
                                      data.accessPlan
                                        ? data.accessPlan?.filter(
                                            (plan) =>
                                              plan.accessType === 'restricted' ||
                                              (plan as any)['access_type'] === 'restricted',
                                          )
                                        : []
                                    }
                                    dropDownList={allAccessPlans[data.id]
                                      ?.filter((plan) => plan.accessType === 'restricted')
                                      ?.map((item) => ({
                                        ...item,
                                        label: item.name,
                                      }))}
                                    handlePlaceholder={multiHandlePlaceholder('name', accessPlanText)}
                                    handleOptionChange={multiHandleChange('id')}
                                    renderOption={renderMultiOptions({
                                      val: 'id',
                                      label: 'name',
                                    })}
                                    handleValue={multiAccessPlanHandleValue}
                                    isMockUpEnabled={isMockUpEnabled}
                                    reset={resetSelector[tenantInputIndex]}
                                    onClear={onClearSelector(tenantInputIndex)}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className="discount-schema-select"
                                  key="discount-schema"
                                  label={<LabelWrapper className="regular-cap">{discountPlanText}</LabelWrapper>}
                                >
                                  <ReusableSelector
                                    showSearch
                                    onOptionsChange={(option) => handleDiscount(option, tenantInputIndex)}
                                    defaultValues={data.discountPlan}
                                    dropDownList={data.discountPlan}
                                    reset={resetSelector[tenantInputIndex]}
                                    onClear={onClearSelector(tenantInputIndex)}
                                    isSingle={false}
                                    placeholder={discountPlanText}
                                    showMagnifySVG={false}
                                    maxTagCount={0}
                                    isDebounceFetcher={true}
                                    handlePlaceholder={multiDiscountPlaceholder}
                                    debounceFetcher={multiDiscountDebounceFetcher(parseFloat(data.id))}
                                    handleOptionChange={multiDiscountHandleChange}
                                    renderOption={renderDiscountOption}
                                    isMockUpEnabled={isMockUpEnabled}
                                  />
                                </Form.Item>
                              </AdminViewBody>
                            </PermissionWrapper>
                          )}
                      </>
                    )
                  })}
                </TenantInputBodyAdminView>
              </AdminViewInputBody>
              <AddMoreWrapper>
                <Form.Item className="add-more-button">
                  <Button
                    style={{
                      color: theme.colors.primary,
                      padding: 0,
                      height: 'fit-content',
                      marginBottom: '0px',
                      letterSpacing: '0.05em',
                    }}
                    type="link"
                    onClick={() => {
                      setTenantInputs((currentTenantInputs: any) => currentTenantInputs + 1)
                      setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
                      setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
                    }}
                  >
                    <SvgSpacing>{addSVG}</SvgSpacing>
                    {addPermissionsText}
                  </Button>
                </Form.Item>
              </AddMoreWrapper>
            </Spin>
          </>
        </Form>
      ) : (
        <Form form={form} labelAlign="right" layout="vertical">
          <>
            <Spin spinning={loading} indicator={antIcon} style={{ background: 'white', top: '7px' }}>
              <TenantInputMobileBody>
                {userPermissions.map((data, tenantInputIndex) => {
                  return (
                    <MobileBody key={tenantInputIndex}>
                      {tenantDropdownData[1] && tenantInputIndex === 0 && (
                        <>
                          <HorizontalLine customMargin="0px -24px 24px" />
                          <Form.Item
                            className="tenant-select"
                            label={<LabelWrapper className="regular-cap">{tenantsText}</LabelWrapper>}
                          >
                            <SelectWrapper>
                              <Select
                                dropdownMatchSelectWidth={false}
                                showSearch
                                onChange={(value, { key }: any) => handleTenantChange(value, key, tenantInputIndex)}
                                value={data.name}
                              >
                                {tenantDropdownData[
                                  (tenantInputIndex + 1) as keyof typeof tenantDropdownData
                                ].map((v: any) => renderOption(v, tenantInputIndex))}
                              </Select>
                            </SelectWrapper>
                          </Form.Item>
                          <Form.Item
                            className="role-select"
                            key="role"
                            label={<LabelWrapper className="regular-cap">{roleText}</LabelWrapper>}
                          >
                            <SelectWrapper>
                              <Select
                                onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                                defaultValue={data.access}
                                value={data.access}
                              >
                                {userPermissionInfo.map((role) => (
                                  <Select.Option key={role} value={role}>
                                    <DropdownWrapper selected={data.access === role}>
                                      {roleTextTransform[role]}
                                    </DropdownWrapper>
                                  </Select.Option>
                                ))}
                              </Select>
                            </SelectWrapper>
                          </Form.Item>
                          <Form.Item
                            className="switch"
                            key="driver"
                            label={
                              <>
                                {driverText}
                                <Tooltip
                                  overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                                  placement="top"
                                  title={tooltipText}
                                >
                                  <ExclamationCircleFilled
                                    style={{
                                      fontSize: theme.fontSizes[3],
                                      marginLeft: '5px',
                                      color: theme.colors.lightGray,
                                    }}
                                  />
                                </Tooltip>
                              </>
                            }
                          >
                            <div>
                              <SwitchLabel>Off</SwitchLabel>
                              <Switch
                                style={{
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                  backgroundColor: data.driver ? theme.colors.primary : '#bfbfbf',
                                }}
                                onChange={() => handleDriverChecked(tenantInputIndex)}
                                checked={data.driver}
                              />
                              <SwitchLabel>On</SwitchLabel>
                            </div>
                          </Form.Item>
                        </>
                      )}
                      {tenantDropdownData[(tenantInputIndex + 1) as keyof typeof tenantDropdownData] &&
                        tenantInputIndex > 0 && (
                          <>
                            <HorizontalLine />
                            <Form.Item
                              className="tenant-select"
                              label={<LabelWrapper className="regular-cap">{tenantsText}</LabelWrapper>}
                            >
                              <SelectWrapper>
                                <Select
                                  dropdownMatchSelectWidth={false}
                                  showSearch
                                  onChange={(value, { key }: any) => handleTenantChange(value, key, tenantInputIndex)}
                                  value={data.name}
                                >
                                  {tenantDropdownData[
                                    (tenantInputIndex + 1) as keyof typeof tenantDropdownData
                                  ].map((v: any) => renderOption(v, tenantInputIndex))}
                                </Select>
                              </SelectWrapper>
                            </Form.Item>
                            <Form.Item
                              className="role-select"
                              key="role"
                              label={<LabelWrapper className="regular-cap">{roleText}</LabelWrapper>}
                            >
                              <SelectWrapper>
                                <Select
                                  onChange={(value) => handleRoleChange(value, tenantInputIndex)}
                                  defaultValue={data.access}
                                  value={data.access}
                                >
                                  {userPermissionInfo.map((role) => (
                                    <Select.Option key={role} value={role}>
                                      <DropdownWrapper selected={data.access === role}>
                                        {roleTextTransform[role]}
                                      </DropdownWrapper>
                                    </Select.Option>
                                  ))}
                                </Select>
                              </SelectWrapper>
                            </Form.Item>
                            <Form.Item
                              className="switch"
                              key="driver"
                              label={
                                <>
                                  {driverText}
                                  <Tooltip
                                    overlayInnerStyle={{ borderRadius: '5px', width: '172px', padding: '7px' }}
                                    placement="top"
                                    title={tooltipText}
                                  >
                                    <ExclamationCircleFilled
                                      style={{
                                        fontSize: theme.fontSizes[3],
                                        marginLeft: '5px',
                                        color: theme.colors.lightGray,
                                      }}
                                    />
                                  </Tooltip>
                                </>
                              }
                            >
                              <SwitchLabel>Off</SwitchLabel>
                              <Switch
                                style={{
                                  marginLeft: '5px',
                                  marginRight: '5px',
                                  backgroundColor: data.driver ? theme.colors.primary : '#bfbfbf',
                                }}
                                onChange={() => handleDriverChecked(tenantInputIndex)}
                                checked={data.driver}
                              />
                              <SwitchLabel>On</SwitchLabel>
                            </Form.Item>
                          </>
                        )}
                    </MobileBody>
                  )
                })}
              </TenantInputMobileBody>
              <HorizontalLine />
              <Button
                style={{
                  color: theme.colors.primary,
                  padding: 0,
                  height: 'fit-content',
                  marginBottom: '0px',
                  letterSpacing: '0.05em',
                }}
                type="link"
                onClick={() => {
                  setTenantInputs((currentTenantInputs: any) => currentTenantInputs + 1)
                  setUserPermissions([...userPermissions, { name: '', id: '', access: '', driver: false }])
                  setTenantDropdownData({ ...tenantDropdownData, [tenantInputs + 1]: [...tenants] })
                }}
              >
                <SvgSpacing>{addSVG}</SvgSpacing>
                {addPermissionsText}
              </Button>
            </Spin>
          </>
        </Form>
      )}
    </>
  )
}

export default EditForm
