import { TenantFilter } from '../../models/filter'

export const tenantQuery = (f: TenantFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.defaultTenant) {
    queryParams['tenant_id'] = `${f.defaultTenant.id}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['term'] = `${f.tenants.map((t) => t.id)}`
  }

  if (f.country && f.country.length !== 0) {
    queryParams['country'] = `${f.country}`
  }
  if (f.province && f.province.length !== 0) {
    queryParams['province'] = `${f.province}`
  }

  if (f.taggings) {
    queryParams['taggings'] = `${f.taggings.map((t) => t.id)}`
  }

  if (f.status && f.status.length !== 0) {
    queryParams['status'] = f.status.join(',')
  }

  if (f.listings && f.listings.length !== 0) {
    queryParams['listing_ids'] = f.listings.map((l) => l.id).join(',')
  }

  if (f.masterAccounts && f.masterAccounts.length !== 0) {
    queryParams['master_account_ids'] = f.masterAccounts.map((ma) => ma.id).join(',')
  }

  return queryParams
}
