import moment from 'moment'
import { PricingResponse } from '../models/http'
import { PricingSchema } from '../models/price'
import { mockDiscountPlanUser } from './user-mock'
import { DiscountSchema } from 'models/discount'
import { mockTenantsRef } from 'services/data-provider/mock'

export const mockPricingSchema = [
  {
    id: 1,
    name: 'test pricing schema 1',
    tenantId: mockTenantsRef[0].id,
    state: 'active',
    priceType: 'Hour',
    flatFeeCents: 100,
    demandResponseFeeCents: 0,
    guestFeeCents: 50,
    commissionPercent: 10.0,
    paymentLimitCents: 10000,
    timeOfDayPriceWeekday: [Array(48).fill(100)],
    timeOfDayPriceWeekend: Array(48).fill(100),
    enableAddtionalServiceFee: false,
    enablePricingCurtailment: false,
    loiteringType: 'none',
    loiteringTimeLimitMinutes: 0,
    loiteringGracePeriodMinutes: 0,
    timeOfDayLoiteringWeekday: Array(48).fill(200),
    timeOfDayLoiteringWeekend: Array(48).fill(200),
    loiteringMaximumCents: 10000,
    appliedListings: [
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
    ],
  },
  {
    id: 2,
    name: 'Test',
    tenantId: Number(mockTenantsRef[1].id),
    state: 'active',
    priceType: 'Hour',
    flatFeeCents: 0,
    demandResponseFeeCents: 0,
    guestFeeCents: 50,
    commissionPercent: 10.0,
    paymentLimitCents: 0,
    timeOfDayPriceWeekday: Array(48).fill(100),
    timeOfDayPriceWeekend: Array(48).fill(100),
    enableAddtionalServiceFee: false,
    enablePricingCurtailment: false,
    loiteringType: 'none',
    loiteringTimeLimitMinutes: 0,
    loiteringGracePeriodMinutes: 0,
    timeOfDayLoiteringWeekday: Array(48).fill(0),
    timeOfDayLoiteringWeekend: Array(48).fill(0),
    loiteringMaximumCents: 10000,
    appliedListings: [
      {
        listingTitle: 'FR01',
        listingId: 1,
      },
      {
        listingTitle: 'fr01',
        listingId: 1,
      },
    ],
  },
] as PricingSchema[]

export const mockPricingSchemaResp = (page: number) => {
  return {
    data: mockPricingSchema,
    pagination: {
      currentPage: page || 0,
      perPage: 6,
      totalEntries: mockPricingSchema.length,
    },
  } as PricingResponse
}

export const mockPricingSchemaNamesResp = () => {
  return ['test', 'testing']
}

export const mockDiscounts = [
  {
    id: 517,
    name: 'Local Loitering Discount 4#5 b4e849',
    promoCode: 'lpc_4#5_6af5a0',
    tenantId: Number(mockTenantsRef[3].id),
    usageStatus: 'active',
    discountPeriodWeekday: Array(48).fill(1),
    discountPeriodWeekend: Array(48).fill(1),
    discountPercentageWeekday: Array(48).fill(0),
    discountPercentageWeekend: Array(48).fill(0),
    loiteringSuspensionPeriodWeekday: Array(48).fill(0),
    loiteringSuspensionPeriodWeekend: Array(48).fill(0),
    ignoreChargingFee: false,
    ignoreLoiteringFee: true,
    ignoreConstantFee: false,
    ignoreDemandResponseFee: false,
    ignoreGuestFee: false,
    type: null,
    users: mockDiscountPlanUser,
    listingGroupId: 550,
    appliedListings: [
      {
        listingId: 11,
        listingTitle: 'L6652',
      },
    ],
    creatorName: null,
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
] as DiscountSchema[]
