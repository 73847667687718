import { AccessPlanSchedule } from 'models/access-plan'

export const mockAccessPlanSchedule: { [key: string]: AccessPlanSchedule } = {
  onlyMonday: {
    '0': [[0, 1440]],
    '1': [[0, 1440]],
    '2': [],
    '3': [],
    '4': [[0, 1440]],
    '5': [[0, 1440]],
    '6': [],
  },
  customSchedule: {
    0: [
      [540, 720],
      [780, 1020],
    ],
    1: [
      [540, 720],
      [780, 1020],
    ],
    2: [
      [540, 720],
      [780, 1020],
    ],
    3: [
      [540, 720],
      [780, 1020],
    ],
    4: [
      [540, 720],
      [780, 1020],
    ],
    5: [[780, 1020]],
    6: [],
  },
  everydayBetweenStartAndEndDate: {
    0: [[540, 1020]],
    1: [[540, 1020]],
    2: [[540, 1020]],
    3: [[540, 1020]],
    4: [[540, 1020]],
    5: [[540, 1020]],
    6: [[540, 1020]],
  },
  weekdayAndWeekend: {
    0: [[0, 480]],
    1: [[0, 480]],
    2: [[0, 480]],
    3: [[0, 480]],
    4: [[0, 480]],
    5: [[0, 120]],
    6: [[0, 120]],
  },
  weekdayAndAllDayWeekend: {
    0: [[0, 480]],
    1: [[0, 480]],
    2: [[0, 480]],
    3: [[0, 480]],
    4: [[0, 480]],
    5: [],
    6: [],
  },
  twentyfour_seven: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  },
}
