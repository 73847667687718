import { useEffect, useState } from 'react'

import { SwtchError } from 'models/error'
import { TenantFilter } from 'models/filter'
import { PaginationMeta } from 'models/pagination'
import { TenantRef, tenantStatusesDefault } from 'models/tenant'

import { GetTenants } from 'services/data-provider/tenants'
import { useAppState } from 'state'

export const useTenants = () => {
  const { selectedTenant, isMockUpEnabled } = useAppState()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)

  const [filter, setFilter] = useState<TenantFilter>({
    page: 1,
    defaultTenant: selectedTenant,
    status: tenantStatusesDefault,
    masterAccounts: [],
    country: [],
    province: [],
  })
  useEffect(() => {
    fetchTenants()() //initial fetch
  }, [])

  const fetchTenants = (page = 1) => () => {
    setLoading(true)
    setError(undefined)
    GetTenants(isMockUpEnabled, {
      page: page,
      tenants: filter.tenants,
      country: filter.country,
      province: filter.province,
      status: filter.status,
      masterAccounts: filter.masterAccounts,
      listings: filter.listings,
      taggings: filter?.taggings,
    })
      .then((resp) => {
        setFilter({ ...filter, page })
        setTenants(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handlePaginationChange = (page: number) => fetchTenants(page)()

  const handleFilterChange = (filter: TenantFilter, tenants?: TenantRef[]) => {
    if (tenants) {
      setFilter((prevFilters) => ({ ...prevFilters, ...filter, tenants }))
    } else setFilter((prevFilters) => ({ ...prevFilters, ...filter }))
  }

  const handleLocationChange = (chosenCountries: string[], chosenProvinces: string[]) => {
    setFilter((prevFilters) => ({ ...prevFilters, country: chosenCountries, province: chosenProvinces }))
  }

  return {
    loading,
    tenants,
    error,
    pagination,
    filter,
    setFilter,
    handleFilterChange,
    fetchTenants,
    handlePaginationChange,
    handleLocationChange,
  }
}
