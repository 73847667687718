import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { Tenant, TenantPartner, TenantPayload } from 'models/tenant'
import { useNotifications } from './useNotification'
import { usePlatformTranslation } from './translation/usePlatformTranslation'
import { FindPartners } from 'services/data-provider/partners'
import { useAppState } from 'state'

export const useTenantTabPlatform = (tenant: Tenant, onSave: (tenant: TenantPayload) => void) => {
  const { isMockUpEnabled } = useAppState()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [partnersOptions, setPartnersOptions] = useState<TenantPartner[]>([])
  const [isDuplicate, setIsDuplicate] = useState(false)

  const [form] = Form.useForm()

  const { openSuccessNotification, openWarningNotification } = useNotifications()
  const { platformSuccessText } = usePlatformTranslation()

  useEffect(() => {
    FindPartners(isMockUpEnabled).then((response) => setPartnersOptions(response.data))
  }, [])

  const handleToggleDisabled = useCallback(() => {
    setDisabled(!disabled)
    form.resetFields()
  }, [disabled])

  const handlePartnersOptionChange = useCallback(
    (partnerName: string, name: number) => {
      // update the form with the selected partner
      const foundPartner = partnersOptions.find((partner) => partner.partnerName === partnerName)

      if (!foundPartner) return

      form.setFields([
        {
          name: ['tenant_partners', name, 'partnerId'],
          value: foundPartner.partnerId,
        },
        {
          name: ['tenant_partners', name, 'partnerName'],
          value: foundPartner.partnerName,
        },
        {
          name: ['tenant_partners', name, 'pmsCode'],
          value: foundPartner.pmsCode,
        },
      ])

      form.setFieldsValue({ [`tenant_partners[${name}].pmsCode`]: undefined })

      // Check for duplicates
      const partners = form.getFieldValue('tenant_partners')
      const partnerNames = partners.map((partner: TenantPartner) => partner.partnerName)
      const hasDuplicates = partnerNames.some((name: string, index: number) => partnerNames.indexOf(name) !== index)

      setIsDuplicate(hasDuplicates)
      if (hasDuplicates) {
        openWarningNotification('Duplicate partners are not allowed.')
      }
    },
    [form, partnersOptions],
  )

  const handleFormPlatformSubmit = async (values: TenantPayload) => {
    const payload: TenantPayload = {
      ...values,
      id: tenant.id,
    }

    onSave(payload)
    setDisabled(true)
    openSuccessNotification(platformSuccessText)
  }

  return {
    form,
    loading,
    disabled,
    isDuplicate,
    partnersOptions,
    setLoading,
    handlePartnersOptionChange,
    handleFormPlatformSubmit,
    handleToggleDisabled,
  }
}
