import React, { useState } from 'react'

import { Button, Form, Modal, Space, Spin } from 'antd'
import { closeSVG } from '../../../assets/svg/close'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import { PeakShavingEvent, PeakShavingEventSchedule, PeakShavingProgram } from '../../../models/peak-shaving'
import {
  CreateEventScheduleForEvent,
  CreateNewPeakShavingEvent,
  PublishPeakShavingEvent,
} from '../../../services/data-provider/peak-shaving'
import { styled } from '../../../theme'
import { EventDetailsBaseForm } from '../form/event-details-base-form'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit(): void
  program: PeakShavingProgram
}

const CreateNewEventModalContainer = styled.div``

export const TimeContainer = styled.div`
  display: flex;

  .ant-row.ant-form-item .ant-col {
    margin-left: 0;
  }
  .ant-select-selector {
    min-height: 39px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export const CreateNewEventModal: React.FC<props> = ({ visible, onCancel, onSubmit, program }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [CreateNewEventForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const eventSchedules: Omit<PeakShavingEventSchedule, 'id'>[] = Array.from(Array(1), (_, index) => ({
    from: index,
    to: index + 1,
    zone: 'none',
  }))

  const { publishText, cancelText, createEventText, eventCreationSuccessText } = usePeakShavingTranslation()

  const handleCancel = () => {
    CreateNewEventForm.resetFields()
    onCancel()
    setError(undefined)
    setDisabled(true)
  }

  const handleFormSubmit = () => {
    CreateNewEventForm.validateFields().then(() => {
      setDisabled(true)
      const { zones, program, participants, ...createNewEventFormPrams } = CreateNewEventForm.getFieldsValue()

      const filteredFields = zones.filter((field: any) => field.zone !== 'none')
      if (filteredFields.length === 0) {
        setError(
          new SwtchApiError({
            message: 'Unable to publish shaving event',
            code: '422',
            details: ['Event must have at least one schedule'],
          }),
        )
        setDisabled(true)
      } else {
        setLoading(true)
        Promise.all(
          participants.map((participant_id: number) =>
            CreateNewPeakShavingEvent({ ...createNewEventFormPrams, participant_id })
              .then((event: PeakShavingEvent) => {
                event && publishEventAndCreateSchedule(event, filteredFields)
              })
              .catch((err: SwtchApiError) => setError(err)),
          ),
        ).finally(() => {
          setLoading(false)
          setDisabled(false)
          CreateNewEventForm.resetFields()
        })
      }
    })
  }

  const handleChange = () => {
    setError(undefined)
    CreateNewEventForm.validateFields()
      .then(() => setDisabled(false))
      .catch((err: any) => {
        const { errorFields } = err
        Array.isArray(errorFields) && errorFields.length > 0 ? setDisabled(true) : setDisabled(false)
      })
  }

  const publishEventAndCreateSchedule = (event: PeakShavingEvent, fields: PeakShavingEventSchedule[]) => {
    const filteredFields = fields
      .filter((field: any) => field.zone !== 'none')
      .map((obj) => {
        return {
          from: obj.from,
          zone: obj.zone,
        }
      })

    Promise.all([...filteredFields.map((field) => CreateEventScheduleForEvent(event.id, field))])
      .then((resp: PeakShavingEventSchedule[]) => {
        resp &&
          resp.length > 0 &&
          PublishPeakShavingEvent(event.id).then(() => {
            CustomSuccessMessage({
              key: 'CreateNewEventAndSchedule',
              response: eventCreationSuccessText,
            })
            onSubmit()
          })
      })
      .catch((err: SwtchApiError) => setError(err))
    //.finally(() => CreateNewEventForm.resetFields())
  }

  return (
    <CreateNewEventModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{createEventText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          <Space>
            <Button type="ghost" onClick={handleCancel} loading={loading}>
              {cancelText}
            </Button>
            <Button type="primary" onClick={handleFormSubmit} disabled={disabled} loading={loading}>
              {publishText}
            </Button>
          </Space>
        }
        onCancel={handleCancel}
        width={1032}
        closeIcon={closeSVG}
        className={`createNewEventModalContainer ${error ? 'ant-modal-error' : ''}`}
      >
        <AlertError error={error} closable />
        <Spin spinning={loading}>
          {program && (
            <EventDetailsBaseForm
              program={program}
              form={CreateNewEventForm}
              disabled={false}
              schedules={eventSchedules}
              formMode="create"
              formListName="zones"
              scheduleFrequency="manual"
              onSelect={handleChange}
            />
          )}
        </Spin>
      </Modal>
    </CreateNewEventModalContainer>
  )
}
