import { TransactionFilter } from 'services/data-provider/metric'

export const transactionQuery = (
  f: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start_date: `${startDate.format('DD-MM-YYYY')}`,
    end_date: `${endDate.format('DD-MM-YYYY')}`,
  }

  queryParams['transaction_interval'] = calculateTransactionInterval(startDate, endDate)

  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.defaultTenant) {
    queryParams['tenants_id'] = `${f.defaultTenant.id}`
  }

  // if (f.tenants && f.tenants.length > 0) {
  //   queryParams['tenants_id'] = `${[...f.tenants.map((t) => t.id)].join('%')}`
  // }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['tenant_ids'] = `${f.tenants.map((t) => t.id)}`
  }

  if (f.charger) {
    queryParams['charger_id'] = `${f.charger.id}`
  }
  if (f.connector) {
    queryParams['connector_id'] = `${f.connector.id}`
  }
  if (f.startMethod) {
    queryParams['start_method'] = `${f.startMethod}`
  }

  if (f.transactionId) {
    queryParams['transaction_id'] = `${f.transactionId}`
  }
  if (f.listing) {
    queryParams['listing_id'] = `${f.listing.id}`
  }
  if (f.userName) {
    queryParams['user_name'] = `${f.userName}`
  }

  if (f.ocppTransactionId) {
    queryParams['ocpp_transaction_id'] = `${f.ocppTransactionId}`
  }
  if (f.country) {
    queryParams['country'] = `${f.country}`
  }
  if (f.province) {
    queryParams['province'] = `${f.province}`
  }

  if (f.masterAccountId) {
    queryParams['master_account_ids'] = `${f.masterAccountId}`
  }

  if (f.masterAccounts && f.masterAccounts.length > 0) {
    queryParams['master_account_ids'] = `${f.masterAccounts.map((m) => m.id)}`
  }

  return queryParams
}

export const calculateTransactionInterval = (startDate: moment.Moment, endDate: moment.Moment): string => {
  const diff = endDate.diff(startDate, 'days')
  if (diff <= 3) {
    return 'HOUR'
  } else if (diff > 3 && diff <= 60) {
    return 'DAY'
  } else if (diff > 60 && diff <= 120) {
    return 'WEEK'
  } else if (diff > 120) {
    return 'MONTH'
  }
  return 'DAY'
}
