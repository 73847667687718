import { renderFormatMessage } from 'helpers/intl'
import { formatCentsPrice, formatPrice } from 'helpers/number-format'
import { isItHourOrkWh } from 'helpers/price/priceHelper'

interface PriceWithFlatFeeProps {
  pricePerUnitCents: number
  priceType: string
  flatFee: number
  isLargeScreen: string
  isSmallScreen: string
}

export const PriceWithFlatFee: React.FC<PriceWithFlatFeeProps> = ({
  pricePerUnitCents,
  priceType,
  flatFee,
  isLargeScreen,
  isSmallScreen,
}) => (
  <div className={isLargeScreen}>
    <div className={isSmallScreen}>
      {formatCentsPrice(pricePerUnitCents)}/{isItHourOrkWh(priceType)} + {formatPrice(flatFee)}{' '}
      {renderFormatMessage('dashboard.text.fee', 'Fee')}
    </div>
  </div>
)
