import { renderFormatMessage } from 'helpers/intl'
import { formatPrice } from 'helpers/number-format'

interface FlatFeeOnlyProps {
  flatFee: number
  isLargeScreen: string
  isSmallScreen: string
}

export const FlatFeeOnly: React.FC<FlatFeeOnlyProps> = ({ flatFee, isLargeScreen, isSmallScreen }) => (
  <div className={isLargeScreen}>
    <div className={isSmallScreen}>
      {formatPrice(flatFee)} {renderFormatMessage('dashboard.text.fee', 'Fee')}
    </div>
  </div>
)
