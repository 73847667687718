import { log } from 'logger'
import configProvider from '../../config'
import { apiClient } from './client'
import { AccessPlanPayload, AccessPlan } from 'models/access-plan'
import { mockAccessPlanNames, mockAccessPlanResponse } from 'mock/access-plan-mock'
import { AccessPlansResponse } from 'models/http'
import { ListingRef } from 'models/listing'
import { mockListingResp } from 'mock/listing-mock'

export async function CreateAccessPlan(accessPlan: AccessPlanPayload, tenantId: number): Promise<AccessPlan> {
  const url = `/tenants/${tenantId}/access_plans`

  log('Creating access plan', { url })
  if (configProvider.config.mockEnabled) {
    return mockAccessPlanResponse.data[0]
  }

  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      access_plan: { ...accessPlan },
    }),
  })
}

export async function UpdateAccessPlan(
  accessPlan: AccessPlanPayload,
  tenantId: number,
  accessPlanId: number,
): Promise<AccessPlan> {
  const url = `/tenants/${tenantId}/access_plans/${accessPlanId}`

  log('Updating access plan', { url })

  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      access_plan: { ...accessPlan },
    }),
  })
}

export async function GetAllAccessPlans(isMockUpEnabled: boolean, tenantId: number): Promise<AccessPlan[]> {
  const url = `/tenants/${tenantId}/access_plans?all_access_plans=true`

  log('getting access plans', { url })
  if (isMockUpEnabled) {
    return mockAccessPlanResponse.data
  }
  return apiClient(url, {})
}

export async function GetAccessPlans(
  page: number,
  tenantId: number,
  accessPlanName?: string,
): Promise<AccessPlansResponse> {
  let url = `/tenants/${tenantId}/access_plans?page=${page}`

  if (accessPlanName) {
    url += `&name=${accessPlanName}`
  }

  log('getting pricing schemas', { url })
  if (configProvider.config.mockEnabled) {
    return mockAccessPlanResponse
  }
  return apiClient(url, {})
}

export async function GetAccessPlanNames(tenantId: number): Promise<string[]> {
  let url = `/tenants/${tenantId}/access_plans/names`

  log('getting pricing schemas', { url })
  if (configProvider.config.mockEnabled) {
    return mockAccessPlanNames
  }
  return apiClient(url, {})
}

export async function GetUnassignedChargers(tenantId: number): Promise<ListingRef[]> {
  let url = `/tenants/${tenantId}/access_plans/unassigned`

  log('getting pricing schemas', { url })
  if (configProvider.config.mockEnabled) {
    return mockListingResp
  }
  return apiClient(url, {})
}
