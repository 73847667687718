import { renderFormatMessage } from 'helpers/intl'

interface FreeListingProps {
  isLargeScreen: string
  isSmallScreen: string
}

export const FreeListing: React.FC<FreeListingProps> = ({ isLargeScreen, isSmallScreen }) => (
  <div className={isLargeScreen}>
    <div className={isSmallScreen}>
      {renderFormatMessage('dashboard.transactionsPage.advancedSearch.state.free', 'Free')}
    </div>
  </div>
)
