import { useEffect } from 'react'
import { Divider, Form, Spin } from 'antd'

import { Box } from 'atom/box'
import { layout } from 'atom/form/page-layout'
import { InputWrapper } from 'atom/chargers'

import { Tenant, TenantPayload } from 'models/tenant'
import EditAccountInfoModal from './modal/edit-account-info-modal'

import { useTenantTabOverview } from 'hooks/useTenantTabOverview'
import TenantStatusModal from './modal/TenantStatusModal'

import TenantBasicInfo from 'features/tenants/TenantTabOverview/TenantBasicInfo'
import TenantAccountInfo from 'features/tenants/TenantTabOverview/TenantAccountInfo'
import TenantServiceStatusInfo from 'features/tenants/TenantTabOverview/TenantServiceStatusInfo'
import TenantOnSiteContactInfo from 'features/tenants/TenantTabOverview/TenantOnSiteContactInfo'
import TenantFooter from 'features/tenants/TenantTabOverview/TenantFooter'
import { ReminderModal } from 'features/listings/modal/ReminderModal'

interface tenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabOverview: React.FC<tenantTabDetailProps> = ({ tenant, onSave }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const {
    loading,
    initialValues,
    form,
    disabled,
    editTenantStatusModal,
    onsiteContact,
    editAccountDetailModal,
    masterAccount,
    remittanceEmails,
    remittanceFrequency,
    status,
    chargerSettings,
    setOnsiteContact,
    setEditTenantStatusModal,
    updateTenantOverview,
    setMasterAccount,
    setEditAccountDetailModal,
    setDisabled,
    setRemittanceEmails,
    setRemittanceFrequency,
    handleStatus,
    reminderTexts,
    statusOnHold,
    onReminderOk,
    onReminderCanel,
    setChargerSettings,
  } = useTenantTabOverview(tenant, onSave)

  // update the tenant's status state when a different status is selected in the modal
  useEffect(() => {
    tenant.status = status
  }, [status])

  return (
    <Spin spinning={loading}>
      <Box padding>
        <Form
          {...layout}
          form={form}
          name={`tenant-${tenant?.id}`}
          onFinish={updateTenantOverview}
          initialValues={initialValues}
        >
          <InputWrapper>
            <TenantBasicInfo
              tenant={tenant}
              disabled={disabled}
              status={status}
              onEditTenantStatusModal={() => setEditTenantStatusModal(!editTenantStatusModal)}
            />
            <Divider />
            <TenantAccountInfo
              tenant={tenant}
              form={form}
              disabled={disabled}
              masterAccount={masterAccount}
              setMasterAccount={setMasterAccount}
              setEditAccountDetailModal={setEditAccountDetailModal}
            />
            <Divider />
            <TenantServiceStatusInfo
              form={form}
              tenant={tenant}
              disabled={disabled}
              currentChargerSettings={chargerSettings}
              setChargerSettings={setChargerSettings}
            />
            <Divider />
            <TenantOnSiteContactInfo
              onsiteContact={onsiteContact}
              disabled={disabled}
              setOnsiteContact={setOnsiteContact}
            />
            <Divider />
            <TenantFooter form={form} disabled={disabled} setDisabled={setDisabled} />
          </InputWrapper>
        </Form>
        {editTenantStatusModal && (
          <TenantStatusModal
            listingCount={tenant.listingCount}
            status={tenant.status}
            onCancel={() => setEditTenantStatusModal(false)}
            onStatus={handleStatus}
          />
        )}
        {(statusOnHold === 'inactive' || statusOnHold === 'deleted') && (
          <ReminderModal
            visible={!!statusOnHold}
            onOk={onReminderOk}
            onCancel={onReminderCanel}
            description={reminderTexts[statusOnHold].description}
            options={reminderTexts[statusOnHold].options}
          />
        )}
        {editAccountDetailModal && (
          <EditAccountInfoModal
            account={masterAccount}
            remittanceEmails={remittanceEmails}
            remittanceFrequency={remittanceFrequency}
            onCancel={() => setEditAccountDetailModal(false)}
            setRemittanceEmails={setRemittanceEmails}
            setRemittanceFrequency={setRemittanceFrequency}
            setMasterAccount={setMasterAccount}
          />
        )}
      </Box>
    </Spin>
  )
}
