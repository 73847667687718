import { Tagging } from 'models/tagging'

export const mockRevSharePartner: { [key: string]: Tagging } = {
  partnerAdminFromListingTable: {
    id: 1,
    tagName: 'Lan Partner',
    status: 'active',
    tableName: 'Listing',
    tagType: 'admin',
    createdAt: '2021-08-20T16:00:00.000Z',
    createdBy: 'admin',
    taggedObjects: [1],
  },
  partnerSystemFromTransactionTable: {
    id: 1,
    tagName: 'John Partner',
    status: 'inactive',
    tableName: 'Transaction',
    tagType: 'system',
    createdAt: '2021-08-20T16:00:00.000Z',
    createdBy: 'system',
    taggedObjects: [2, 3],
  },
}
