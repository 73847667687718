import { useDashboardTranslation } from '../../hooks/translation/useDashboardTranslation'
import { theme } from '../../theme'

const fontFamily = theme.fontFamily.regularFontFamily

const categories = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]

export const initialChartOption = {
  chart: {
    zoomType: 'x',
    // styledMode: true,
    height: 310,
    marginTop: 5,
  },

  xAxis: {
    categories: categories,
    type: 'spline',
    tickInterval: 1,
    minPadding: 0,
    maxPadding: 0,
    startOnTick: true,
    endOnTick: true,
    title: {
      text: '',
      margin: 20,
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: theme.fontSizes[2],
      },
    },
    crosshair: {
      width: 5,
      color: theme.colors.softBlack,
      dashStyle: 'ShortDash',
    },
    labels: {
      rotation: -45,
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: '11px',
        color: '#000',
      },
      //@ts-ignore
      formatter: function () {
        //@ts-ignore
        // return categories[this.value]
        return this.value
      },
    },
  },

  yAxis: {
    min: 0, // This ensures the Y-axis starts at 0
    crosshair: {
      width: 5,
      color: theme.colors.softBlack,
      dashStyle: 'ShortDash',
    },
    title: {
      text: '',
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: theme.fontSizes[2],
      },
    },
    labels: {
      format: `{value} kW`,
      style: {
        fontFamily,
        fontWeight: 400,
        fontSize: '11px',
        color: '#000',
      },
    },
    gridLineDashStyle: 'dash',
  },

  title: {
    text: '',
  },

  credits: {
    enabled: false,
  },

  defs: {
    gradient0: {
      tagName: 'linearGradient',
      id: 'gradient-0',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [
        {
          tagName: 'stop',
          offset: 0,
        },
        {
          tagName: 'stop',
          offset: 1,
        },
      ],
    },
    gradient1: {
      tagName: 'linearGradient',
      id: 'gradient-1',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
      children: [
        {
          tagName: 'stop',
          offset: 0,
        },
        {
          tagName: 'stop',
          offset: 1,
        },
      ],
    },
  },

  plotOptions: {
    area: {
      fillColor: {
        linearGradient: {
          x1: '0%',
          y1: '0%',
          x2: '0%',
          y2: '100%',
        },
        stops: [
          [0, theme.colors.graphLinearGradientFrom],
          [0.52, theme.colors.graphLinearGradientTo],
          [1, 'rgba(48, 215, 186, 0)'],
        ],
      },
      marker: {
        enabled: false,
        radius: 2,
        states: {
          hover: {
            fillColor: theme.colors.white,
            lineColor: theme.colors.primary,
          },
        },
      },
      //   lineWidth: 1,
      states: {
        hover: {
          //   lineWidth: 1,
        },
      },
      threshold: null,
    },
    series: {
      lineWidth: 1,
    },
  },

  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
    csv: {
      dateFormat: '%Y-%m-%d',
    },
    filename: '',
    chartOptions: {
      chart: {
        style: {
          fontFamily,
        },
      },
      title: {
        style: {
          fontFamily,
          fontWeight: 400,
          fontSize: '10px',
        },
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: '0%',
              y1: '0%',
              x2: '0%',
              y2: '100%',
            },
            stops: [
              [0, 'rgb(226,249,245)'],
              [1, 'rgb(255, 255, 255)'],
            ],
          },
        },
      },
      xAxis: {
        title: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
        labels: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
      },
      yAxis: {
        min: 0, // This ensures the Y-axis starts at 0
        title: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
        labels: {
          style: {
            fontFamily,
            fontWeight: 400,
            fontSize: '8px',
          },
        },
      },
    },
  },

  series: [
    {
      showInLegend: false,
      type: 'area',
      color: theme.colors.primary,
      name: 'Consumption Load Profile (kW)',

      //   pointStart: Date.UTC(2010, 0, 1),
      // pointIntervalUnit: 'month',
      data: [],
    },
  ],
  // tooltip: {
  //   // color: '#fff',
  //   backgroundColor: '#FCFFC5',
  //   //@ts-ignore
  //   formatter: function () {
  //     //@ts-ignore
  //     return `$ ${this.y}`
  //   },
  //   crosshairs: [true, true],
  // },
  tooltip: {
    // backgroundColor: '#000',
    // borderWidth: 1,
    // borderColor: '#000',
    style: {
      color: '#fff',
      fontFamily: theme.fontFamily.regularFontFamily,
      fontSize: '11px',
      fontWeight: 700,
      textAlign: 'center',
    },
    //@ts-ignore
    formatter: function () {
      //@ts-ignore
      return `${this.y} kW`
    },
  },
}

export const ChartOptionsState = () => {
  const { onlineChargers, offlineChargers, underRepairChargers } = useDashboardTranslation()

  return {
    chart: {
      plotShadow: false,
      height: 312,
      styledMode: true,
      animation: {
        duration: 1000,
      },
    },
    title: {
      text: '',
    },
    legend: {
      floating: true,
      align: 'left',
      x: -10,
      y: 10,
      symbolRadius: 3,
      layout: 'vertical',
      itemDistance: 70,
      padding: 1,
      itemMarginTop: 0,
      itemMarginBottom: 11,
      itemStyle: {
        lineHeight: '140%',
      },
      labelFormatter: function (): any {
        // console.log('---label--- station --')
        //@ts-ignore
        // console.log(this)
        //@ts-ignore
        // const [value] = this.ydata
        //@ts-ignore
        var output = `<span class='paragraph-03-regular seriesName'> ${this.name} </span> <span class='paragraph-03-regular'><b> (${this.y})</b></span>`
        return output
      },
    },
    tooltip: {
      followPointer: false,
      shared: false,
      useHTML: true,
      hideDelay: 0,
      //@ts-ignore
      formatter: function () {
        //@ts-ignore
        return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>'
      },
      style: {},
    },
    plotOptions: {
      pie: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        startAngle: -115,
        endAngle: 115,
        showInLegend: true,
        slicedOffset: 5,
      },
      series: {
        borderRadius: 12,
        animation: false,
        states: {
          hover: {
            halo: {
              size: 1,
              opacity: 0.4,
              attributes: {
                fill: '#54DEC7',
                strokeWidth: 25,
                stroke: '#54DEC7',
              },
            },
          },
          inactive: {
            enabled: false,
          },
          lineWidth: 5,
        },
        point: {
          events: {
            mouseOver: function () {},
            mouseOut: function () {},
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        innerSize: '60%',
        data: [
          {
            name: onlineChargers,
            y: 0,
            sliced: true,
          },
          {
            name: offlineChargers,
            y: 0,
            sliced: true,
          },
          {
            name: underRepairChargers,
            y: 0,
            sliced: true,
          },
        ],
      },
    ],
  }
}
