import { connectorServiceStatus, connectorStatus } from '../models/connector'
import { ListingsResponse, ListingsBaseResponse } from '../models/http'
import { Listing, ListingBase, ListingRef, NewListing, listingKind, smartCharging } from '../models/listing'
import { loiteringStatusType } from '../models/loitering'
import { mockAccessPlanSchedule } from './access-plan-schedule-mock'
import { mockLoiterings } from './loitering-mock'
import { mockPrice } from './price-mock'
import { mockTenant } from './tenant-mock'

export const mockListingResp = [
  { id: 15, title: 'L6652', timeZone: 'America/Toronto' },
  { id: 16, title: 'L6653', timeZone: 'America/Toronto' },
  { id: 17, title: 'L6654', timeZone: 'America/Toronto' },
  { id: 18, title: 'L6655', timeZone: 'America/Toronto' },
  { id: 19, title: 'L6656', timeZone: 'America/Toronto' },
  { id: 20, title: 'L6657', timeZone: 'America/Toronto' },
  { id: 21, title: 'L6658', timeZone: 'America/Toronto' },
  { id: 22, title: 'L6659', timeZone: 'America/Toronto' },
  { id: 23, title: 'L6660', timeZone: 'America/Toronto' },
  { id: 24, title: 'L6661', timeZone: 'America/Toronto' },
  { id: 25, title: 'L6662', timeZone: 'America/Toronto' },
  { id: 26, title: 'L6663', timeZone: 'America/Toronto' },
  { id: 26, title: 'L6663', timeZone: 'America/Toronto' },
  { id: 27, title: 'L7715', timeZone: 'America/Toronto' },
  { id: 28, title: 'L7716', timeZone: 'America/Toronto' },
  { id: 29, title: 'L7717', timeZone: 'America/Toronto' },
  { id: 30, title: 'L7718', timeZone: 'America/Toronto' },
  { id: 31, title: 'L7719', timeZone: 'America/Toronto' },
  { id: 32, title: 'L7720', timeZone: 'America/Toronto' },
  { id: 33, title: 'L7721', timeZone: 'America/Toronto' },
  { id: 34, title: 'L7722', timeZone: 'America/Toronto' },
  { id: 35, title: 'L7723', timeZone: 'America/Toronto' },
  { id: 36, title: 'L7724', timeZone: 'America/Toronto' },
  { id: 37, title: 'SG337', timeZone: 'America/Toronto' },
  { id: 38, title: 'SG338', timeZone: 'America/Toronto' },
  { id: 39, title: 'SG339', timeZone: 'America/Toronto' },
  { id: 40, title: 'SG340', timeZone: 'America/Toronto' },
  { id: 41, title: 'SG341', timeZone: 'America/Toronto' },
  { id: 42, title: 'SG342', timeZone: 'America/Toronto' },
  { id: 43, title: 'L7725', timeZone: 'America/Toronto' },
  { id: 44, title: 'L7726', timeZone: 'America/Toronto' },
  { id: 45, title: 'L7727', timeZone: 'America/Toronto' },
  { id: 46, title: 'L7728', timeZone: 'America/Toronto' },
]

export const mockListing: { [title: string]: ListingRef } = {
  L6652: { id: 15, title: 'L6652' },
  L6653: { id: 16, title: 'L6653' },
  L6654: { id: 17, title: 'L6654' },
  L6655: { id: 18, title: 'L6655' },
  L6656: { id: 19, title: 'L6656' },
  L6657: { id: 20, title: 'L6657' },
  L6658: { id: 21, title: 'L6658' },
  L6659: { id: 22, title: 'L6659' },
  L6660: { id: 23, title: 'L6660' },
  L6661: { id: 24, title: 'L6661' },
  L6662: { id: 25, title: 'L6662' },
  L6663: { id: 26, title: 'L6663' },
  L7715: { id: 27, title: 'L7715' },
  L7716: { id: 28, title: 'L7716' },
  L7717: { id: 29, title: 'L7717' },
  L7718: { id: 30, title: 'L7718' },
  L7719: { id: 31, title: 'L7719' },
  L7720: { id: 32, title: 'L7720' },
  L7721: { id: 33, title: 'L7721' },
  L7722: { id: 34, title: 'L7722' },
  L7723: { id: 35, title: 'L7723' },
  L7724: { id: 36, title: 'L7724' },
  SG337: { id: 37, title: 'SG337' },
  SG338: { id: 38, title: 'SG338' },
  SG339: { id: 39, title: 'SG339' },
  SG340: { id: 40, title: 'SG340' },
  SG341: { id: 41, title: 'SG341' },
  SG342: { id: 42, title: 'SG342' },
  L7725: { id: 43, title: 'L7725' },
  L7726: { id: 44, title: 'L7726' },
  L7727: { id: 45, title: 'L7727' },
  L7728: { id: 46, title: 'L7728' },
}

export const mockListings = [
  {
    ...mockListing['DP01'],
    pricePerUnitCents: 1,
    priceType: 'hour',
    connectors: [{ key: 1, id: 1, connectorId: 1 }],
  },
  {
    ...mockListing['DP02'],
    pricePerUnitCents: 1.15,
    priceType: 'kWh',
    connectors: [{ key: 12, id: 12, connectorId: 1 }],
  },
  {
    ...mockListing['YK01'],
    pricePerUnitCents: 1,
    priceType: 'hour',
    connectors: [
      { key: 31, id: 31, connectorId: 1 },
      { key: 5, id: 5, connectorId: 2 },
    ],
  },
  {
    ...mockListing['DC01'],
    pricePerUnitCents: 1,
    priceType: 'hour',
    connectors: [{ key: 8, id: 8, connectorId: 1 }],
  },
]

export const mockListingByChargerId: { [chargerId: number]: Listing[] } = {
  1: [
    {
      ...mockListing['DP01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 1, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
    {
      ...mockListing['DP02'],
      pricePerUnitCents: 1.15,
      priceType: 'kWh',
      connector: { id: 2, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
  2: [
    {
      ...mockListing['YK01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 9, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
  3: [
    {
      ...mockListing['NA01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 3, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
  4: [
    {
      ...mockListing['BN01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 4, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
  5: [
    {
      ...mockListing['BY01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 5, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
  6: [
    {
      ...mockListing['DN01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 6, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
  7: [
    {
      ...mockListing['DC01'],
      pricePerUnitCents: 1,
      priceType: 'hour',
      connector: { id: 8, connectorId: 1 },
      price: mockPrice.flatFeeOnly,
    },
  ],
}

export const mockAllListings: ListingBase[] = [
  {
    id: 63,
    title: 'AB01',
    priceType: 'Hour',
    pricePerUnitCents: 149,
    kind: 'no_reservation',
    open: true,
    author: 'Sam Arabastani',
    charger: {
      id: 80,
      chargePointSerialNumber: '1EC4-1-2131-00245',
      chargePointVendor: 'OCA',
      chargePointModel: 'OCA',
      serviceStatus: 'service_mode',
      online: false,
      chargingType: 'DC',
      tenant: {
        id: `4`,
        name: 'swtchhq',
        displayName: 'SWTCH HQ',
        combineName: 'SWTCH HQ (swtchhq)',
        status: 'active',
      },
    },
    connector: { id: 97, connectorId: 1 },
    price: mockPrice.flatFeeOnly,
  },
]

export const mockListingFull = {
  id: 9,
  title: 'MP01',
  kind: listingKind[0],
  open: true,
  hidden: false,
  currency: 'CAD',
  priceType: 'Hour',
  preferedPricePerUnitCents: 200,
  pricePerUnitCents: 100,
  loiteringStatus: loiteringStatusType[0],
  loiteringGracePeriod: 0,
  loiteringPenalty: 4,
  loiteringTimeLimit: 60,
  authorId: 'v7NpvrHi6nT225fFCs9t3A',
  additionalServiceFee: 'off',
  accessType: 'public',
  carDetailRequired: true,
  customNotification: "Parking not included! Don't forget to pay for parking, too!",
  output: 7.2,
  tenantId: 4,
  paymentType: 'wallet',
  stripeAccountId: 1,
  description: 'This listing has multiple connectors.',
  enableTieredPricing: false,
  guestFeeCents: 50,
  paymentLimitCents: 10000,
  enableTimeOfDayPricing: false,
  timeOfDayPriceWeekday: [],
  timeOfDayPriceWeekend: [],
  timeOfDayEnabled: false,
  pricePerHour: 100,
  commisionPercent: 10.0,
  flatFee: '0.0',
  enableLoadManagementPricing: false,
  enableLoadManagementAutoStop: true,
  addCloudLoadManagement: true,
  fundingSources: ['NEVI', 'ZEVIP'],
  disableLoiteringForTenantMember: false,
  trailerAccessible: false,
  person: {
    id: 'v7NpvrHi6nT225fFCs9t3A',
    email: 'sam.arabastani@swtchenergy.com',
    name: 'Sam Arabastani',
  },
  charger: {
    id: 50,
    chargePointSerialNumber: '20281717',
    chargingType: 'Level 2',
  },
  tenant: {
    id: 4,
    name: 'swtchhq',
    displayName: 'SWTCH HQ',
  },
  stripeAccount: {
    id: 1,
    name: 'Test123',
  },
  connector: { id: 97, connectorId: 1, serviceStatus: connectorServiceStatus[5] },
  reason: ['poor_customer_service', 'poor_hardware_quality'],
}

export const mockNewListings = [
  {
    id: 1,
    connectivity: true,
    title: 'LP01',
    accessPlans: {
      accessType: 'public',
      hidden: false,
      accessCode: null,
    },
    kind: 'reservation',
    connector: {
      id: 1,
      connectorId: 1,
      serviceStatus: connectorServiceStatus[10],
      ocppStatus: connectorStatus[0],
      connectorType: 'J1772',
    },
    charger: null,
    // ! IMPORTANT: temporary comment for testing
    // {
    //   id: 1,
    //   chargePointSerialNumber: '1EC1-1-1824-00035',
    //   chargePointModel: 'foo',
    //   chargePointVendor: 'LITE-ON',
    //   online: false,
    //   chargingType: 'Level 2',
    // },
    tenant: mockTenant['202 - Waverly - Rd - Dartmouth - NS - B2X2H3'],
    loitering: mockLoiterings[0],
    smartCharging: smartCharging[2],
    price: mockPrice.kWhPricing,
  },
  {
    id: 2,
    connectivity: true,
    title: 'LP02',
    accessPlans: {
      accessType: 'public',
      hidden: true,
      accessCode: null,
    },
    kind: 'no_reservation',
    connector: {
      id: 2,
      connectorId: 2,
      serviceStatus: connectorServiceStatus[4],
      ocppStatus: connectorStatus[1],
      connectorType: 'CCS',
    },
    charger: {
      id: 2,
      chargePointSerialNumber: '1EC1-1-1834-00188',
      chargePointModel: 'foo',
      chargePointVendor: 'LITE-ON',
      online: true,
      chargingType: 'DC',
    },
    tenant: mockTenant['133-Hazelton-Av-Toronto-ON-M5R0A6'],
    loitering: mockLoiterings[1],
    smartCharging: smartCharging[1],
    price: mockPrice.hourlyPricing,
  },
  {
    id: 3,
    connectivity: true,
    title: 'LP03',
    accessPlans: {
      accessType: 'private',
      hidden: true,
      accessCode: 123129,
    },
    kind: 'destination',
    connector: {
      id: 3,
      connectorId: 1,
      serviceStatus: connectorServiceStatus[2],
      ocppStatus: connectorStatus[2],
      connectorType: 'CHAdeMO',
    },
    charger: {
      id: 63,
      chargePointSerialNumber: '1EC0-1-1823-00061',
      chargePointModel: 'foo',
      chargePointVendor: 'LITE-ON',
      online: true,
      chargingType: 'DC',
    },
    tenant: mockTenant['133-Hazelton-Av-Toronto-ON-M5R0A6'],
    loitering: mockLoiterings[2],
    smartCharging: smartCharging[0],
    price: mockPrice.kWhPricingWithFlatFee,
  },
  {
    id: 4,
    connectivity: 'no_connection',
    title: 'LP04',
    accessPlans: {
      accessType: 'private',
      hidden: true,
      accessCode: 123129,
    },
    kind: 'no_reservation',
    connector: null,
    // {
    //   id: 5,
    //   connectorId: 1,
    //   serviceStatus: connectorServiceStatus[3],
    // },
    charger: null,
    //  {
    //   id: 6,
    //   chargePointSerialNumber: '1EC1-1-1834-00059',
    //   chargePointModel: 'foo',
    //   chargePointVendor: 'LITE-ON',
    //   online: true,
    //   chargingType: 'DC',
    // },
    // tenant: mockTenant['34-Victoria-Av-Kitchnber-ON-AZX8E9'],
    tenant: null,
    loitering: mockLoiterings[3],
    smartCharging: smartCharging[3],
    price: mockPrice.todPricingEnabled,
  },
  {
    id: 5,
    connectivity: false,
    title: 'LP05',
    accessPlans: {
      accessType: 'private',
      hidden: true,
      accessCode: 78456123,
    },
    kind: 'non_network',
    connector: {
      id: 6,
      connectorId: 1,
      serviceStatus: connectorServiceStatus[8],
      ocppStatus: connectorStatus[4],
      connectorType: 'J1772',
    },
    charger: {
      id: 5,
      chargePointSerialNumber: '1920511',
      chargePointModel: 'foo',
      chargePointVendor: 'LITE-ON',
      online: false,
      chargingType: 'Level 2',
    },
    tenant: mockTenant['344-Kitchener-Ave-West-ON-M9E8E4'],
    loitering: mockLoiterings[3],
    smartCharging: smartCharging[0],
    price: mockPrice.hourlyPricingWithFlatFee,
    serviceStatusChangedSince: '2024-07-23T10:20:30.123Z',
  },
  {
    id: 6,
    connectivity: 'connecting',
    title: 'LP06',
    accessPlans: {
      accessType: 'public',
      hidden: true,
      accessCode: 78456123,
    },
    kind: 'reservation',
    connector: {
      id: 7,
      connectorId: 1,
      serviceStatus: connectorServiceStatus[9],
      ocppStatus: connectorStatus[5],
      connectorType: 'CCS',
    },
    charger: {
      id: 7,
      chargePointSerialNumber: '1EC1-1-1834-00060',
      chargePointModel: 'foo',
      chargePointVendor: 'LITE-ON',
      online: false,
      chargingType: 'DC',
    },
    tenant: mockTenant['344-Kitchner-Ave-West-ON-M9E8E4'],
    loitering: mockLoiterings[3],
    smartCharging: smartCharging[0],
    price: mockPrice.flatFeeOnly,
    serviceStatusChangedSince: '2024-08-23T10:20:30.123Z',
  },
  {
    id: 7,
    connectivity: false,
    title: 'LP07',
    accessPlans: [
      {
        name: 'Public Chargers',
        access_type: 'restricted',
        access_code: 'qwerty',
        email_domains: ['@gmail.com'],
        schedules: mockAccessPlanSchedule.twentyfour_seven,
      },
    ],
    kind: 'no_reservation',
    connector: {
      id: 8,
      connectorId: 1,
      serviceStatus: connectorServiceStatus[0],
      ocppStatus: connectorStatus[6],
    },
    charger: {
      id: 3,
      chargePointSerialNumber: '1EC1-1-1834-00185',
      chargePointModel: 'foo',
      chargePointVendor: 'LITE-ON',
      online: true,
      chargingType: 'DC',
    },
    tenant: mockTenant['81-NavyWharf-Ct-Toronto-ON-M5B3S2'],
    loitering: mockLoiterings[4],
    smartCharging: smartCharging[1],
    price: mockPrice.flatFeeOnly,
  },
] as NewListing[]

export const mockNewListingResp = (currentPage: number) => {
  return {
    data: mockNewListings,
    pagination: {
      currentPage,
      perPage: 20,
      totalEntries: mockNewListings.length,
    },
  } as ListingsResponse
}

export const mockAllListingsResp = (page?: number) => {
  return {
    data: mockAllListings,
    pagination: {
      currentPage: page || 0,
      perPage: 20,
      totalEntries: 423,
    },
  } as ListingsBaseResponse
}

export const mockAllListingsRespV1 = (page?: number) => {
  return {
    data: mockAllListings,
    pagination: {
      currentPage: page || 0,
      perPage: 20,
      totalEntries: 423,
    },
  } as ListingsBaseResponse
}

export const mockServiceDescriptions = [
  { id: 1, name: 'Replaced Unit' },
  { id: 2, name: 'Reset Breaker' },
  { id: 3, name: 'Reset SWTCH Network' },
  { id: 4, name: 'Replaced SWTCH Network Hardware' },
  { id: 5, name: 'Waited for Area Network Recover' },
  { id: 6, name: 'Reset Building Network' },
  { id: 7, name: 'Replaced Cable' },
  { id: 8, name: 'Replaced Hardware parts (DCFC)' },
]

export const mockServiceStatusReasons = () => {
  return {
    data: [
      {
        id: 4,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Vandalism',
      },
      {
        id: 5,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Area Network Outage (Cellular)',
      },
      {
        id: 6,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'SWTCH Network Outage (Wifi)',
      },
      {
        id: 7,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Building Network Outage (Wifi)',
      },
      {
        id: 8,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Breaker Tripped',
      },
      {
        id: 9,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Voluntary shutoff',
      },
      {
        id: 10,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Area Power Outage',
      },
      {
        id: 11,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Grid power loss',
      },
      {
        id: 12,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Fault of the vehicle',
      },
      {
        id: 13,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Scheduled maintenance',
      },
      {
        id: 14,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Natural disasters',
      },
      {
        id: 15,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Operating Hours',
      },
      {
        id: 16,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Force Majeure (Catch All)',
      },
      {
        id: 17,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Communication Network Outages',
      },
      {
        id: 18,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Planned Outage for Maintenance and/or Upgrade',
      },
      {
        id: 19,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Fault Code: Low Voltage',
      },
      {
        id: 20,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Fault Code: Power Switch Failure',
      },
      {
        id: 21,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Fault Code: EV Communication Error',
      },
      {
        id: 22,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Fault Code: Ground Failure',
      },
      {
        id: 23,
        previousStatus: 'under_repair',
        newStatus: 'active',
        name: 'Fault Code: High Current',
      },
    ],
    pagination: {
      currentPage: 1,
      perPage: 20,
      totalEntries: 423,
    },
  }
}
