import { useEffect } from 'react'
import { Button, Col, Row, Space } from 'antd'

import { magnifyBlackSVG } from 'assets/svg/magnify'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'

import MultiListingTitleSelector from 'components/redesign/MultiListingIdTitleSelector'
import { MultiTenantSelector } from 'components/redesign/multiple-tenant-selector'
import MultiOptionSelector from 'components/redesign/MultiOptionSelector'
import { LocationSelector } from 'components/redesign/location-selector'
import { ReusableSelector } from 'components/reusableSelector/selector'

import { useSelector } from 'hooks/useSelector'
import { useTagging } from 'hooks/useTagging'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { TenantFilter } from 'models/filter'
import { ListingRef } from 'models/listing'
import { Tagging } from 'models/tagging'
import { MasterAccountRef } from 'models/master-account'
import { TenantRef, TenantStatus, tenantStatuses } from 'models/tenant'

import { useAppState } from 'state'

interface TenantsAllSelectorsProps {
  loading: boolean
  filter: TenantFilter
  tenants: TenantRef[]
  dropdownClassName?: string
  fetchData: () => void
  onFilterChange: (filter: TenantFilter, tenants?: TenantRef[]) => void
  onLocationChange: (chosenCountries: string[], chosenProvinces: string[]) => void
}

const TenantsAllSelectors: React.FC<TenantsAllSelectorsProps> = ({
  loading,
  filter,
  tenants,
  dropdownClassName,
  onFilterChange,
  fetchData,
  onLocationChange,
}) => {
  const { IsDesktop, IsLaptop, isMockUpEnabled } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const { currentUser } = useAppState()
  const { accountNamesText, taggingText } = useSelectorTranslation()

  const { getTaggingsWithoutPagination, taggings, renderMultiTagOption } = useTagging()

  const { multiTaggingHandlePlaceholder, multiTaggingHandleChange } = useSelector()
  const isAdmin = currentUser?.role === 'admin'

  useEffect(() => {
    getTaggingsWithoutPagination('Tenant')
  }, [])

  const {
    multiMasterAccountDebounceFetcher,
    multiMasterAccountHandleChange,
    multiMasterAccountHandlePlaceholder,
    multiMasterAccountHandleValue,
    renderMultiMasterAccountOption,
  } = useSelector()

  const handleMasterAccountSelection = (masterAccounts: MasterAccountRef[]) =>
    onFilterChange({
      masterAccounts: masterAccounts.filter((obj, index) => {
        return index === masterAccounts.findIndex((o) => obj.id === o.id)
      }),
    })

  const { searchText } = useGeneralTranslation()
  const renderMultiTenantSelector = () => (
    <MultiTenantSelector
      onOptionsChange={(tenants) => onFilterChange(filter, tenants)}
      onClear={() => onFilterChange(filter)}
      longerWidth={true}
      isCombineRight={true}
      loading={loading}
      dropdownClassName={dropdownClassName}
    />
  )

  const renderLocationSelector = () => (
    <LocationSelector
      dropdownClassName="tenant-page"
      transparent
      onSearch={onLocationChange}
      loading={loading}
      removeSVG={true}
      customWidth={145}
    />
  )

  const renderMasterAccountSelector = () => (
    <ReusableSelector
      maxTagCount={0}
      showArrow={false}
      showSearch={true}
      isSingle={false}
      showMagnifySVG={false}
      isDebounceFetcher={true}
      combineType="both"
      fieldForValue="id"
      placeholder={accountNamesText}
      dropdownClassName={dropdownClassName}
      onClear={() => onFilterChange({ ...filter, masterAccounts: [] })}
      onOptionsChange={(opts: MasterAccountRef[]) => handleMasterAccountSelection(opts)}
      debounceFetcher={multiMasterAccountDebounceFetcher}
      handlePlaceholder={multiMasterAccountHandlePlaceholder}
      handleValue={multiMasterAccountHandleValue}
      handleOptionChange={multiMasterAccountHandleChange}
      renderOption={renderMultiMasterAccountOption}
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  const renderTenantStatusSelector = () => (
    <MultiOptionSelector
      dropdownClassName={dropdownClassName}
      options={tenantStatuses.map((v) => v)}
      onOptionsChange={(opts: TenantStatus[]) => onFilterChange({ ...filter, status: opts })}
      onClear={() => onFilterChange({ ...filter, status: [] })}
      onSearch={(opts) => onFilterChange({ ...filter, status: opts })}
      placeholder="Status (es)"
      isCombineRightAndLeft
      loading={loading}
      longerWidth={false}
      defaultValues={filter?.status}
      removeSVG={true}
    />
  )

  const renderMultiListingTitleSelector = () => (
    <MultiListingTitleSelector
      dropdownClassName={dropdownClassName}
      onOptionChange={(listingsResp: ListingRef[]) => onFilterChange({ ...filter, listings: listingsResp })}
      setClear={() => onFilterChange(filter)}
      tenants={tenants}
      isCombineLeftSelector={!isAdmin}
      isCombineSelector={isAdmin}
      width={isDesktop || isLaptop ? '120px' : '100%'}
      minWidth={120}
    />
  )

  const renderTagSelector = () => (
    <ReusableSelector
      showSearch
      nativeSearch
      isSingle={false}
      clearSearchAfterSelect
      placeholder={taggingText}
      dropDownList={taggings}
      showMagnifySVG={false}
      defaultValues={filter?.taggings}
      fieldForValue="id"
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={multiTaggingHandlePlaceholder}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      combineType="left"
      handleOptionChange={multiTaggingHandleChange}
      renderOption={renderMultiTagOption}
      onOptionsChange={(taggings: Tagging[]) => onFilterChange({ ...filter, taggings })}
      customWidth="120px"
      searchButtonMerged
      placeholderWidth="50px"
      isMockUpEnabled={isMockUpEnabled}
    />
  )

  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderMultiTenantSelector()}
          <LineBreak />
          {renderLocationSelector()}
          {isAdmin && (
            <>
              <LineBreak />
              {renderMasterAccountSelector()}
            </>
          )}
          <LineBreak />
          {renderTenantStatusSelector()}
          <LineBreak />
          {renderMultiListingTitleSelector()}
          {isAdmin && (
            <>
              <LineBreak />
              {renderTagSelector()}
            </>
          )}
          <ButtonIcon
            icon={magnifyBlackSVG}
            loading={loading}
            disabled={loading}
            fetchData={fetchData}
            withinSearchBar
          />
        </Space>
      ) : (
        <Row wrap gutter={[6, 6]}>
          <Col xs={24} sm={24} md={24}>
            {renderMultiTenantSelector()}
          </Col>
          <Col xs={24} sm={24} md={24}>
            <LocationSelector onSearch={onLocationChange} loading={loading} />
          </Col>
          {isAdmin && (
            <Col xs={24} sm={24} md={24}>
              {renderMasterAccountSelector()}
            </Col>
          )}
          <Col xs={24} sm={24} md={24}>
            {renderTenantStatusSelector()}
          </Col>
          <Col xs={24} sm={24} md={24}>
            {renderMultiListingTitleSelector()}
          </Col>
          {isAdmin && (
            <Col xs={24} sm={24} md={24}>
              {renderTagSelector()}
            </Col>
          )}
          <Col xs={24} sm={24} md={24}>
            <Button block type="primary" loading={loading} disabled={loading} onClick={fetchData}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default TenantsAllSelectors
