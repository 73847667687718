import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'

export const useListingCommissionFeeTranslation = () => {
  const selectComissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText',
    'Select Commission Fee',
  )

  const defineNewComissionStructureText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionStructureText',
    'Define New Commission Structure',
  )

  const defineNewComissionTypeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText',
    'Define New Commission Fee Type',
  )
  const defineNewComissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText',
    'Define New Commission Fee (%)',
  )

  const swtchCommissionFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.companyComimissionFee',
    'SWTCH Commission Fee (%)',
  )

  const partnerText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.partner',
    'Revenue Share Partner',
  )

  const revenuePartnerPercentageText = useFormatMessage('dashboard.text.revenuePartnerPercentage', 'Partner Share (%)')

  const defineNewComissionTypeWarningText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText',
    'Commission fee cannot be empty!',
  )

  const flatFeeText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.flatFeeText',
    'Flat Fee ($)',
  )

  const commissionFeeText = renderFormatMessage('dashboard.createNewListing.commissionTab', 'Commission & Fee')

  const commissionPerecentText = renderFormatMessage('dashboard.createNewListing.comissionStep.txt', 'Commission (%)')

  const guestFeeCents = renderFormatMessage('dashboard.createNewListing.comissionStep.guestFee.txt', 'Guest Fee ($)')

  const enableLoadMgmtAutoStopText = renderFormatMessage(
    'dashboard.createNewListing.priceStep.localLoadManagementAutoStop.txt',
    'Local Load Management: Transaction Auto-stop',
  )
  const loadManagementText = useFormatMessage('dashboard.tenantPage.heading.loadManagement', 'Load Management')

  const taggingText = useFormatMessage('dashboard.tagging', 'Tagging')

  return {
    commissionFeeText,
    commissionPerecentText,
    defineNewComissionStructureText,
    defineNewComissionTypeText,
    defineNewComissionFeeText,
    defineNewComissionTypeWarningText,
    enableLoadMgmtAutoStopText,
    flatFeeText,
    guestFeeCents,
    loadManagementText,
    partnerText,
    revenuePartnerPercentageText,
    selectComissionFeeText,
    swtchCommissionFeeText,
    taggingText,
  }
}
