import { AccessPlanSchedule } from 'models/access-plan'
import { convertMinutesToTime } from './date-format'
import { renderFormatMessage } from './intl'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const weekdays = [0, 1, 2, 3, 4]
const weekend = [5, 6]
const allDays = [...weekdays, ...weekend]

const isSameSchedule = (schedule: AccessPlanSchedule, daysArray: number[]): boolean => {
  const firstDaySchedule = JSON.stringify(schedule[daysArray[0] as keyof AccessPlanSchedule])
  return daysArray.every((day) => JSON.stringify(schedule[day as keyof AccessPlanSchedule]) === firstDaySchedule)
}

const renderScheduleRange = (range: number[]) => {
  if (range[0] === 0 && range[1] === 1440) {
    return 'All Day'
  }
  return `${convertMinutesToTime(range[0])} - ${convertMinutesToTime(range[1])}`
}

const isScheduleOpen24_7 = (schedule: AccessPlanSchedule): boolean => {
  return Object.values(schedule).every((ranges) => ranges.length === 0)
}

const renderEverydaySchedule = (schedule: AccessPlanSchedule) => {
  const [start, end] = schedule[0][0]

  // Check if the schedule is 'All Day' for everyday
  if (start === 0 && end === 1440) {
    return '24/7' // Return '24/7' if it's All Day
  }

  // Return the formatted schedule if it's not 'All Day'
  return <div>Everyday {`${convertMinutesToTime(start)} - ${convertMinutesToTime(end)}`}</div>
}

const renderWeekdayWeekendSchedule = (schedule: AccessPlanSchedule) => {
  if (isSameSchedule(schedule, weekdays) && isSameSchedule(schedule, weekend)) {
    const weekdayRanges = schedule[0] // Get the ranges for weekdays
    const weekendRanges = schedule[5] // Get the ranges for weekends

    return (
      <div>
        {weekdayRanges.length > 0 && (
          <>
            {renderFormatMessage('dashboard.text.weekday', 'Weekday')}{' '}
            {weekdayRanges.map((range) => renderScheduleRange(range)).join(', ')}
          </>
        )}
        {weekdayRanges.length > 0 && weekendRanges.length > 0 && <br />}
        {weekendRanges.length > 0 && (
          <>
            {renderFormatMessage('dashboard.text.weekend', 'Weekend')}{' '}
            {weekendRanges.map((range) => renderScheduleRange(range)).join(', ')}
          </>
        )}
      </div>
    )
  }
}

const formatRangesForCustomDays = (ranges: [number, number][]) => {
  return ranges
    .map((range) =>
      range[0] === 0 && range[1] === 1440
        ? 'All Day'
        : `${convertMinutesToTime(range[0])} - ${convertMinutesToTime(range[1])}`,
    )
    .join(', ')
}

const renderCustomDaysSchedule = (day: string, ranges: [number, number][]) => {
  // this is to prevent returning empty div if there are no ranges for that day
  if (ranges.length === 0) {
    return null
  }

  /* the code below will return the day and the ranges for that day
   for example, if the day is '0' (Monday) and the ranges are [[540, 720], [780, 1020]], then it will return:
   Monday 9:00 AM - 12:00 PM, 1:00 PM - 5:00 PM */
  /* or if it's all day, then the it will return Monday All Day */
  return (
    <div key={day}>
      {days[+day]} {formatRangesForCustomDays(ranges)}
    </div>
  )
}

export const describeSchedule = (schedule: AccessPlanSchedule) => {
  // Check if the object is empty (no keys)
  if (isScheduleOpen24_7(schedule)) {
    return '24/7'
  }

  // Check if the schedule is the same every day
  if (isSameSchedule(schedule, allDays)) {
    return renderEverydaySchedule(schedule)
  }

  // Check if the schedule is the same for weekdays and weekends
  if (isSameSchedule(schedule, weekdays) && isSameSchedule(schedule, weekend)) {
    return renderWeekdayWeekendSchedule(schedule)
  }

  // If no pattern, return detailed schedule per day (day-specific schedules)
  return Object.entries(schedule).map(([day, ranges]) => renderCustomDaysSchedule(day, ranges))
}

export const getScheduleDescription = (schedule: AccessPlanSchedule): string => {
  // Check if the object is empty (no keys)
  if (Object.keys(schedule).length === 0) {
    return '24/7'
  }

  // Extract values for weekdays (0-4) and weekend (5-6)
  const weekdays = [0, 1, 2, 3, 4].map((key) => schedule[key as keyof AccessPlanSchedule])
  const weekend = [5, 6].map((key) => schedule[key as keyof AccessPlanSchedule])

  // Check if all weekdays have the same value and all weekend days have the same value
  const allWeekdaysSame = weekdays.every((val) => JSON.stringify(val) === JSON.stringify(weekdays[0]))
  const allWeekendSame = weekend.every((val) => JSON.stringify(val) === JSON.stringify(weekend[0]))

  // Check if it's 'All Day' for both weekdays and weekends (from 0 to 1440)
  const isAllDaySchedule = (daySchedule: number[][]) =>
    daySchedule.length === 1 && daySchedule[0][0] === 0 && daySchedule[0][1] === 1440

  if (allWeekdaysSame && allWeekendSame) {
    // Check if all days are 'All Day'
    if (isAllDaySchedule(weekdays[0]) && isAllDaySchedule(weekend[0])) {
      return '24/7'
    }
    return 'weekdayWeekend'
  }

  // If not, return specific day descriptions
  const descriptions: string[] = []

  for (let i = 0; i < 7; i++) {
    if (schedule[i as keyof AccessPlanSchedule].length > 0) descriptions.push(`${days[i]}`)
  }

  return descriptions.length > 0 ? 'customDays' : '24/7'
  // return descriptions.length > 0 ? descriptions.join(', ') : '24/7'
}
