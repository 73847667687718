import moment from 'moment'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockTransactionRespv2 } from '../../mock/transaction-mock'
import { ChargerRef } from '../../models/charger'
import { Connector } from '../../models/connector'
import { TransactionResponsev2, TransactionSummary } from '../../models/http'
import { ListingRef } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import { TransactionStartMethod, TransactionState } from '../../models/transaction'
import { apiClient2 } from './client'
import { returnMock } from './mock'
import { mockChargerMetrics, sumChargerMetrics } from 'mock/insight-mock'
import { transactionQuery } from 'helpers/query/TransactionQuery'
import { MasterAccountRef } from 'models/master-account'

export interface TransactionFilter {
  page?: number
  perPage: number
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  charger?: ChargerRef
  connector?: Connector
  startMethod?: TransactionStartMethod
  state?: TransactionState
  transactionId?: string
  listing?: ListingRef
  userName?: string
  userEmail?: string
  ocppTransactionId?: string
  country?: string
  province?: string
  masterAccountId?: number
  masterAccounts?: MasterAccountRef[]
}

export async function FetchTransactions(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
  controller: AbortController,
  isMockUpEnabled: boolean,
): Promise<TransactionResponsev2> {
  let url = '/v1/transactions'

  const { signal } = controller

  const queryParams = transactionQuery(props, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('home page props', props)
  log('fetching transactions overview', { url })
  if (isMockUpEnabled) {
    return returnMock(mockTransactionRespv2(props))
  }
  return apiClient2(url, { signal })
}

export async function GetChargerSummaryMetrics(
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
  controller: AbortController,
  isMockUpEnabled: boolean,
): Promise<TransactionSummary> {
  let url = '/v1/dashboard/transaction-summary'

  const { signal } = controller

  const queryParams = transactionQuery(props, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('fetching charger summary metrics', { url })
  if (
    isMockUpEnabled &&
    startDate.isSame(moment().subtract(1, 'years').startOf('year')) &&
    endDate.isSame(moment().subtract(1, 'years').endOf('year'))
  ) {
    return returnMock(sumChargerMetrics(mockChargerMetrics, props))
  }
  return apiClient2(url, { signal })
}
