import { useFormatMessage } from '../../helpers/intl'

export const useListingsTranslation = () => {
  const createListing = useFormatMessage('dashboard.listing.CreateNewListing', 'Create Listing')
  const editListingDetailsText = useFormatMessage('dashboard.text.editListingDetails', 'Edit Listing Details')
  const listingUpdated = useFormatMessage(
    'dashboard.chargersPage.modal.updateListing.listingUpdated',
    'Listing Updated',
  )
  const listingsUpdated = useFormatMessage(
    'dashboard.chargersPage.modal.updateListing.listingsUpdated',
    'Listings Updated',
  )
  const capAndMapSettingsText = useFormatMessage('dashboard.text.carAndMapSettings', 'Car and Map Settings')

  return { capAndMapSettingsText, createListing, editListingDetailsText, listingUpdated, listingsUpdated }
}
