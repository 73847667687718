import { useEffect, useState } from 'react'
import { Form } from 'antd'
import {
  CreateAccessPlan,
  GetAccessPlanNames,
  GetUnassignedChargers,
  UpdateAccessPlan,
} from 'services/data-provider/access-plan'
import { Tenant } from 'models/tenant'
import {
  AccessPlanPayload,
  AccessPlan,
  AccessPlanSchedule,
  ConflictDetails,
  ConflictingPlan,
  AccessPlanType,
} from 'models/access-plan'
import { ListingRef, NewListing } from 'models/listing'
import { GetListings } from 'services/data-provider/listing'
import { ListingsResponse } from 'models/http'
import { getScheduleDescription } from 'helpers/schedule'
import { useAppState } from 'state'
import { useNotifications } from './useNotification'
import { useAccessPlanTranslation } from './translation/useAccessPlanTranslation'
import { validateInput } from 'helpers/regularExpression/input'

// helper funciton to map rate arrays
const createRateArray = (rates: any[]) => rates.map((rate) => [rate.from * 60, rate.to * 60])

const initialCheckedState = {
  weekdayChecked: false,
  weekendChecked: false,
  mondayChecked: false,
  tuesdayChecked: false,
  wednesdayChecked: false,
  thursdayChecked: false,
  fridayChecked: false,
  saturdayChecked: false,
  sundayChecked: false,
}

// interface
interface CheckedDays {
  weekdayChecked: boolean
  weekendChecked: boolean
  mondayChecked: boolean
  tuesdayChecked: boolean
  wednesdayChecked: boolean
  thursdayChecked: boolean
  fridayChecked: boolean
  saturdayChecked: boolean
  sundayChecked: boolean
}

export const useAccessPlanCreateAndEdit = (
  tenant: Tenant,
  accessPlans: AccessPlan[],
  visible: boolean,
  selectedAccessPlan?: AccessPlan,
) => {
  const [form] = Form.useForm()
  const initialValues = {
    accessType: selectedAccessPlan ? selectedAccessPlan.accessType : 'restricted',
    scheduleType: '24/7',
    restrictDrivers: true,
  }
  const { isMockUpEnabled } = useAppState()
  const { openSuccessNotification, openErrorNotification } = useNotifications()
  const { accessPlanCreatedText, accessPlanUpdatedText, accessCodeAlreadyInUseText } = useAccessPlanTranslation()
  const [accessType, setAccessType] = useState<AccessPlanType>('restricted')
  const [scheduleType, setScheduleType] = useState<string>('24/7') //
  const [driversChecked, setDriversChecked] = useState<boolean>(true)
  const [accessCodeChecked, setAccessCodeChecked] = useState<boolean>(false)
  const [accessCode, setAccessCode] = useState<string>('')
  const [emailDomainChecked, setEmailDomainChecked] = useState<boolean>(false)
  const [emailDomains, setEmailDomains] = useState<string[]>([])
  const [checkedDays, setCheckedDays] = useState(initialCheckedState)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [nameExists, setNameExists] = useState<boolean>(false)
  const [accessPlanNames, setAccessPlanNames] = useState<string[]>([])
  const [conflictingData, setConflictingData] = useState<ConflictingPlan[]>()
  const [currentPlanValues, setCurrentPlanValues] = useState()
  const [listings, setListings] = useState<ListingRef[]>([])
  const [allListings, setAllListings] = useState<NewListing[]>([])
  const [showNoAccessPlanModal, setShowNoAccessPlanModal] = useState(false)
  const [chargersNotAssignedData, setChargersNotAssignedData] = useState<ListingRef[]>([])

  useEffect(() => {
    if (selectedAccessPlan) {
      setAccessType(selectedAccessPlan.accessType)
      setAccessCodeChecked(!!selectedAccessPlan.enableAccessCode)
      setAccessCode(selectedAccessPlan.accessCode)
      setDriversChecked(!!selectedAccessPlan.enableEmailDomains)
      setEmailDomainChecked(selectedAccessPlan.emailDomains.length > 0 ? true : false)
      setScheduleType(getScheduleDescription(selectedAccessPlan.schedules))
      setEmailDomains(selectedAccessPlan.emailDomains)
      setListings(selectedAccessPlan.listings)
      form.setFieldsValue({
        accessPlanName: selectedAccessPlan.name,
        accessCode: selectedAccessPlan.accessCode,
        emailDomain: selectedAccessPlan.emailDomains,
        accessType: selectedAccessPlan.accessType,
        scheduleType: getScheduleDescription(selectedAccessPlan.schedules),
      })

      const scheduleDescription = getScheduleDescription(selectedAccessPlan.schedules)
      if (scheduleDescription !== '24/7') {
        const hasWeekdaySchedule = selectedAccessPlan.schedules[0]?.length > 0 // Monday to Friday
        const hasWeekendSchedule = selectedAccessPlan.schedules[5]?.length > 0 // Saturday and Sunday

        if (hasWeekdaySchedule) handleDayCheckedChange('weekdayChecked', true)
        if (hasWeekendSchedule) handleDayCheckedChange('weekendChecked', true)

        if (getScheduleDescription(selectedAccessPlan.schedules) === 'customDays') {
          // Update each day based on schedule values
          const schedule = selectedAccessPlan.schedules
          const days = [
            'mondayChecked',
            'tuesdayChecked',
            'wednesdayChecked',
            'thursdayChecked',
            'fridayChecked',
            'saturdayChecked',
            'sundayChecked',
          ]
          days.forEach((day, index) => {
            const isChecked = schedule[index as keyof AccessPlanSchedule]?.length > 0 // Check if the day has any time ranges
            handleDayCheckedChange(day, isChecked)
          })
        }
      }
    }
  }, [selectedAccessPlan])

  useEffect(() => {
    GetListings(isMockUpEnabled, { defaultTenant: tenant, allListings: true })
      .then((listings: NewListing[] | ListingsResponse) => {
        if (Array.isArray(listings)) setAllListings(listings)
      })
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    if (visible) {
      GetAccessPlanNames(Number(tenant.id)).then((resp) => setAccessPlanNames(resp))
    }
  }, [visible])

  useEffect(() => {
    const { accessPlanName } = form.getFieldsValue()

    const isFormValid = validateForm(listings, accessPlanName)
    const isScheduleValid = validateSchedule(scheduleType, checkedDays)
    const accessCodeValidation = validateAccessCode(accessType, accessCodeChecked, accessCode)
    const accessDriverEmailsValidation = validateAccessDriverEmails(accessType, emailDomainChecked, emailDomains)

    setIsButtonDisabled(!(isFormValid && isScheduleValid && accessCodeValidation && accessDriverEmailsValidation))
  }, [listings, scheduleType, checkedDays, form, accessCodeChecked, accessCode, emailDomainChecked, emailDomains])

  const validateForm = (listings: ListingRef[], accessPlanName: string) => {
    return listings.length > 0 && accessPlanName
  }

  const validateSchedule = (scheduleType: string, checkedDays: CheckedDays) => {
    if (scheduleType === 'weekdayWeekend') {
      return checkedDays.weekdayChecked || checkedDays.weekendChecked
    } else if (scheduleType === 'customDays') {
      return (
        checkedDays.mondayChecked ||
        checkedDays.tuesdayChecked ||
        checkedDays.wednesdayChecked ||
        checkedDays.thursdayChecked ||
        checkedDays.fridayChecked ||
        checkedDays.saturdayChecked ||
        checkedDays.sundayChecked
      )
    }
    return true
  }

  const validateAccessCode = (accessType: AccessPlanType, accessCodeChecked: boolean, accessCode: string) => {
    if (accessType !== 'restricted') return true
    const validatedAccessCode = validateInput(accessCode)
    return accessCodeChecked ? isValidAccessCode(validatedAccessCode) : true
  }

  const isValidAccessCode = (code: string) => code.length >= 4 && code.length <= 10

  const validateAccessDriverEmails = (
    accessType: AccessPlanType,
    emailDomainChecked: boolean,
    emailDomains: string[],
  ) => {
    if (accessType !== 'restricted') return true
    return emailDomainChecked ? emailDomains.length > 0 : true
  }

  const handleDayCheckedChange = (day: string, isChecked: boolean) =>
    setCheckedDays((prev) => ({ ...prev, [day]: isChecked }))

  const handleAccessTypeChange = (e: any) => setAccessType(e.target.value)

  const handleScheduleTypeChange = (e: any) => setScheduleType(e.target.value)

  const createSchedules = (values: any) => {
    if (values.scheduleType === 'weekdayWeekend') {
      const weekdayRateArr = 'weekdayRates' in values ? createRateArray(values.weekdayRates) : []
      const weekendRateArr = 'weekendRates' in values ? createRateArray(values.weekendRates) : []

      return {
        0: weekdayRateArr,
        1: weekdayRateArr,
        2: weekdayRateArr,
        3: weekdayRateArr,
        4: weekdayRateArr,
        5: weekendRateArr,
        6: weekendRateArr,
      }
    }

    if (values.scheduleType === 'customDays') {
      return {
        0: 'mondayRates' in values ? createRateArray(values.mondayRates) : [],
        1: 'tuesdayRates' in values ? createRateArray(values.tuesdayRates) : [],
        2: 'wednesdayRates' in values ? createRateArray(values.wednesdayRates) : [],
        3: 'thursdayRates' in values ? createRateArray(values.thursdayRates) : [],
        4: 'fridayRates' in values ? createRateArray(values.fridayRates) : [],
        5: 'saturdayRates' in values ? createRateArray(values.saturdayRates) : [],
        6: 'sundayRates' in values ? createRateArray(values.sundayRates) : [],
      }
    }
    return {}
  }

  const createAccessPlan = (values: any, setVisible: (visible: boolean) => void, update?: boolean) => {
    const buildSchedule = createSchedules(values)
    const listingsArr = listings.map((listing) => listing.id)

    const payload: AccessPlanPayload = {
      name: values.accessPlanName,
      tenant_id: Number(tenant.id),
      listing_ids: listingsArr,
      access_type: values.accessType,
      has_operation_hours: values.scheduleType !== '24/7',
      enable_access_code: values.accessType === 'restricted' ? accessCodeChecked : false,
      enable_email_domains: values.accessType === 'restricted' ? emailDomainChecked : false,
      email_domains: values.accessType === 'restricted' && emailDomainChecked ? emailDomains : [],
      access_code: values.accessType === 'restricted' && accessCodeChecked ? values.accessCode : '',
      schedules: buildSchedule,
    }

    if (update && selectedAccessPlan) {
      UpdateAccessPlan(payload, Number(tenant.id), selectedAccessPlan?.id)
        .then((resp: AccessPlan | ConflictDetails) => {
          if ('error' in resp) {
            if (resp.error === 'overlap with existing access plan') return setConflictingData(resp.conflicting_plans)
            if (resp.error === 'taken') return openErrorNotification(accessCodeAlreadyInUseText)
            if (typeof resp.error === 'string') return openErrorNotification(resp.error)
          }
          setConflictingData(undefined)
          setVisible(false)
          openSuccessNotification(accessPlanUpdatedText)
        })
        .catch((error) => console.log('Error:', error))
    } else {
      CreateAccessPlan(payload, Number(tenant.id))
        .then((resp: AccessPlan | ConflictDetails) => {
          if ('error' in resp) {
            if (resp.error === 'overlap with existing access plan') return setConflictingData(resp.conflicting_plans)
            if (resp.error === 'taken') return openErrorNotification(accessCodeAlreadyInUseText)
            if (typeof resp.error === 'string') return openErrorNotification(resp.error)
          }
          setConflictingData(undefined)
          setVisible(false)
          openSuccessNotification(accessPlanCreatedText)
        })
        .catch((error) => console.log('Error:', error))
    }
  }

  const updateAndSave = (setVisible: (visible: boolean) => void) => {
    if (!conflictingData) {
      console.error('No conflicting data available.')
      return
    }

    const updatePromises = conflictingData.map((data) => {
      const conflictingAccessPlan = accessPlans.find((plan) => plan.id === data?.plan_id)

      if (!conflictingAccessPlan) {
        console.error('No conflicting access plan found.')
        return Promise.resolve(null) // Return a resolved promise to maintain the promise chain
      }

      const listingsArr = conflictingAccessPlan.listingIds.filter((item) => !data?.listings.includes(item))

      const payload: AccessPlanPayload = {
        name: conflictingAccessPlan.name,
        tenant_id: conflictingAccessPlan.tenantId,
        listing_ids: listingsArr,
        access_type: conflictingAccessPlan.accessType,
        has_operation_hours: conflictingAccessPlan.hasOperationHours,
        enable_access_code: conflictingAccessPlan.enableAccessCode,
        enable_email_domains: conflictingAccessPlan.enableEmailDomains,
        email_domains: conflictingAccessPlan.emailDomains,
        access_code: conflictingAccessPlan.accessCode,
        schedules: conflictingAccessPlan.schedules,
      }

      return UpdateAccessPlan(payload, Number(tenant.id), data.plan_id) // Return the promise
    })

    // Wait for all update promises to complete
    Promise.all(updatePromises)
      .then(() => {
        // Call createAccessPlan after all updates are done
        createAccessPlan(currentPlanValues, setVisible, true)
      })
      .catch((error) => {
        console.log('Error:', error)
      })
  }

  const handleValuesChange = (changedValues: any, allValues: any) => {
    // Check if all required fields are filled
    const values = form.getFieldsValue()

    // Disable or enable the button based on whether the fields are filled
    setIsButtonDisabled(!(values.accessPlanName && listings.length > 0))

    if (changedValues.accessCode !== undefined) {
      form.setFieldsValue({ accessCode: validateInput(changedValues.accessCode) })
      setAccessCode(validateInput(changedValues.accessCode))
    }
  }

  const nameCheck = (value: string) => {
    // Check if the selected access plan is being edited
    if (selectedAccessPlan && selectedAccessPlan.name.toLowerCase() === value.toLowerCase()) {
      setNameExists(false)
      return
    }

    // Check if the name already exists in other access plans
    const nameExists = accessPlanNames.some((name) => name.toLowerCase() === value.toLowerCase())
    setNameExists(nameExists)
  }

  const editAccessPlan = (values: any, accessPlanId: number, setVisible: (visible: boolean) => void) => {
    const buildSchedule = createSchedules(values)
    const listingsArr = listings.map((listing) => listing.id)

    const payload: AccessPlanPayload = {
      name: values.accessPlanName,
      tenant_id: Number(tenant.id),
      listing_ids: listingsArr,
      access_type: values.accessType,
      has_operation_hours: values.scheduleType !== '24/7',
      enable_access_code: values.accessType === 'restricted' ? accessCodeChecked : false,
      enable_email_domains: values.accessType === 'restricted' ? emailDomainChecked : false,
      email_domains: values.accessType === 'restricted' && emailDomainChecked ? emailDomains : [],
      access_code: values.accessType === 'restricted' && accessCodeChecked ? values.accessCode : '',
      schedules: buildSchedule,
    }

    UpdateAccessPlan(payload, Number(tenant.id), accessPlanId)
      .then((resp: AccessPlan | ConflictDetails) => {
        if ('error' in resp) {
          if (resp.error === 'overlap with existing access plan') return setConflictingData(resp.conflicting_plans)
          if (resp.error === 'taken') return openErrorNotification(accessCodeAlreadyInUseText)
          if (typeof resp.error === 'string') return openErrorNotification(resp.error)
        }
        const removedListings = selectedAccessPlan?.listingIds.filter(
          (originalListingId) => !listingsArr.includes(originalListingId),
        )
        if (removedListings && removedListings.length > 0) {
          return GetUnassignedChargers(Number(tenant.id))
            .then((resp) => {
              // Check if any removed listings are in the unassigned listings
              const matchingUnassigned = resp.filter((unassigned) => removedListings.includes(unassigned.id))
              if (matchingUnassigned.length > 0) {
                setChargersNotAssignedData(matchingUnassigned)
                setShowNoAccessPlanModal(true)
              } else {
                setVisible(false)
              }
            })
            .then((err) => console.log('Error:', err))
        }
        return setVisible(false)
      })
      .catch((error) => console.log('Error:', error))
  }

  const convertSingleDaySchedule = (daySchedule: number[][]) =>
    daySchedule.map((timeRange) => {
      const [from, to] = timeRange.length === 2 ? timeRange : [0, 0] // Default to [0, 0] if the length is not 2
      return {
        from: Math.floor(from / 60), // convert minutes to hours
        to: Math.floor(to / 60), // convert minutes to hours
        dollar_rate: '1', // default dollar rate, you can customize this
      }
    })

  const addListingsBack = () => {
    if (selectedAccessPlan) {
      const payload: AccessPlanPayload = {
        name: selectedAccessPlan.name,
        tenant_id: selectedAccessPlan.tenantId,
        listing_ids: selectedAccessPlan.listingIds,
        access_type: selectedAccessPlan.accessType,
        has_operation_hours: selectedAccessPlan.hasOperationHours,
        enable_access_code: selectedAccessPlan.enableAccessCode,
        enable_email_domains: selectedAccessPlan.enableEmailDomains,
        email_domains: selectedAccessPlan.emailDomains,
        access_code: selectedAccessPlan.accessCode,
        schedules: selectedAccessPlan.schedules,
      }

      UpdateAccessPlan(payload, Number(tenant.id), selectedAccessPlan.id)
        .then(() => {
          setListings(selectedAccessPlan.listings)
          setShowNoAccessPlanModal(false)
        })
        .catch((error) => console.log('Error:', error))
    }
  }

  const continueAndClose = (setVisible: (visible: boolean) => void) => {
    setShowNoAccessPlanModal(false)

    setShowNoAccessPlanModal((prevState) => {
      if (!prevState) {
        setVisible(false)
      }
      return prevState
    })
  }

  return {
    form,
    initialValues,
    accessCodeChecked,
    emailDomainChecked,
    emailDomains,
    accessType,
    driversChecked,
    scheduleType,
    checkedDays,
    isButtonDisabled,
    nameExists,
    initialCheckedState,
    conflictingData,
    allListings,
    showNoAccessPlanModal,
    chargersNotAssignedData,
    listings,
    setAccessCodeChecked,
    setEmailDomainChecked,
    setEmailDomains,
    handleDayCheckedChange,
    handleAccessTypeChange,
    handleScheduleTypeChange,
    handleValuesChange,
    createAccessPlan,
    setDriversChecked,
    setAccessType,
    setScheduleType,
    nameCheck,
    setCheckedDays,
    setConflictingData,
    updateAndSave,
    setCurrentPlanValues,
    setListings,
    setShowNoAccessPlanModal,
    editAccessPlan,
    convertSingleDaySchedule,
    addListingsBack,
    setNameExists,
    continueAndClose,
  }
}
