import { theme } from 'theme'

const { dangerPrimary } = theme.colors

export const MapHiddenSVG: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path
          id="Subtract"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.88323 5.41876C1.87778 5.4717 1.875 5.52523 1.875 5.57917V15.9833C1.87497 16.2497 1.94305 16.5117 2.07278 16.7444C2.2025 16.977 2.38955 17.1727 2.61617 17.3127C2.84278 17.4527 3.10144 17.5325 3.36756 17.5444C3.63368 17.5563 3.89843 17.5 4.13667 17.3808L7.36 15.7683C7.40346 15.7466 7.45139 15.7352 7.5 15.7352C7.54861 15.7352 7.59654 15.7466 7.64 15.7683L11.8017 17.8492C12.0185 17.9575 12.2576 18.0139 12.5 18.0139C12.7424 18.0139 12.9815 17.9575 13.1983 17.8492L13.9419 17.4775L8.125 11.6605L6.875 10.4105L1.88323 5.41876ZM18.1168 14.5812C18.1222 14.5283 18.125 14.4748 18.125 14.4208V4.01667C18.125 3.7504 18.0569 3.48855 17.9272 3.25599C17.7975 3.02342 17.6105 2.82785 17.3841 2.68785C17.1576 2.54784 16.899 2.46804 16.633 2.45602C16.367 2.444 16.1024 2.50016 15.8642 2.61917L12.64 4.23167C12.5517 4.275 12.4483 4.275 12.36 4.23167L8.19917 2.15C7.9821 2.04139 7.74272 1.98485 7.5 1.98485C7.25728 1.98485 7.0179 2.04139 6.80083 2.15V2.15084L6.05781 2.52227L11.875 8.33947V7.5C11.875 7.33424 11.9408 7.17527 12.0581 7.05806C12.1753 6.94085 12.3342 6.875 12.5 6.875C12.6658 6.875 12.8247 6.94085 12.9419 7.05806C13.0592 7.17527 13.125 7.33424 13.125 7.5V9.58947L18.1168 14.5812Z"
          fill={dangerPrimary}
        />
        <path id="Vector 194" d="M2 2L19 19" stroke={dangerPrimary} stroke-width="2" stroke-linecap="round" />
      </g>
    </svg>
  )
}
