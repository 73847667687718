import { Button, Modal } from 'antd'
import { ConflictingPlanCustomFooterLayout } from 'atom/chargers'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { renderFormatMessage } from 'helpers/intl'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { ListingRef } from 'models/listing'
import { ErrorTitleSectionModal } from './error-title-section'

interface props {
  visible: boolean
  notLinkedCharger: ListingRef[]
  setVisible: (visible: boolean) => void
  setCloseModal: (closeModal: boolean) => void
  continueAndClose: (setVisible: (visible: boolean) => void) => void
  undo: () => void
}

export const NoAccessPlanLinkedModal: React.FC<props> = ({
  visible,
  notLinkedCharger,
  setVisible,
  setCloseModal,
  continueAndClose,
  undo,
}) => {
  const { cancel } = useListingBasicTrans()
  const { continueText } = useGeneralTranslation()
  const listings = notLinkedCharger.map((listing) => listing.title).join(', ')
  const titleText = renderFormatMessage(
    'dashboard.accessPlan.noAccessPlanLinked',
    'No Access Plan Linked with {listing}',
    {
      listing: listings,
    },
  )

  return (
    <Modal visible={visible} onCancel={undo} width={450} footer={null} closable={false} centered={true}>
      <ErrorTitleSectionModal text={titleText} />
      <br />
      <div className="paragraph-01-regular">
        {renderFormatMessage(
          'dashboard.accessPlan.removeListingAnyways',
          '{listing} has been removed from this access plan and is no longer linked to any access plans. Are you sure you want to continue?',
          { listing: listings },
        )}
      </div>
      <ConflictingPlanCustomFooterLayout>
        <Button type="ghost" onClick={undo} className="half-width-button">
          {cancel}
        </Button>
        <Button
          className="ant-btn-default ant-btn-dangerous half-width-button"
          type="primary"
          onClick={() => continueAndClose(setCloseModal)}
          style={{ marginLeft: '12px' }}
        >
          {continueText}
        </Button>
      </ConflictingPlanCustomFooterLayout>
    </Modal>
  )
}
