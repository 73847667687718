import { useState } from 'react'

import { CoreInfoCard } from 'components/cards'
import { InfoIcon } from '../../../../assets/svg/info'
import { useHomeTranslation } from '../../../../hooks/translation/useHomeTranslation'
import { TrxSummary } from '../../../../models/transaction'
import { GhgInfoModal } from '../../ghg-info-modal'

interface props {
  loading: boolean
  trxProfileSummary?: TrxSummary
}

const GHGMetrics: React.FC<props> = ({ loading, trxProfileSummary }) => {
  const [showGHGModal, setShowGHGModal] = useState(false)

  const { GHGSavingsTxt } = useHomeTranslation()
  const infoTextFormatter = (infoText: JSX.Element, scale: number = 1) => (
    <>
      {infoText}
      <div className="suffix" style={{ transform: `scale(${scale})` }}>
        <p className="paragraph-01-regular">
          KG CO<sub>2</sub> eq
        </p>
      </div>
    </>
  )
  return (
    <>
      <CoreInfoCard
        title={GHGSavingsTxt}
        wsLoading={loading}
        infoIcon={<InfoIcon onClick={() => setShowGHGModal(true)} />}
        infoText={
          trxProfileSummary &&
          Number.parseFloat(
            `${trxProfileSummary.ghgSavingTotal ? trxProfileSummary.ghgSavingTotal / 1000 : 0}`,
          ).toLocaleString(undefined, {
            maximumFractionDigits: 3,
            minimumFractionDigits: 2,
          })
        }
        infoTextFormatter={infoTextFormatter}
      />
      <GhgInfoModal visible={showGHGModal} onClose={() => setShowGHGModal(false)} />
    </>
  )
}

export default GHGMetrics
