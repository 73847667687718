import { ReportFilter } from '../../models/filter'

export const reportsQuery = (f: ReportFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start_date: `${f.startDate.format('DD-MM-YYYY')}`,
    end_date: `${f.endDate.format('DD-MM-YYYY')}`,
  }

  if (f.tenants && f.tenants.length !== 0) {
    queryParams['tenant_ids'] = `${f.tenants.map((t) => t.id).join(',')}`
  }

  if (f.listingTitles && f.listingTitles.length !== 0) {
    queryParams['listing_ids'] = `${f.listingTitles.join(',')}`
  }

  if (f.selectedProvinces && f.selectedProvinces.length !== 0) {
    queryParams['province'] = `${f.selectedProvinces}`
  }

  if (f.selectedCountries && f.selectedCountries.length !== 0) {
    queryParams['country'] = `${f.selectedCountries}`
  }

  if (f.masterAccounts && f.masterAccounts.length !== 0) {
    queryParams['master_account_ids'] = `${f.masterAccounts.map((ma) => ma.id).join(',')}`
  }

  return queryParams
}
