export const mockPartnerRef = [
  { id: 11, partnerId: 1, partnerName: 'ReWire', pmsCode: null },
  { id: 12, partnerId: 2, partnerName: 'SmartOne', pmsCode: null },
  { id: 13, partnerId: 3, partnerName: 'KODE Labs', pmsCode: null },
  { id: 14, partnerId: 4, partnerName: 'Greystar', pmsCode: '1231AFD' },
  { id: 15, partnerId: 5, partnerName: 'Rogers', pmsCode: null },
  { id: 16, partnerId: 6, partnerName: 'Bell', pmsCode: null },
]

export const mockPartnersRefResponse = {
  data: mockPartnerRef,
  pagination: {
    currentPage: 1,
    perPage: 10,
    totalEntries: mockPartnerRef.length,
  },
}

export const mockAvailablePartnerRef = [
  { id: 17, partnerId: 7, partnerName: 'Telus', pmsCode: null },
  { id: 18, partnerId: 8, partnerName: 'Fido', pmsCode: null },
]
