import { useMemo } from 'react'
import queryString from 'query-string'

import { TenantRef } from 'models/tenant'
import {
  ConnectorServiceStatus,
  ConnectorServiceStatusAlias,
  ConnectorStatus,
  ConnectorStatusAlias,
} from 'models/connector'
import { log } from 'logger'
import { MasterAccountRef } from 'models/master-account'

interface ParsedTenantData {
  tenantRef?: TenantRef
  connOCPPStatus?: ConnectorStatus[]
  chargerServiceStatus?: ConnectorServiceStatus[]
  chargerConnectivityStatus?: string
  masterAccountParsed?: MasterAccountRef[]
  tenantsParsed?: TenantRef[]
}

export const useParsedTenantData = (search: string): ParsedTenantData => {
  return useMemo(() => {
    const { tenants, ocppStatus, serviceStatus, connectivityStatus, masterAccounts } = queryString.parse(search)

    log('search', queryString.parse(search))

    // Parse the query string of the tenants into an array of TenantRef
    let masterAccountParsed: MasterAccountRef[] | undefined = undefined
    let tenantsParsed: TenantRef[] | undefined = undefined

    if (typeof masterAccounts === 'string') {
      masterAccountParsed = JSON.parse(decodeURIComponent(masterAccounts))
    } else if (Array.isArray(masterAccounts)) {
      // Handle the case where masterAccounts is an array of strings, which may need joining first
      masterAccountParsed = JSON.parse(decodeURIComponent(masterAccounts.join(',')))
    }

    if (typeof tenants === 'string') {
      tenantsParsed = JSON.parse(decodeURIComponent(tenants))
    } else if (Array.isArray(tenants)) {
      // Handle the case where tenants is an array of strings, which may need joining first
      tenantsParsed = JSON.parse(decodeURIComponent(tenants.join(',')))
    }

    log('masterAccountParsed', masterAccountParsed)
    log('tenantsParsed', tenantsParsed)

    let tenantRef: TenantRef | undefined = undefined
    let connOCPPStatus: ConnectorStatus[] | undefined = undefined
    let chargerServiceStatus: ConnectorServiceStatus[] | undefined = undefined
    let chargerConnectivityStatus: string | undefined = undefined

    if (typeof ocppStatus === 'string') {
      connOCPPStatus = ConnectorStatusAlias[ocppStatus] || undefined
    }

    if (typeof serviceStatus === 'string') {
      chargerServiceStatus = ConnectorServiceStatusAlias[serviceStatus] || undefined
    }

    if (typeof connectivityStatus === 'string') {
      chargerConnectivityStatus = connectivityStatus || undefined
    }

    return {
      tenantRef,
      connOCPPStatus,
      chargerServiceStatus,
      chargerConnectivityStatus,
      masterAccountParsed,
      tenantsParsed,
    }
  }, [search])
}
