import { MasterAccountRef } from '../models/master-account'

export const mockMasterAccountRef: { [name: string]: MasterAccountRef } = {
  AutoGrid: {
    id: 1,
    name: 'AutoGrid',
    remittanceEmails: ['test0@email.com', 'testing0@gmail.com'],
    remittanceFrequency: 'monthly',
  },
  'Natural Resources of Canada': {
    id: 2,
    name: 'Natural Resources of Canada',
    remittanceEmails: ['test1@email.com', 'testing1@gmail.com'],
    remittanceFrequency: null,
  },
  'Signature Electric': {
    id: 3,
    name: 'Signature Electric',
    remittanceEmails: ['test2@email.com'],
    remittanceFrequency: null,
  },
  'Leading Head Energy': {
    id: 4,
    name: 'Leading Head Energy',
    remittanceEmails: ['test3@email.com'],
    remittanceFrequency: null,
  },
  'Opus One Solutions': {
    id: 5,
    name: 'Opus One Solutions',
    remittanceEmails: ['test4@email.com'],
    remittanceFrequency: null,
  },
  'CSC Serviceworks': {
    id: 6,
    name: 'CSC Serviceworks',
    remittanceEmails: [],
    remittanceFrequency: null,
  },
  SmartOne: {
    id: 7,
    name: 'SmartOne',
    remittanceEmails: [],
    remittanceFrequency: null,
  },
  'Aligned Climate Capital': {
    id: 8,
    name: 'Aligned Climate Capital',
    remittanceEmails: [],
    remittanceFrequency: null,
  },
}

export const mockMasterAccounts = [
  { id: 1, name: 'AutoGrid' },
  { id: 2, name: 'Natural Resources of Canada' },
  { id: 3, name: 'Signature Electric' },
  { id: 4, name: 'Leading Head Energy' },
  { id: 5, name: 'Opus One Solutions' },
  { id: 6, name: 'CSC Serviceworks' },
  { id: 7, name: 'SmartOne' },
  { id: 8, name: 'Aligned Climate Capital' },
  { id: 9, name: 'AutoGrid North' },
  { id: 10, name: 'AutoGrid South' },
] as MasterAccountRef[]
