import { theme } from 'theme'

interface MapSVGProps {
  color?: string
}

export const MapSVG: React.FC<MapSVGProps> = ({ color = theme.colors.black }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame">
      <path
        id="Vector"
        d="M6.80083 2.65C7.0179 2.54139 7.25728 2.48485 7.5 2.48485C7.74272 2.48485 7.9821 2.54139 8.19917 2.65L12.36 4.73167C12.4483 4.775 12.5517 4.775 12.64 4.73167L15.8642 3.11917C16.1024 3.00016 16.367 2.944 16.633 2.95602C16.899 2.96804 17.1576 3.04784 17.3841 3.18785C17.6105 3.32785 17.7975 3.52342 17.9272 3.75599C18.0569 3.98855 18.125 4.2504 18.125 4.51667V14.9208C18.125 15.5125 17.7908 16.0542 17.2608 16.3183L13.1983 18.3492C12.9815 18.4575 12.7424 18.5139 12.5 18.5139C12.2576 18.5139 12.0185 18.4575 11.8017 18.3492L7.64 16.2683C7.59654 16.2466 7.54861 16.2352 7.5 16.2352C7.45139 16.2352 7.40346 16.2466 7.36 16.2683L4.13667 17.8808C3.89843 18 3.63368 18.0563 3.36756 18.0444C3.10144 18.0325 2.84278 17.9527 2.61617 17.8127C2.38955 17.6727 2.2025 17.477 2.07278 17.2444C1.94305 17.0117 1.87497 16.7497 1.875 16.4833V6.07917C1.875 5.4875 2.20917 4.94584 2.73833 4.68167L6.80083 2.65084V2.65ZM7.5 5.5C7.66576 5.5 7.82473 5.56585 7.94194 5.68306C8.05915 5.80027 8.125 5.95924 8.125 6.125V13C8.125 13.1658 8.05915 13.3247 7.94194 13.4419C7.82473 13.5592 7.66576 13.625 7.5 13.625C7.33424 13.625 7.17527 13.5592 7.05806 13.4419C6.94085 13.3247 6.875 13.1658 6.875 13V6.125C6.875 5.95924 6.94085 5.80027 7.05806 5.68306C7.17527 5.56585 7.33424 5.5 7.5 5.5ZM13.125 8C13.125 7.83424 13.0592 7.67527 12.9419 7.55806C12.8247 7.44085 12.6658 7.375 12.5 7.375C12.3342 7.375 12.1753 7.44085 12.0581 7.55806C11.9408 7.67527 11.875 7.83424 11.875 8V14.875C11.875 15.0408 11.9408 15.1997 12.0581 15.3169C12.1753 15.4342 12.3342 15.5 12.5 15.5C12.6658 15.5 12.8247 15.4342 12.9419 15.3169C13.0592 15.1997 13.125 15.0408 13.125 14.875V8Z"
        fill={color}
      />
    </g>
  </svg>
)
