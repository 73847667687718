import { BaseLocal } from './base'
import configProvider from '../config'

const currentSiteOwner = configProvider.config.siteTitle

export const English: BaseLocal = {
  /*************************** General ***************************/
  'dashboard.title': 'Dashboard',
  'dashboard.title.admindashboard': 'Admin Dashboard',
  'dashboard.text.demoDashboard': 'Demo Dashboard',
  'dashboard.title.supportdashboard': 'Support Dashboard',
  'dashboard.calendar.today': 'Today',
  'dashboard.calendar.yesterday': 'Yesterday',
  'dashboard.calendar.past7days': 'Past 7 Days',
  'dashboard.calendar.past15days': 'Past 15 Days',
  'dashboard.calendar.weektodate': 'Week to date',
  'dashboard.calendar.monthtodate': 'Month to date',
  'dashboard.calendar.yeartodate': 'Year to date',
  'dashboard.calendar.lastweek': 'Last Week',
  'dashboard.calendar.lastmonth': 'Last Month',
  'dashboard.calendar.lastquarter': 'Last Quarter',
  'dashboard.calendar.customdate': 'Custom Date',
  'dashboard.calendar.thisWeek': 'This Week',
  'dashboard.calendar.thisMonth': 'This Month',
  'dashboard.calendar.thisYear': 'This Year',
  'dashboard.calendar.lastYear': 'Last Year',
  'dashboard.calendar.previousQuarter': 'Previous Quarter',
  'dashboard.error.somethingwentwrong': 'Something went wrong',
  'dashboard.text.connectivity': 'Connectivity',
  'dashboard.text.noConnection': 'No Connection',
  'dashboard.text.active': 'Active',
  'dashboard.text.access': 'Access',
  'dashboard.text.accessPlan': 'Access Plan',
  'dashboard.text.carCatelog': 'Car Catelog',
  'dashboard.text.country': 'Country',
  'dashboard.text.on': 'on',
  'dashboard.text.close': 'Close',
  'dashboard.text.connected': 'Connected',
  'dashboard.text.disconnected': 'Disconnected',
  'dashboard.text.energyConsumedWithkWh': 'Energy Consumed (kWh)',
  'dashboard.text.filter': 'Filter',
  'dashboard.selected': 'selected',
  'dashboard.button.delete': 'Delete',
  'dashboard.text.masterAccount': 'Master Account',
  'dashboard.text.account': 'Account',
  'dashboard.text.channelPartner': 'Channel Partner',
  'dashboard.text.generated': 'Generated',
  'dashboard.text.hour': 'Hour',
  'dashboard.text.by': 'by',
  'dashboard.text.billingAddress': 'Billing Address',
  'dashboard.button.create': 'Create',
  'dashboard.button.city': 'City',
  'dashboard.text.currency': 'Currency',
  'dashboard.button.country': 'Country',
  'dashboard.text.description': 'Description',
  'dashboard.button.subscribe': 'Subscribe',
  'dashboard.button.cancel': 'Cancel',
  'dashboard.text.charger': 'Charger',
  'dashboard.text.chargeDuration': 'Charge Duration',
  'dashboard.text.viewCharger': 'View Charger',
  'dashboard.text.viewMore': 'View More',
  'dashboard.text.search': 'Search',
  'dashboard.text.modify': 'Modify',
  'dashboard.text.refresh': 'Refresh',
  'dashboard.text.action': 'Action',
  'dashboard.text.details': 'Details',
  'dashboard.text.found': 'found',
  'dashboard.text.glossaryFor': 'Glossary for',
  'dashboard.text.heading': 'Heading',
  'dashboard.text.is': 'is',
  'dashboard.text.are': 'are',
  'dashbaord.text.inactive': 'Inactive',
  'dashboard.text.interval': 'Interval',
  'dashbaord.text.installer': 'Installer',
  'dashboard.text.configure': 'Configure',
  'dashboard.text.listing': 'Listing',
  'dashboard.text.listingLower': 'listing',
  'dashboard.text.listingID': 'Listing ID',
  'dashboard.text.name': 'Name',
  'dashboard.text.note': 'Note',
  'dashboard.text.netRevenue': 'Net Revenue',
  'dashboard.text.numberOfTransactions': 'Number of Transactions',
  'dashboard.text.status': 'Status',
  'dashboard.text.online': 'Online',
  'dashboard.text.offline': 'Offline',
  'dashboard.text.panel': 'Panel',
  'dashboard.text.plans': 'Plans',
  'dashboard.text.plan': 'Plan',
  'dashboard.text.postalCodeZipCode': 'Postal Code/Zip Code',
  'dashboard.text.price': 'Price',
  'dashboard.text.province': 'Province',
  'dashboard.text.provinceState': 'Province/State',
  'dashboard.text.enable': 'Enable',
  'dashboard.text.enabled': 'Enabled',
  'dashboard.text.disabled': 'Disabled',
  'dashboard.text.cancel': 'Cancel',
  'dashboard.text.apply': 'Apply',
  'dashboard.text.cancelled': 'Cancelled',
  'dashboard.text.paused': 'Paused',
  'dashboard.text.save': 'Save',
  'dashboard.text.state': 'State',
  'dashboard.text.streetAddress': 'Stress Address',
  'dashboard.text.subscribers': 'Subscribers',
  'dashboard.text.subscription': 'subscription',
  'dashboard.text.subscriptions': 'Subscriptions',
  'dashboard.text.user': 'User',
  'dashboard.text.users': 'Users',
  'dashboard.text.add': 'Add',
  'dashboard.text.edit': 'Edit',
  'dashboard.text.tenant': 'Site',
  'dashboard.text.vendor': 'Vendor',
  'dashboard.text.start': 'Start',
  'dashboard.text.stop': 'Stop',
  'dashboard.text.update': 'Update',
  'dashboard.text.error': 'Error',
  'dashboard.text.cost': 'Cost',
  'dashboard.text.location': 'Location',
  'dashboard.text.searchlocation': 'Search Location',
  'dashboard.text.map&access': 'Map & Access',
  'dashboard.text.loiteringStatuses': 'Loitering Statuses',
  'dashboard.text.pending': 'Pending',
  'dashboard.text.inactive': 'Inactive',
  'dashboard.text.all': 'All',
  'dashboard.text.weekday': 'Weekday',
  'dashboard.text.weekend': 'Weekend',
  'dashboard.text.everyday': 'Everyday',
  'dashboard.text.level2': 'Level 2',
  'dashboard.text.noDataAvailable': 'Sorry, no data to display.',
  'dashboard.text.unknown': 'Unknown',
  'dashboard.text.switchHomePage': 'Switch to the classic dashboard view',
  'dashboard.text.switchUsersPage': 'Switch to the classic users page',
  'dashboard.text.switchHomePageNewVersion': 'Switch to the new dashboard view',
  'dashboard.text.switchUsersPageNewVersion': 'Switch to the new users page',
  'dashboard.text.transactions': 'Transactions',
  'dashboard.text.transaction': 'Transaction',
  'dashboard.text.warning': 'Warning',
  'dashboard.text.noDataFor': "Sorry, couldn't any data with ",
  'dashboard.text.daily': 'Daily',
  'dashboard.text.schema': 'Schema',
  'dashboard.text.pricingSchema': 'Pricing Schema',
  'dashboard.text.select': 'Select',
  'dashboard.text.fee': 'Fee',
  'dashboard.text.suspended': 'Suspended',
  'dashboard.text.deleted': 'Deleted',
  'dashboard.text.draft': 'Draft',
  'dashboard.text.pending_sa': 'Pending SA',
  'dashboard.text.onHold': 'On Hold',
  'dashboard.text.configuration': 'Configuration',
  'dashboard.text.directDepositInformation': 'Direct Deposit Information',
  'dashboard.text.onboardingComplete': 'Onboarding Complete',
  'dashboard.text.statuses': 'Status (es)',
  'dashboard.text.accountName': 'Account Name (s)',
  'dashboard.text.archive': 'Archive',
  'dashboard.heading.realTimeData': 'Real Time Data',
  'dashboard.heading.historicalData': 'Historical Data',
  'dashboard.text.chargerId': 'Charger ID',
  'dashboard.text.chargerIds': 'Charger ID (s)',
  'dashboard.text.makeAndModel': 'Make and Model',
  'dashboard.text.yes': 'Yes',
  'dashboard.text.no': 'No',
  'dashboard.text.viewChargerDetails:': 'View Charger Details:',
  'dashboard.text.viewSiteDetails': 'View Site details',
  'dashboard.text.noLoiteringPenalty': 'No Loitering Penalty',
  'dashboard.text.minute': 'minute',
  'dashboard.text.more': 'More',
  'dashboard.text.incidences': 'Incidents',
  'dashboard.text.continue': 'CONTINUE',
  'dashboard.text.unrestricted': 'Unrestricted',
  'dashboard.text.restricted': 'Restricted',

  /*************************** Time ***************************/
  'time.years': '{count, plural, one {yr} other {yrs}}',
  'time.months': '{count, plural, one {mo} other {mos}}',
  'time.weeks': '{count, plural, one {wk} other {wks}}',
  'time.days': '{count, plural, one {d} other {d}}',
  'time.hours': '{count, plural, one {hr} other {hrs}}',
  'time.minutes': '{count, plural, one {min} other {mins}}',
  'time.seconds': '{count, plural, one {s} other {s}}',
  'time.duration.weeks': '{count} week{count, plural, one {} other (s)}',
  'time.duration.months': '{count} month',
  'time.duration.years': '{count} year{count, plural, one {} other (s)}',

  /*************************** Car ***************************/
  'dashboard.text.brand': 'Brand',
  'dashboard.text.model': 'Model',
  'dashboard.text.year': 'Year',
  'dashboard.text.socBeforeCharging': 'SOC Before Charging',
  'dashboard.text.socAfterCharging': 'SOC After Charging',

  /************************* Listing *************************/
  'dashboard.text.listingTitles': 'Listing Title (s)',
  'dashboard.text.generalPrice': 'General Price',
  'dashboard.text.preferredPrice': 'Preferred Price',
  'dashboard.text.smartCharging': 'Smart Charging',
  'dashboard.text.fundingSources': 'Funding Sources',
  'dashboard.text.trailerAccessible': 'Charging point accessible by vehicle with trailer',
  'dashboard.text.fundingSourcesPlaceholder': 'Select Funding Source(s)',
  'dashboard.text.selectAllListings': 'Select All Listings',
  'dashboard.text.viewChargerInApp': 'View Charger In App',
  'dashboard.text.editListingDetails': 'Edit Listing Details',
  'dashboard.text.editListing': 'Edit Listing',

  /************************* Listing Modal *************************/
  'dashboard.listingPage.modal.updateListing': 'Update Listing',
  'dashboard.listingPage.modal.settlement': 'Settlement',
  'dashboard.chargersPage.modal.updateListing.selected': 'selected',
  'dashboard.listingPage.modal.decommisioned.reminder': 'Reminder',
  'dashboard.chargersPage.modal.decommisioned.description':
    'Please note that changing Listing Service Status to Decommisioned will also update the following:',
  'dashboard.chargersPage.modal.decommisioned.option1.left': '• Access ',
  'dashboard.chargersPage.modal.decommisioned.option1.right': 'Restricted',
  'dashboard.chargersPage.modal.decommisioned.option2.left': '• Hidden from Map ',
  'dashboard.chargersPage.modal.decommisioned.option2.right': 'True',
  'dashboard.chargersPage.modal.decommisioned.acknowledge': 'Acknowledge',
  'dashboard.chargersPage.modal.decommisioned.cancel': 'Cancel',
  'dashboard.chargersPage.modal.updateListing.update': 'Update',
  'dashboard.chargersPage.modal.updateListing.listingUpdated': 'Listing Updated',
  'dashboard.chargersPage.modal.updateListing.listingsUpdated': 'Listings Updated',
  'dashboard.listingPage.modal.bulkUpdate.swtchCare': 'SWTCH Care',
  'dashboard.listingPage.modal.bulkUpdate.buildingWifi': 'Building Wifi',
  'dashboard.listingPage.modal.bulkUpdate.currentStatus': 'Current Status',
  'dashboard.listingPage.modal.bulkUpdate.since': '-1 since -2',
  'dashboard.listingPage.modal.bulkUpdate.about': 'About selected listing: -1',
  'dashboard.listingPage.modal.bulkUpdate.issueDetails': 'Issue Details',
  'dashboard.listingPage.modal.bulkUpdate.issueSymptom': 'Issue Symptom',
  'dashboard.listingPage.modal.bulkUpdate.details': 'Details',
  'dashboard.listingPage.modal.bulkUpdate.serviceEvent': 'Service Event',
  'dashboard.listingPage.modal.bulkUpdate.exclude': 'Exclude',
  'dashboard.listingPage.modal.bulkUpdate.include': 'Include',
  'dashboard.listingPage.modal.bulkUpdate.resolution': 'Resolution',
  'dashboard.listingPage.modal.bulkUpdate.rootCause': 'Root Cause',
  'dashboard.listingPage.modal.bulkUpdate.zendeskId': 'Zendesk ID',
  'dashboard.listingPage.modal.bulkUpdate.uptimeReport': 'Uptime Report',
  'dashboard.listingPage.modal.bulkUpdate.statusChange': 'Status Change',
  'dashboard.listingPage.modal.bulkUpdate.warning':
    'Listings can only be modified if they share the same site and service status',
  'dashboard.listingPage.modal.bulkUpdate.help':
    'For selected listing above, the logged symptom when the status was changed to Under Repair are: Offline',
  'dashboard.listingPage.modal.bulkUpdate.newStatusSince': 'New Status Since',
  'dashboard.listingPage.modal.bulkUpdate.underRepairSince': 'Under Repair Since',
  'dashboard.listingPage.modal.bulkUpdate.notes': 'Notes',
  'dashboard.text.carAndMapSettings': 'Car and Map Settings',

  /************************* Only for Listing Modal because it is used as part of a sentence *************************/
  'dashboard.chargersPage.modal.updateListing.from': 'from',

  /************************* Loitering *************************/
  'dashboard.text.loitering': 'Loitering',
  'dashboard.text.visibleOnMap': 'Visible On Map',
  'dashboard.text.hiddenFromMap': 'Hidden From Map',

  /************************* Selector *************************/
  'dashboard.text.selected.serialNumber': 'Serial Numbers Selected',
  'dashboard.text.selected.tenant': '-1 Sites Selected',

  /***************** Connector Service Status *****************/
  'dashboard.serviceStatus.awaitingCommissioning': 'Awaiting Commissioning',
  'dashboard.serviceStatus.awaiting_commissioning': 'Awaiting Commissioning',
  'dashboard.serviceStatus.decommissioned': 'Decommissioned',
  'dashboard.serviceStatus.commissioned': 'Commissioned',
  'dashboard.serviceStatus.testing': 'Testing',
  'dashboard.serviceStatus.active': 'Active',
  'dashboard.serviceStatus.property_activating': 'Activating - Pending Property',
  'dashboard.serviceStatus.driver_activating': 'Activating - Pending Driver',
  'dashboard.serviceStatus.activatingPendingProperty': 'Activating - Pending Property',
  'dashboard.serviceStatus.under_repair': 'Under Repair',
  'dashboard.serviceStatus.onHold': 'On Hold',
  'dashboard.serviceStatus.activatingPendingDriverSub': 'Activating - Pending Driver Subscription',
  'dashboard.serviceStatus.other': 'Other',
  'dashboard.serviceStatus.troubleshooting': 'Troubleshooting',
  'dashboard.serviceStatus.terminated': 'Terminated',
  'dashboard.serviceStatus.selectReason': 'Select a reason',
  'dashboard.serviceStatus.selectReasonForStatusChange': 'Select reason for status change',
  'dashboard.serviceStatus.chargerIsOffline': 'Charger is offline',
  'dashboard.serviceStatus.chargerIsFaulted': 'Charger is faulted',
  'dashboard.serviceStatus.chargerIsDamaged': 'Charger is damaged',
  'dashboard.serviceStatus.chargerIsVandalized': 'Charger is vandalized',
  'dashboard.serviceStatus.stationIsMalfunctioning': 'Station is malfunctioning',
  'dashboard.serviceStatus.otherPleaseSpecify': 'Other - Please specify',
  'dashboard.serviceStatus.priceIsNotCompetitive': 'Price is not competitive',
  'dashboard.serviceStatus.lackOfFeature': 'Lack of feature',
  'dashboard.serviceStatus.productBug': 'Product bug',
  'dashboard.serviceStatus.poorCustomerService': 'Poor customer service',
  'dashboard.serviceStatus.poorHardwareQuality': 'Poor hardware quality',
  'dashboard.serviceStatus.unitReplacement': 'Unit replacement',
  'dashboard.serviceStatus.cableReplacement': 'Cable replacement',
  'dashboard.serviceStatus.hardwarePartsReplacementDCFC': 'Hardware parts replacement (DCFC)',
  'dashboard.serviceStatus.breakerReset': 'Breaker reset',
  'dashboard.serviceStatus.networkReset': 'Network reset',
  'dashboard.serviceStatus.networkHardwareReplacement': 'Network hardware replacement',
  'dashboard.serviceStatus.specificANewReason': 'Specific a new reason',
  'dashboard.serviceStatus.typeAReason': 'Type a reason',
  'dashboard.serviceStatus.initialActivation': 'Initial activation',

  /************************** Placeholder **************************/
  'dashboard.placeholder.masterAccount': 'Select a master account',
  'dashboard.placeholder.pleaseEnterPartner': 'Please enter partner',
  'dashboard.placeholder.addNewMasterAccount': 'Add new master account',
  'dashboard.text.addPlatform': 'Add Platform',

  /*********************** General Messasge ***********************/
  'dashboard.message.positiveNumber': 'Please enter a positive number',
  'dashboard.placeholder.searchEmail': 'Search Email',
  'dashboard.placeholder.email': 'Email',

  /*************************** Navigation ***************************/
  'dashboard.navigation.dashboard': 'Dashboard',
  'dashboard.navigation.chargers': 'Chargers',
  'dashboard.navigation.transactions': 'Transactions',
  'dashboard.navigation.tenants': 'Sites',
  'dashboard.navigation.listings': 'Listings',
  'dashboard.navigation.analytics': 'Analytics',
  'dashboard.navigation.reports': 'Reports',
  'dashboard.navigation.users': 'Users',
  'dashboard.navigation.peakShaving': 'Demand Management',
  'dashboard.navigation.tagging': 'Tagging',
  /*********************** Pagination ***********************/
  'dashboard.pagination.previous': 'Previous',
  'dashboard.pagination.next': 'Next',

  /*************************** Header ***************************/
  'dashboard.header': 'Admin Dashboard',
  'dashboard.header.logout': 'Logout',
  'dashboard.header.chooseLanguage': 'Choose Language',

  /*************************** Footer ***************************/
  'dashboard.footer.poweredby': 'Powered By ',

  /************************ Export and Search Button *********************/
  'dashboard.exportbtn.heading': 'Export',
  'dashboard.exportbtn.title': 'Export Data',
  'dashboard.exportbtn.csv': 'CSV',
  'dashboard.exportbtn.pdf': 'PDF',
  'dashboard.exportbtn.info': 'Choose either to export the data via PDF or CSV. The file will be emailed to',
  'dashboard.searchBtn.title': 'Search',
  'dashboard.search.placeholder.user': 'Search user',

  /*************************** Selector ***************************/
  'dashboard.selector.searchTenant': 'Search Site',
  'dashboard.selector.Tenants': 'Site (s)',
  'dashboard.selector.searchTenants': 'Search Site(s)',
  'dashboard.selector.searchCharger': 'Search Charger',
  'dashboard.selector.searchListing': 'Search Listing',
  'dashboard.selector.serviceStatus': 'Service Status',
  'dashboard.selector.selectConnectorStatus': 'Connector Status',
  'dashboard.selector.transactionID': 'Transaction ID',
  'dashboard.selector.startTyping': 'Start typing to search',
  'dashboard.selector.vendors': 'Vendor (s)',
  'dashboard.selector.makes': 'Make (s)',

  /*************************** Charger ***************************/
  'dashboard.charger.updateAllChargers': 'Update Pricing for ALL Chargers in Plan',
  'dashboard.charger.updateThisChargers': 'Update Pricing for THIS Charger',
  'dashboard.charger.phases': 'Phases',
  'dashboard.charger.minAmp': 'Min. Cap',
  'dashboard.charger.maxAmp': 'Max. Cap',
  'dashboard.charger.fallback': 'Fallback',
  'dashboard.charger.circuitSize': 'Circuit Size',
  'dashboard.charger.existingLoad': 'Existing Load',
  'dashboard.charger.breakerSize': 'Breaker Size',
  'dashboard.charger.endPoint': 'Endpoint',
  'dashboard.charger.type.mixed': 'Mixed',
  'dashboard.charger.type.dedicated': 'Dedicated',
  'dashboard.charger.serviceStatus.active': 'Active',
  'dashboard.charger.serviceStatus.commissioning': 'Commissioning',
  'dashboard.charger.serviceStatus.activating': 'Activating',
  'dashboard.charger.serviceStatus.underRepair': 'Under Repair',
  'dashboard.charger.serialNumber': 'Serial Number',
  'dashboard.charger.serialNumbers': 'Serial Number (s)',
  'dashboard.charger.vendor': 'Vendor',
  'dashboard.charger.model': 'Model',
  'dashboard.charger.chargerType': 'Charger Type',
  'dashboard.charger.SN#': 'SN#',
  'dashboard.charger.chargerId': 'Charger ID',
  'dashboard.charger.swtchCare': 'SWTCH Care',
  'dashboard.charger.utilizationRate': 'Utilization Rate',
  'dashboard.charger.uptimePercentage': 'Uptime Percentage',
  'dashboard.text.selectAllChargers': 'Select all {totalEntries} chargers',
  'dashboard.text.allChargersSelected': 'All {count} chargers on this page selected',
  'dashboard.text.someChargersSelected':
    '{count} {chargerCount, plural, one {charger} other {chargers}} on this page selected',
  'dashboard.text.map.description': 'Whether the charger is hidden or visible on the map',

  /*************************** Connector ***************************/
  'dashboard.connector.connectorId': 'Connector ID',
  'dashboard.connector.lastStatusChangedAt': 'Last status changed at',
  'dashboard.connector.type': 'Connector Type',
  'dashboard.text.connectivity.description': 'The current connectivity status of the charger',

  /*************************** Tenant Selector Page ***************************/
  'dashboard.tenantselectorpage.pagereload': 'Loading your profile',
  'dashboard.tenantselectorpage.sessionexpired': 'Session Expired',
  'dashboard.tenantselectorpage.redirect': 'Redirecting back to the login page!',
  'dashboard.tenantselectorpage.title': 'Oops something went wrong',
  'dashboard.tenantselectorpage.subtitle': 'Try again.',
  'dashboard.tenantselectorpage.backtologin': 'Back to login',
  'dashboard.tenantselectorpage.tenantpermission.title': 'You do not have access to a Tenant',
  'dashboard.tenantselectorpage.tenantpermission.subtitle': 'Please contact an administrator',

  /*************************** Auth0 Login Page ***************************/
  'dashboard.authpage.title': 'Oops something went wrong',
  'dashboard.authpage.subtitle': 'We were unable to log you in. Try again',
  'dashboard.authpage.backtologin': 'Back to login',
  'dashboard.authpage.error,invalidcallbackparameters': 'Invalid callback parameters',

  /*************************** Home Page ***************************/
  'dashboard.homePage.home': 'Home',
  'dashboard.homePage.selectTenant': 'Select a tenant',
  'dashboard.homePage.search': 'Search',
  'dashboard.homePage.revenue': 'Revenue',
  'dashboard.homePage.revenues': 'Revenues',
  'dashboard.homePage.transaction': 'Transaction',
  'dashboard.homePage.transactions': 'Transactions',
  'dashboard.homePage.transactions.info.p1': 'of transactions were started after 6 PM',
  'dashboard.homePage.transactions.info.p2': 'of transactions were started on weekday',
  'dashboard.homePage.demandResponseText': 'from demand response',
  'dashboard.homePage.chargingText': 'Charging',
  'dashboard.homePage.fromChargingText': 'from charging',
  'dashboard.homePage.loiteringText': 'from loitering',
  'dashboard.homePage.energy': 'Energy',
  'dashboard.homePage.energy.info.p1': 'of charging occured during peak hours',
  'dashboard.homePage.energyConsumptions': 'Energy Consumption (kWh)',
  'dashboard.homePage.energyConsumptions.info.header': 'Greenhouse Gases Info',
  'dashboard.homePage.energyConsumptions.info.p1': 'How are my greenhouse gas savings computed?',
  'dashboard.homePage.energyConsumptions.info.p2':
    'ICE fuel economy in 2017 based on Canada’s average vehicle: 8.9 L of Gasoline/100 km',
  'dashboard.homePage.energyConsumptions.info.p3':
    'CO2 eq emissions per litre of gasoline for ICE: 2.3 kg of CO2 eq/L of Gasoline',
  'dashboard.homePage.energyConsumptions.info.p4':
    'EV fuel economy based on Canada’s 2020 battery electric vehicle fleet: 19.51 kWh/100 km',
  'dashboard.homePage.energyConsumptions.info.p5': 'CO2 eq emissions per kWh in Canada: 318.9 g CO2 eq/kWh',
  'dashboard.homePage.energyConsumptions.info.p6': 'In Canada',
  'dashboard.homePage.energyConsumptions.info.okbtn': 'Ok',
  'dashboard.homePage.ghgsavings': 'GHG Savings (kg C02 eq)',
  'dashboard.homePage.ghgsavings.text': 'GHG Savings',
  'dashboard.homePage.totalConnectors': 'Total Connectors',
  'dashboard.homePage.inuse': 'In Use',
  'dashboard.homePage.available': 'Available',
  'dashboard.homePage.unavailable': 'Unavailable',
  'dashboard.homePage.commissioning': 'Commissioning',
  'dashboard.homePage.totalActive': 'Total Active Connectors',
  'dashboard.homePage.activating': 'Activating',
  'dashboard.homePage.underRepair': 'Under Repair',
  'dashboard.homePage.info.totalActive': 'These are all connectors from the Available, In Use, and Unavailable blocks',
  'dashboard.homePage.info.totalActivating':
    'Connectors where service status is set to Commissioned, Activating - Pending Property, or Activating - Pending Driver Subscription',
  'dashboard.homePage.info.totalCommissioning':
    'Connectors where service status is set to On Hold, Awaiting Commissioning, or Troubleshooting',
  'dashboard.homePage.stationoverview.heading': 'Station Overview',
  'dashboard.homePage.stationoverview.activeUsers': 'Active Users',
  'dashboard.homePage.stationoverview.activeUsers.idle': 'Idle',
  'dashboard.homePage.stationOverview.activeUsers.idle.info':
    'The transaction is ongoing, but the vehicle is not actively charging.',
  'dashboard.homePage.stationoverview.connectorsPluggedIn': 'Connectors Plugged In',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.level2connectors': 'Level 2',
  'dashboard.homePage.stationoverview.connectorsPluggedIn.dcfastconnectors': 'DC Fast',
  'dashboard.homePage.stationoverview.chargersPluggedIn': 'Chargers Plugged In',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.heading': 'Connectors Enrolled in Demand Response',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.level2connectors': 'Level 2 Connectors',
  'dashboard.homePage.stationoverview.chargersParcipatingDREvents.dcfastconnectors': 'DC Fast Connectors',
  'dashboard.homePage.stationoverview.connectorAvailability': 'Connector Availability',
  'dashboard.homePage.stationoverview.connectorAvailability.level2connectors': 'Level 2 Connectors',
  'dashboard.homePage.stationoverview.connectorAvailability.dcfastconnectors': 'DC Fast Connectors',
  'dashboard.homePage.stationoverview.chargersAvailability': 'Chargers Availability',
  'dashboard.homePage.operationoverview.heading': 'Operation Overview',
  'dashboard.homePage.operationoverview.timeZone.heading': 'Time (EST)',
  'dashboard.homePage.utilizationInsights.heading': 'Utilization Insights',
  'dashboard.homePage.utilizationInsights.numberOfConnectorsPluggedIn': '# of Chargers In Use',
  'dashboard.homePage.consumptionInsights.heading': 'Consumption Insights',
  'dashboard.homePage.consumptionInsights.uniqueUsers.heading': 'Unique users',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.heading': 'Average # of transactions',
  'dashboard.homePage.consumptionInsights.avgNumberOfTransactions.day': 'day',
  'dashboard.homePage.consumptionInsights.averageConsumption.heading': 'Average consumption',
  'dashboard.homePage.consumptionInsights.averageConsumption.averageUserSpend': 'Average user spend',
  'dashboard.homePage.utilizationInsights.dial.averageChargingDurationText': 'Avg. Charging Duration',
  'dashboard.homePage.utilizationInsights.dial.averagePlugInDurationText': 'Avg. Plug-in duration',
  'dashboard.homePage.consumptionInsights.chart.title': 'Average Daily Load Profile',
  'dashboard.homePage.stationActivation.heading': 'Station Activation',
  'dashboard.homePage.transactionOverview.heading': 'Transaction Overview',
  'dashboard.homePage.seeAllTransactions.heading': 'See All Transactions',
  'dashboard.homePage.stationoverview.stations.heading': 'Stations',
  'dashboard.homePage.stationoverview.current.heading': 'Current',
  'dashboard.homePage.stationoverview.stations.onlinechargers.text': 'Online Connectors',
  'dashboard.homePage.stationoverview.stations.offlinechargers.text': 'Offline Connectors',
  'dashboard.homePage.stationoverview.stations.underrepairchargers.text': 'Under Repair',
  'dashboard.homePage.stationoverview.stations.onlinechargers.description':
    'Functional and connection established allowing for remote monitoring and management',
  'dashboard.homePage.stationoverview.stations.offlinechargers.description':
    'Unable to establish connection and may require further troubleshooting or escalation',
  'dashboard.homePage.stationoverview.stations.underrepairchargers.description':
    'Currently out of service due to maintenance to restore functionality',
  'dashboard.homePage.stationoverview.current.description1': 'Active Charger Current',
  'dashboard.homePage.stationoverview.current.description2': 'Maximum Charger Current',
  'dashboard.homePage.stationActivation.webapp': 'Web App',
  'dashboard.homePage.stationActivation.roamingpartner': 'Roaming Partner',
  'dashboard.homePage.stationActivation.mobileapp': 'Mobile App',
  'dashboard.homePage.exportGraph.pdf': 'Export as PDF',
  'dashboard.homePage.exportGraph.csv': 'Export as CSV',

  /*************************** Chargers Page ***************************/
  'dashboard.chargersPage.heading': 'Chargers',
  'dashboard.chargersPage.connector.heading': 'Connector',
  'dashboard.chargersPage.searchTenant': 'Search Site',
  'dashboard.chargersPage.selectVendor': 'Select Vendor',
  'dashboard.chargersPage.selectStatus': 'Select Status',
  'dashboard.chargersPage.table.heading.connectorId': 'Connector ID',

  'dashboard.text.initialActivationWarning':
    'Warning: Clicking “Apply” will update the Initial Activation Start Date to today’s date',

  'dashboard.chargersPage.table.heading.tenant': 'Site',
  'dashboard.chargersPage.table.heading.serviceStatus': 'Service Status',
  'dashboard.chargersPage.table.heading.status': 'Connector Status',
  'dashboard.chargersPage.modal.updatechargers': 'Update Chargers',
  'dashboard.chargersPage.modal.updatechargers.selected': 'selected',
  'dashboard.chargersPage.modal.firmware': 'Firmware',
  'dashboard.chargersPage.modal.firmware.location': 'Location',
  'dashboard.chargersPage.modal.firmware.location.warning': 'Please input a location!',
  'dashboard.chargersPage.modal.firmware.location.help':
    'Date after which the charger must retrieve the (new) firmware',
  'dashboard.chargersPage.modal.firmware.retrieveDate': 'Retrieve date',
  'dashboard.chargersPage.modal.firmware.retrieveDate.warning': 'Please retrieve a date!',
  'dashboard.chargersPage.modal.firmware.updatefirmware': 'Update Firmware',
  'dashboard.chargersPage.modal.configuration': 'Configuration',
  'dashboard.chargersPage.modal.configuration.key': 'Key',
  'dashboard.chargersPage.modal.configuration.key.info': 'Key of the value you want to change',
  'dashboard.chargersPage.modal.configuration.key.warning': 'Please input a key!',
  'dashboard.chargersPage.modal.configuration.value': 'Value',
  'dashboard.chargersPage.modal.configuration.value.info': 'Value to change',
  'dashboard.chargersPage.modal.configuration.updateConfiguration': 'Update Configuration',
  'dashboard.chargersPage.modal.configuration.updatefirmwarechanges': 'Sending firmware update for',
  'dashboard.chargersPage.modal.configuration.trigger.heading':
    'Select a message below for selected chargers to initiate a trigger message',
  'dashboard.chargersPage.modal.configuration.trigger.message': "Sending Trigger Message '123' to selected chargers",
  'dashboard.text.chargerID.description': 'Unique ID that can be found on the front of a charger',
  'dashboard.text.serialNumber.description': 'Manufacturer identifier for each charger, not visible to drivers',
  'dasboard.text.makeAndModel.description': "Make and model of the charger's manufacturer",
  'dashboard.text.pricingPlan.description':
    'Details of the usage rate set for charging along with whether reservations are allowed',
  'dashboard.text.loitering.description': 'Details if a loitering fee is applied to the usage rate',
  'dashboard.text.access.description':
    'Details whether a charger is "Open" meaning publicly accessible or "Restricted" meaning available only to those provided access through an account or access code. This section also details the access code where applicable and whether the charger is visible or hidden on public maps',
  'dashboard.text.serviceStatus.description': `The current connectivity status of the charger on the ${currentSiteOwner} network`,
  'dashboard.charger.makeAndModel': 'Make and Model',
  'dashboard.text.chargingStatus': 'Charging Status',
  'dashboard.text.lastChargingStatus': 'Last Charging Status',
  'dashboard.text.chargingStatusesSelected': '-1 Charging Statuses Selected',
  'dashboard.text.serviceStatusesSelected': '-1 Service Statuses Selected',
  'dasboard.text.accountNamesSelected': '-1 Account Names Selected',
  'dashboard.text.tagsSelected': '-1 Tags Selected',

  /*************************** Charger Page ***************************/
  'dashboard.chargerPage.allConnectors': 'All Connectors',
  'dashboard.chargerPage.noListing': 'No Listing',
  'dashboard.chargerPage.chargerStatus.heading': 'Charger Status',
  'dashboard.chargerPage.chargerStatus.energyConsumption': 'Energy Consumption (kWh)',
  'dashboard.chargerPage.chargerStatus.revenue': 'Revenue ($)',
  'dashboard.chargerPage.graph.heading': 'Revenue, Transactions, and Energy Consumption',
  'dashboard.chargerPage.connectorStatus.heading': 'Connector Status',
  'dashboard.chargerPage.connectorStatus.disconnected': 'Disconnected',
  'dashboard.chargerPage.connectorStatus.disconnected.message': 'About -1 at -2',
  'dashboard.chargerPage.chargerStatus.offline': 'Offline',
  'dashboard.chargerPage.chargerStatus.online': 'Online',
  'dashboard.chargerPage.chargerStatus.available': 'Available',
  'dashboard.chargerPage.chargerStatus.charging': 'Charging',
  'dashboard.chargerPage.chargerStatus.commissioning': 'Commissioning',
  'dashboard.chargerPage.chargerStatus.commissioned': 'Commissioned',
  'dashboard.chargerPage.chargerStatus.suspendedEV': 'SuspendedEV',
  'dashboard.chargerPage.chargerStatus.preparing': 'Preparing',
  'dashboard.chargerPage.chargerStatus.suspendedEVSE': 'SuspendedEVSE',
  'dashboard.chargerPage.chargerStatus.faulted': 'Faulted',
  'dashboard.chargerPage.chargerStatus.unavailable': 'Unavailable',
  'dashboard.chargerPage.chargerStatus.reserved': 'Reserved',
  'dashboard.chargerPage.chargerStatus.finishing': 'Finishing',
  'dashboard.chargerPage.chargerStatus.unknown': 'Unknown',
  'dashboard.chargerPage.tab.detail': 'Details',
  'dashboard.chargerPage.tab.detail.title': 'Title',
  'dashboard.chargerPage.tab.detail.connectorid': 'Connector ID',
  'dashboard.chargerPage.tab.detail.status': 'Status',
  'dashboard.chargerPage.tab.detail.tenant': 'Site',
  'dashboard.chargerPage.tab.detail.vendor': 'Vendor',
  'dashboard.chargerPage.tab.detail.firmwareVersion': 'Firmware Version',
  'dashboard.chargerPage.diagnosticsTab.heading': 'Diagnostics',
  'dashboard.chargerPage.diagnostics.files.requestTime.heading': 'Request Time',
  'dashboard.chargerPage.diagnostics.files.filename.heading': 'Filename',
  'dashboard.chargerPage.diagnostics.files.state.heading': 'State',
  'dashboard.chargerPage.diagnostics.button.text': 'Request Diagnostics',
  'dashboard.chargerPage.diagnostics.kickButton.text': 'Kick Charger',
  'dashboard.chargerPage.remoteControlTab.heading': 'Remote Control',
  'dashboard.chargerPage.remoteControlTab.description': 'Select a connector to proceed',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.heading': 'Restart charge box',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.p1': 'Click below to restart the',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.restartbtn': 'Restart',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerStarted': 'Charger 1234 has been restarted',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.chargerFailedToStart': 'Charger 1234 failed to restart',
  'dashboard.chargerPage.remoteControlTab.restartChargeBox.hardResetRequest': 'Sending Hard Reset request for',
  'dashboard.chargerPage.remoteControlTab.clearCache.heading': 'Clear Cache',
  'dashboard.chargerPage.remoteControlTab.clearCache.title': "Click below to clear the charger's cache",
  'dashboard.chargerPage.remoteControlTab.clearCache.btn.heading': 'Clear 1234 Cache',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.errormessage': 'Select a message type to send',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.message':
    'Sending message 0000 to connector -1 for charger 1234',
  'dashboard.chargerPage.remoteControlTab.clearCache.request': 'Sending clear cache request to',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.heading': 'Trigger Message',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.text':
    'Select a message below for 1234 - connector -1 to initiate a trigger message',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.trigger': 'Trigger',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.success':
    '1234 has been sent to 0000 - connector -1 for charger 1111',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.accepted': 'Accepted',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message.rejected': 'Rejected',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error':
    '1234 has failed to send to connector -1 for charger 1111',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.alert.error.message': 'Rejected',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.placeholder': 'Select a message type',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.bootNotification': 'Boot Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.diagnosticsStatusNotification':
    'Diagnostics Status Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.firmwareStatusNotification':
    'Firmware Status Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.heartbeat': 'Heart Beat',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.meterValue': 'Meter Value',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.select.statusNotification': 'Status Notification',
  'dashboard.chargerPage.remoteControlTab.triggerMessage.btn.sendTriggerMessage': 'Send Trigger Message',
  'dashboard.chargerPage.remoteControlTab.updateFirmware.heading': 'Update Firmware',
  'dashboard.chargerPage.remoteControlTab.changeConfiguration.heading': 'Change Configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.heading': 'Get Configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.text': 'Click below to get the charger configuration',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.getchargerconfiguration': "Get 1234's Configuration",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.sendchargerconfiguration':
    'Sending get configuration request to',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.key': 'Key',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.readonly': 'Read Only',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.value': 'Value',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callresult': 'Call Result',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.table.callid': 'Call ID',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.heading': "1234's Configuration",
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationRetrieved':
    'Charger 1234 configuration retrieved',
  'dashboard.chargerPage.remoteControlTab.getConfiguration.modal.configurationDidNotRetrieve':
    'Failed to retrieve Charger 1234 configuration',
  'dashboard.chargerPage.DREventTab.heading': 'Demand Response',
  'dashboard.chargerPage.logs.chargerLogs.heading': 'Charger Logs',
  'dashboard.chargerPage.logs.timestamp.heading': 'Timestamp',
  'dashboard.chargerPage.logs.heading': 'Log',
  'dashboard.chargerPage.logs.errorCode.heading': 'Error Code',
  'dashboard.chargerPage.logs.vendorErrorCode.heading': 'Vendor Error Code',
  'dashboard.chargerPage.listing.heading': 'Listing',
  'dashboard.chargerPage.listing.listingTitle.heading': 'Listing Title',
  'dashboard.chargerPage.listing.connectorid.heading': 'Connector ID',
  'dashboard.chargerPage.listing.price.heading': 'Price',
  'dashboard.chargerPage.listing.pricetype.heading': 'Price Type',
  'dashboard.chargerPage.listing.action.heading': 'Action',
  'dashboard.chargerPage.listing.action.modifybtn': 'Modify',
  'dashboard.chargerPage.listing.action.saveBtn': 'Save',
  'dashboard.chargerPage.listing.action.cancelBtn': 'Cancel',

  /*************************** Transactions Page ***************************/
  'dashboard.transactionsPage.heading': 'Transactions',
  'dashboard.transactionsPage.exportbtn': 'Export',
  'dashboard.transactionsPage.advancedSearch': 'Advanced Search',
  'dashboard.transactionsPage.advancedSearchHide': 'Hide Advanced Search',
  'dashboard.transactionsPage.advancedSearch.transactionId': 'Transaction ID',
  'dashboard.transactionsPage.advancedSearch.username': 'User Name',
  'dashboard.transactionsPage.advancedSearch.useremail': 'User Email',
  'dashboard.transactionsPage.advancedSearch.useremail.warning': 'The input is not valid E-mail!',
  'dashboard.transactionsPage.advancedSearch.ocppTransactionId': 'OCPP Transaction ID',
  'dashboard.transactionsPage.advancedSearch.startMethod': 'Start Method',
  'dashboard.transactionsPage.advancedSearch.state': 'State',
  'dashboard.transactionsPage.advancedSearch.state.cancelled': 'cancelled',
  'dashboard.transactionsPage.advancedSearch.state.canceled': 'Canceled',
  'dashboard.transactionsPage.advancedSearch.state.confirmed': 'confirmed',
  'dashboard.transactionsPage.advancedSearch.state.completed': 'Completed',
  'dashboard.transactionsPage.advancedSearch.state.errored': 'Errored',
  'dashboard.transactionsPage.advancedSearch.state.free': 'Free',
  'dashboard.transactionsPage.advancedSearch.state.ongoing': 'Ongoing',
  'dashboard.transactionsPage.advancedSearch.state.no_reservation': 'no_reservation',
  'dashboard.transactionsPage.advancedSearch.state.paid': 'paid',
  'dashboard.transactionsPage.advancedSearch.state.preauthorized': 'preauthorized',
  'dashboard.transactionsPage.advancedSearch.state.checked_in': 'checked_in',
  'dashboard.transactionsPage.advancedSearch.state.pending_payment': 'Pending Payment',
  'dashboard.transactionsPage.advancedSearch.startMethod.rfid': 'rfid',
  'dashboard.transactionsPage.advancedSearch.startMethod.mobile': 'mobile',
  'dashboard.transactionsPage.advancedSearch.startMethod.website': 'website',
  'dashboard.transactionsPage.advancedSearch.startMethod.location': 'Location',
  'dashboard.transactionsPage.totalenergy': 'Total Energy (kWh)',
  'dashboard.transactionsPage.totalnetenergy': 'Total Net Revenue',
  'dashboard.transactionsPage.totalChargingDuration': 'Total Charging Duration',
  'dashboard.transactionsPage.table.transactionId': 'ID',
  'dashboard.transactionsPage.table.startMethod': 'Start Method',
  'dashboard.transactionsPage.table.user': 'User',
  'dashboard.transactionsPage.table.userName': 'User Name',
  'dashboard.transactionsPage.table.state': 'State',
  'dashboard.transactionsPage.table.state.errored': 'Errored',
  'dashboard.transactionsPage.table.state.pendingpayment': 'Pending Payment',
  'dashboard.transactionsPage.table.state.free': 'Free',
  'dashboard.transactionsPage.table.state.confirmed': 'Confirmed',
  'dashboard.transactionsPage.table.state.cancelled': 'Cancelled',
  'dashboard.transactionsPage.table.state.no_reservation': 'no_reservation',
  'dashboard.transactionsPage.table.state.paid': 'Paid',
  'dashboard.transactionsPage.table.state.preauthorized': 'preauthorized',
  'dashboard.transactionsPage.table.state.checkedin': 'Checked In',
  'dashboard.transactionsPage.table.duration': 'Duration',
  'dashboard.transactionsPage.table.connectedAt': 'Connected At',
  'dashboard.transactionsPage.table.connected': 'Connected',
  'dashboard.transactionsPage.table.disconnected': 'Disconnected',
  'dashboard.transactionsPage.table.disconnectedAt': 'Disconnected At',
  'dashboard.transactionsPage.table.plugInDuration': 'Plug-in Duration',
  'dashboard.transactionsPage.table.energy': 'Energy (kWh)',
  'dashboard.transactionsPage.table.total': 'Total',
  'dashboard.transactionsPage.table.totalEnergy': 'Total Energy',
  'dashboard.transactionsPage.table.action': 'Action',
  'dashboard.transactionsPage.table.nestedTable.heading.connectorId': 'Connector ID',
  'dashboard.transactionsPage.table.nestedTable.heading.battery': 'Battery Percent (%)',
  'dashboard.transactionsPage.table.nestedTable.heading.chargedAt': 'Charged At',
  'dashboard.transactionsPage.table.nestedTable.heading.endChargedAt': 'End Charging At',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingDuration': 'Charging Duration',
  'dashboard.transactionsPage.table.nestedTable.heading.subtotal': 'Sub-total',
  'dashboard.transactionsPage.table.nestedTable.heading.tax': 'Tax',
  'dashboard.transactionsPage.table.nestedTable.heading.seller': 'Seller',
  'dashboard.transactionsPage.table.nestedTable.heading.chargingStats': 'Charging Stats',
  'dashboard.transactionsPage.chargingStart': 'Charging Start',
  'dashboard.transactionsPage.chargingEnd': 'Charging End',
  'dashboard.transactionsPage.chargingDuration': 'Charging Duration',
  'dashboard.transactionsPage.statsView.totalTransactions': 'Total Transactions',
  'dashboard.transactionsPage.statsView.totalEnergyWithKwh': 'Total Energy (kWh)',
  'dashboard.transactionsPage.statsView.totalEnergy': 'Total Energy',
  'dashboard.transactionsPage.statsView.totalNetRevenue': 'Total Net Revenue',
  'dashboard.transactionsPage.export.message':
    'Choose either to export the data via PDF or CSV. The file will be emailed to',

  /*************************** Transaction Page ***************************/
  'dashboard.transactionPage.transactionId': 'Transaction ID',
  'dashboard.transactionPage.tenant': 'Site',
  'dashboard.transactionPage.startMethod': 'Start Method',
  'dashboard.transactionPage.user': 'User',
  'dashboard.transactionPage.batteryLevel': 'Battery Level (%)',
  'dashboard.transactionPage.connectedAt': 'Connected At',
  'dashboard.transactionPage.disconnectedAt': 'Disconnected At',
  'dashboard.transactionPage.pluginDuraction': 'Plug-in Duration',
  'dashboard.transactionPage.energy': 'Energy (kWh)',
  'dashboard.transactionPage.total': 'Total',
  'dashboard.transactionPage.stop': 'Stop',
  'dashboard.transactionPage.start': 'Start',
  'dashboard.transactionPage.error': 'Error',
  'dashboard.transactionPage.action': 'Action',
  'dashboard.transactionPage.listing': 'Listing',
  'dashboard.transactionPage.connectorId': 'Connector ID',
  'dashboard.transactionPage.chargedAt': 'Charged At',
  'dashboard.transactionPage.endChargingAt': 'End Charging At',
  'dashboard.transactionPage.chargingDuration': 'Charging Duration',
  'dashboard.transactionPage.subtotal': 'Sub-Total',
  'dashboard.transactionPage.tax': 'Tax',
  'dashboard.transactionPage.seller': 'Seller',
  'dashboard.transactionsPage.statsView.cancel': 'Cancel',
  'dashboard.transactionsPage.statsView.error': 'Error',
  'dashboard.transactionsPage.statsView.total': 'Total',

  /*************************** Transaction State Badge  ***************************/
  'dashboard.transaction.confirmed': 'Confirmed',
  'dashboard.transaction.free': 'Free',
  'dashboard.transaction.pending_payment': 'Pending Payment',
  'dashboard.transaction.canceled': 'Cancelled',
  'dashboard.transaction.errored': 'Errored',
  'dashboard.transaction.paid': 'Paid',
  'dashboard.transaction.preauthorized': 'Pre Authorized',
  'dashboard.transaction.noReservation': 'No Reservation',
  'dashboard.transaction.ongoing': 'Ongoing',
  'dashboard.transaction.completed': 'Completed',
  'dashboard.transaction.reserved': 'Reserved',

  /*************************** Tenants Page ***************************/
  'dashboard.tenantsPage.heading': 'Sites',
  'dashboard.tenantsPage.createNewTenantBtn': 'Create New Site',
  'dashboard.tenantsPage.searchTenantTxt': 'Search Site',
  'dashboard.tenantsPage.table.heading.name': 'Name',
  'dashboard.tenantsPage.table.heading.chargerCount': 'Charger Count',

  /******************* Individual Tenant Page ***************************/
  'dashboard.tenantPage.detailTab.heading': 'Detail',
  'dashboard.tenantPage.detailTab.channelPartner': 'Channel Partner',
  'dashboard.tenantPage.overviewTab.uniformServiceStatuses': 'Uniform Service Statuses',
  'dashboard.tenantPage.overviewTab.heading': 'Overview',
  'dashboard.tenantPage.overviewTab.editTenant': 'Edit Site Status',
  'dashboard.tenantPage.chargersTab.heading': 'Chargers',
  'dashboard.tenantPage.detailTab.accessCode.warning': 'Please enter a 6-digit access code',
  'dashboard.tenantPage.detailTab.ocppName.heading': 'OCPP Name',
  'dashboard.tenantPage.detailTab.ocppName.warning': 'Please enter a OCPP Name',
  'dashboard.tenantPage.detailTab.address.warning': 'Please enter an Address',
  'dashboard.tenantPage.detailTab.city.warning': 'Please enter City',
  'dashboard.tenantPage.detailTab.displayName.heading': 'Display Name',
  'dashboard.tenantPage.detailTab.address.heading': 'Address',
  'dashboard.tenantPage.detailTab.city.heading': 'City',
  'dashboard.tenantPage.detailTab.postalCode.heading': 'Postal Code',
  'dashboard.tenantPage.detailTab.postalCode.warning':
    "That's a bit long isn't it? Please limit yourself to 10 characters here.",
  'dashboard.tenantPage.detailTab.countryAndProvince.heading': 'Country and Province',
  'dashboard.tenantPage.detailTab.timezone': 'Time Zone',
  'dashboard.tenantPage.detailTab.location': 'Location',
  'dashboard.tenantPage.detailTab.open247': 'Open 24/7',
  'dashboard.tenantPage.detailTab.ocpiSharing': 'OCPI Sharing',
  'dashboard.tenantPage.detailTab.saveBtn': 'Save',
  'dashboard.tenantPage.detailTab.resetbtn': 'Reset',
  'dashboard.tenantPage.detailTab.ocppNameAlreadyExists.warning': 'OCPP Name already exists',
  'dashboard.tenantPage.detailTab.displayNameAlreadyExists.warning': 'Display Name already exists',
  'dashboard.tenantPage.postalCode.19103.info': 'Tax rate of 8% will apply to chargers in this zip code',
  'dashboard.tenantPage.overviewTab.heading.accessPolicy': 'Access Policy',
  'dashboard.tenantPage.overviewTab.heading.billingPlan': 'Billing Plan',
  'dashboard.tenantPage.contactInfo.ownerContact': 'Owner Contact',
  'dashboard.tenantPage.contactInfo.ownerContact.info':
    'If charger is privately owned by a condo or strata resident, indicate the owner’s contact information here',
  'dashboard.tenantPage.contactInfo.accountContact': 'Account Contact',
  'dashboard.tenantPage.contactInfo.onsiteContact': 'On-Site Contact',
  'dashboard.tenantPage.contactInfo.installerContact': 'Installer Contact',
  'dashboard.tenantPage.contactInfo.additionalContacts': 'Additional Contacts',
  'dashboard.tenantPage.contactInfo.add-additionalContacts': 'Add Additional Contacts',
  'dashboard.tenantPage.contactInfo.location.err': 'Please update tenants Location',
  'dashboard.tenantPage.contactInfo.firstName': 'First Name',
  'dashboard.tenantPage.contactInfo.lastName': 'Last Name',
  'dashboard.tenantPage.contactInfo.title': 'Job Title',
  'dashboard.tenantPage.contactInfo.modal.title': 'Add Additional Contact',
  'dashboard.tenantPage.contactInfo.modal.addbtn': 'Add',
  'dashboard.tenantPage.contactInfo.phoneNumber': 'Phone Number',
  'dashboard.tenantPage.contactInfo.email': 'Email',
  'dashboard.tenantPage.contactInfo.typeofcontact': 'Type Of Contact',
  'dashboard.tenantPage.contactInfo.validemail.warning': 'Enter valid E-mail!',
  'dashboard.tenantPage.contactInfo.typeofcontactalreadyexixts.warning': 'Type of contact already exists',
  'dashboard.tenantPage.contactInfo.typeofcontact.warning': 'Please enter type of contact',
  'dashboard.tenantPage.contactInfo.propertymanagementcompany': 'Property Management Company',
  'dashboard.tenantPage.contactInfo.contactUpdated': 'Contact Updated',
  'dashboard.tenantPage.contactInfo.contactCreated': 'Contact Created',
  'dashboard.tenantPage.deployment.parkingstalls.heading': 'Parking Stalls',
  'dashboard.tenantPage.deployment.parkingstall.heading': 'Parking Stall',
  'dashboard.tenantPage.deployment.loadmanagementconfiguration.heading': 'Load Management Configuration',
  'dashboard.tenantPage.deployment.remotedesktopids.heading': 'Remote Desktop IDs',
  'dashboard.tenantPage.deployment.electricalservice.heading': 'Electrical Service',
  'dashboard.tenantPage.deployment.networkdevice.heading': 'Network Device',
  'dashboard.tenantPage.deployment.notes.heading': 'Notes',
  'dashboard.tenantPage.deployment.btn.save': 'Save',
  'dashboard.tenantPage.deployment.btn.modify': 'Modify',
  'dashboard.tenantPage.deployment.btn.addfield': 'Add Field',
  'dashboard.tenantPage.deployment.btn.cancel': 'Cancel',
  'dashboard.tenantPage.deployment.successmsg': 'Deployment Information Updated',
  'dashboard.tenantPage.deployment.modal.addnewparkingstall.heading': 'Add New Parking Stall Field',
  'dashboard.tenantPage.deployment.modal.loadmanagementconfiguration.heading':
    'Add New Load Management Configuration Field',
  'dashboard.tenantPage.deployment.modal.remotedesktopids.heading': 'Add New Remote Desktop ID',
  'dashboard.tenantPage.deployment.field.warning': 'Field cannot be blank!',
  'dashboard.tenantPage.overviewTab.heading.userType': 'User Type',
  'dashboard.tenantPage.overviewTab.heading.networkinformation': 'Network Information',
  'dashboard.tenantPage.overviewTab.heading.circuitSharingInformation': 'Circuit Sharing Information',
  'dashboard.tenantPage.overviewTab.saveBtn': 'Save',
  'dashboard.tenantPage.overviewTab.modifybtn': 'Modify',
  'dashboard.tenantPage.overviewTab.cancelBtn': 'Cancel',
  'dashboard.tenantPage.overviewTab.tenantUpdatedMessage': '-1 has been updated!',
  'dashboard.tenantPage.overviewTab.status.heading': 'Status',
  'dashboard.tenantPage.overviewTab.status.inprogress': 'In Progress',
  'dashboard.tenantPage.overviewTab.status.commissioned': 'Commissioned',
  'dashboard.tenantPage.overviewTab.status.active': 'Active',
  'dashboard.tenantPage.overviewTab.status.inmaintenance': 'In Maintenance',
  'dashboard.tenantPage.overviewTab.status.nonNetworked': 'Non-Networked',
  'dashboard.tenantPage.overviewTab.status.testerInternal': 'Tester – Internal',
  'dashboard.tenantPage.overviewTab.status.testerExternal': 'Tester – External',
  'dashboard.tenantPage.overviewTab.status.decommissioned': 'Decommissioned',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.heading': 'O&M Opt-in',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.yes': 'Yes',
  'dashboard.tenantPage.overviewTab.o_m_opt_in.no': 'No',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus': 'Charger Service Status',
  'dashboard.tenantPage.overviewTab.chargerServiceStatus.help':
    "IMPORTANT: Selecting one of the values will update all chargers' service status associated with this site",
  'dashboard.tenantPage.overviewTab.connectorServiceStatus': 'Connector Service Status',
  'dashboard.tenantPage.overviewTab.status.help':
    'NOTE: Site cannot be deleted if one or more listings are still attached!',
  'dashboard.tenantPage.locationTab.uniformupdate.help':
    'IMPORTANT: Checking this checkbox will hide all listings associated with this site from the map',
  'dashboard.tenantPage.location.heading': 'Location',
  'dashboard.tenantPage.accesscontrolsetting.heading': 'Access Control Setting',
  'dashboard.tenantPage.accesscontrolsetting.restricted': 'Restricted',
  'dashboard.tenantPage.accesscontrolsetting.openaccess': 'Open Access',
  'dashboard.tenantPage.accesscode.heading': 'Access Code',
  'dashboard.tenantPage.accesspolicy.heading': 'Access Policy',
  'dashboard.tenantPage.accesscode.option.public': 'Public',
  'dashboard.tenantPage.accesscode.option.privateindividual': 'Private Individual',
  'dashboard.tenantPage.accesscode.option.privateshared': 'Private Shared',
  'dashboard.tenantPage.notes': 'Notes',
  'dashboard.tenantPage.notes.warning': 'Limit is 65,535 characters',
  'dashboard.tenantPage.heading.pricing': 'Pricing',
  'dashboard.tenantPage.heading.pricingSchema': 'Pricing Schema',
  'dashboard.tenantPage.heading.contactInfo': 'Contact Info',
  'dashboard.tenantPage.heading.loadManagement': 'Load Management',
  'dashboard.tenantPage.heading.deployment': 'Deployment',
  'dashboard.tenantPage.chargersTab.listings': 'Listings',
  'dashboard.tenantPage.chargersTab.tenantupdated': 'has been updated!',
  'dashboard.tenantPage.chargersTab.utilizationRate': 'Utilization Rate',
  'dashboard.tenantPage.chargersTab.utilizationRate.description':
    'Utilization Rate is calculated based on the time the charger is connected to a vehicle, regardless of whether the vehicle is actively charging or not (i.e. Charger OCPP Status Preparing, Charging, Finishing, SuspendedEV).',
  'dashboard.tenantPage.chargersTab.uptimePercentage': 'Uptime Percentage',
  'dashboard.tenantPage.chargersTab.uptimePercentage.description':
    'Uptime Percentage measures the time the charger is operational—either online, available for use, or actively charging a vehicle. This percentage excludes downtime caused by factors beyond SWTCH’s control, such as vandalism, city-wide outages, or natural disasters.',
  'dashboard.tenantPage.eyedroIpAddress.heading': 'Building Service - EV Panel Load',
  'dashboard.tenantPage.eyedroIpAddress.powerfactor': 'Power Factor',
  'dashboard.tenantPage.eyedroIpAddress.voltage': 'Voltage (V)',
  'dashboard.tenantPage.eyedroIpAddress.amperage': 'Amperage (A)',
  'dashboard.tenantPage.eyedroIpAddress.wattage': 'Wattage (W)',
  'dashboard.tenantPage.eyedroIpAddress.ipaddress': 'IP Address',
  'dashboard.tenantPage.error.geocodingError': 'Geocoding result is outside province',
  'dashboard.tenantPage.error.coordinateError': 'Could not find coordinates for this address',
  'dashboard.tenantPage.error.countryAndProvinceError': 'Please pick a country and province for this tenant',
  'dashboard.tenantPage.error.googleLatLongError': 'Google did not give us a latitude and longitude for that address',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.checkbox.text': 'Uniform Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enabled':
    'Uniform Pricing Enabled for all the listings in the tenant',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.disabled':
    'Uniform Pricing Disabled for all the listings in the tenant',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.updated': 'uniform pricing updated!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p1':
    'If enabled, it indicates that all chargers in this tenant will have the same pricing configuration.',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.info.p2':
    ' If disabled, then each charger’s pricing settings must be configured individually',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.listing': 'Listing Author',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.stripeAccount.text': 'Stripe Account',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectListingAuthorText': 'Select listing author',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectStripeAccountText': 'Select Stripe Account',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceCentsText': 'Price ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.kwOutputBasedPricingText': 'Hourly pricing based on kWh',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enableTenantPreferredPricingText':
    'Enable Tenant Preferred Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPricingText': 'Site Preferred Pricing ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredWarningText':
    'Preferred Price cannot be empty!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.tenantPreferredPriceTypeWarningText':
    'Please select a type for preferred price',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText': 'Price',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceTypeHourText': 'Hour',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.flatFeeText': 'Flat Fee ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionStructureText':
    'Define New Commission Structure',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeText': 'Define New Commission Fee Type',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionFeeText': 'Define New Commission Fee (%)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.companyCommissionFee': `${currentSiteOwner} Commission Fee (%)`,
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.partner': 'Revenue Share Partner',
  'dashboard.text.partnerSharePercentage': 'Partner Share (%)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.defineNewComissionTypeWarningText':
    'Commission fee cannot be empty!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.guestFeeText': 'Guest Fee ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.paymentLimitText': 'Payment Limit ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText': 'Loitering Status',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.none': 'None',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.statusBased': 'Status Based',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringStatusText.timeBased': 'Time Based',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText':
    'Loitering Time Limit For Time-Based In Minutes',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringPenaltyPerHour': 'Loitering Penalty Per Hour ($)',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimit.emptyfield': 'Field cannot be empty!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.loiteringtimeLimitText.gracetimelimit.minutes':
    'Loitering Time Limit For Grace-Period in Minutes',
  'dashboard.tenantPage.uniformPricingTab.updatedtext': 'uniform pricing updated!',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectComissionFeeText': 'Select Commission Fee',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPreferredPricingOptionText':
    'Select Preferred Pricing Option',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPricingOptionText': 'Select Pricing Option',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.saveBtn': 'Save',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.modifybtn': 'Modify',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.cancelBtn': 'Cancel',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.toggleUniformPricingText': 'Toggle Uniform Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing.text': 'Uniform Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.uniformPricing..enable.text': 'Enable',
  'dashboard.tenantPage.accessCodeuniformUpdateText': 'Uniform Access Control',
  'dashboard.tenantPage.mapSettingsuniformUpdateText': 'Uniform Map Settings',
  'dashboard.tenantPage.locationTab.hideTenantFromListingsText': 'Listings Hidden on Map',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.heading': 'Enable Time of Day Pricing',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekdays': 'Weekdays',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekends': 'Weekends',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.start': 'Start',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.end': 'End',
  'dashboard.tenantPage.uniformPricingTab.uniformPricing.enablePricingForTenantMembers':
    'Enable pricing for site members',
  'dashboard.tenantPage.enableLoitering': 'Disable Loitering For Site Member',
  'dashboard.tenantPage.overviewTab.taxRegistration.heading': 'Tax Registration',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.registered': 'Registered',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.not_registered': 'Not Registered',
  'dashboard.tenantPage.overviewTab.taxRegistration.option.joint_elected': 'Joint Elected',
  'dashboard.tenantPage.overviewTab.remittanceEmail': 'Remittance Email(s)',
  'dashboard.tenantPage.overviewTab.remittanceFrequency': 'Remittance Frequency',
  'dashboard.tenantPage.overviewTab.editAccountInfo': 'Edit Account Info',
  'dashboard.tenantPage.overviewTab.editAccountDetails': 'Edit Account Details',
  'dashboard.tenantPage.overviewTab.commaDelimited': 'Comma delimited',
  'dashboard.tenantPage.overviewTab.monthly': 'Monthly',
  'dashboard.tenantPage.overviewTab.quarterly': 'Quarterly',
  'dashboard.tenantPage.overviewTab.annually': 'Annually',
  'dashboard.tenantPage.overviewTab.caas': 'CaaS',
  'dashboard.tenantPage.overviewTab.swtchCare': 'SWTCH Care',
  'dashboard.tenantPage.overviewTab.arenaOrStadium': 'Arena or Stadium',
  'dashboard.tenantPage.overviewTab.campground': 'Campground',
  'dashboard.tenantPage.overviewTab.fleet': 'Fleet',
  'dashboard.tenantPage.overviewTab.gasStation': 'Gas Station',
  'dashboard.tenantPage.overviewTab.government': 'Government',
  'dashboard.tenantPage.overviewTab.hospital': 'Hospital',
  'dashboard.tenantPage.overviewTab.hotel': 'Hotel',
  'dashboard.tenantPage.overviewTab.multiUnitResidential': 'Multi Unit Residential',
  'dashboard.tenantPage.overviewTab.onStreet': 'On Street',
  'dashboard.tenantPage.overviewTab.parkingLot': 'Parking Lot',
  'dashboard.tenantPage.overviewTab.parkingGarage': 'Parking Garage',
  'dashboard.tenantPage.overviewTab.public': 'Public',
  'dashboard.tenantPage.overviewTab.retail': 'Retail',
  'dashboard.tenantPage.overviewTab.schoolUniversity': 'School/University',
  'dashboard.tenantPage.overviewTab.singleFamilyResidential': 'Single Family Residential',
  'dashboard.tenantPage.overviewTab.workplace': 'Workplace',
  'dashboard.tenantPage.pricingSchemaTab.time': 'Time',
  'dashboard.tenantPage.pricingSchemaTab.nameInUse': 'Name already in use',
  'dashboard.tenantPage.pricingSchemaTab.hoursNotDefined': 'All hours not defined will follow default rate.',
  'dashboard.tenantPage.pricingSchemaTab.to': 'TO',
  'dashboard.tenantPage.pricingSchemaTab.constants': 'Constants',
  'dashboard.tenantPage.pricingSchemaTab.defineNewCommissionFee': 'Define New Commission Fee',
  'dashboard.tenantPage.pricingSchemaTab.rate': 'Rate',
  'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustment': 'Curtailment Adjustment',
  'dashboard.tenantPage.pricingSchemaTab.timeOfDay': 'Time of Day',
  'dashboard.tenantPage.pricingSchemaTab.weekdayRate': 'Weekday Rate',
  'dashboard.tenantPage.pricingSchemaTab.weekendRate': 'Weekend Rate',
  'dashboard.tenantPage.pricingSchemaTab.loiteringRate': 'Loitering Rate',
  'dashboard.tenantPage.pricingSchemaTab.gracePeriod': 'Grace Period (min)',
  'dashboard.tenantPage.pricingSchemaTab.timeLimit': 'Time Limit (min)',
  'dashboard.tenantPage.pricingSchemaTab.timeOfDayLoitering': 'Time of Day Loitering',
  'dashboard.tenantPage.pricingSchemaTab.weekdayLoiteringRate': 'Weekday Loitering Rate',
  'dashboard.tenantPage.pricingSchemaTab.weekendLoiteringRate': 'Weekend Loitering Rate',
  'dashboard.tenantPage.pricingSchemaTab.paymentProcessingFee': 'Payment Processing Fee',
  'dashboard.tenantPage.pricingSchemaTab.commissionFee': 'Commission Fee',
  'dashboard.tenantPage.pricingSchemaTab.newCommissionFee': 'New Commission Fee',
  'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustmentEnabled': 'Curtailment Adjustment Pricing Enabled',
  'dashboard.tenantPage.pricingSchemaTab.maxLoitering': 'Max Loitering',
  'dashboard.tenantPage.pricingSchemaTab.commissionPercent': 'Commission Percent',
  'dashboard.tenantPage.pricingSchemaTab.discountPlan': 'Discount Plan',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan': 'Pricing Plan',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan.updated': 'Pricing schema Updated Successfully',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan.created': 'Pricing schema created successfully',
  'dashboard.tenantPage.pricingSchemaTab.pricingPlan.selectedListings': 'Selected Charger(s): ',
  'dashboard.tenantPage.tenantTabOverview.tenantStatus': 'Site Status',
  'dashboard.tenantPage.tenantTabOverview.editStatus': 'Edit Status',
  'dashboard.tenantPage.tenantTabOverview.accountName': 'Account Name',
  'dashboard.text.accountNames': 'Account Name (s)',
  'dashboard.tenantPage.partnerIntegrationTab.heading': 'Platform Integration',
  'dashboard.tenantPage.partnerIntegrationTab.partner': 'Platform',
  'dashboard.tenantPage.pricingSchemaTab.curtailmentAdjustmentInfoText':
    'If enabled, hourly rate will be prorated based on output',
  'dashboard.tenantPage.pricingSchemaTab.selectedListingsInfoText':
    'Selected listings will be updated to the new pricing plan',
  'dashboard.tenantPage.pricingSchemaTab.maxLoiteringInfoText':
    'This is the maximum amount drivers will get charged for loitering.',
  'dashboard.tenantPage.pricingSchemaTab.paymentLimitText': 'Payment Limit',
  'dashboard.text.initialActivationDate': 'Initial Activation Date',
  'dashboard.tenantPage.overviewTab.chargerSettings': 'Charger Settings',
  'dashboard.tenantPage.overviewTab.chargerSettingsSelected': 'Charger Settings Selected',
  'dashboard.text.propertyManagementSoftwareCode': 'Property Management Software Code',
  'dashboard.text.platformSuccess': 'Platform updated Successfully',
  'dashboard.text.selectPartner': 'Select a Partner',
  'dashboard.text.platformCode': 'Platform Code',
  'dashboard.tenantPage.pricingSchemaTab.canadaKwhInfo':
    'In order to set kWh pricing, you must apply through Measurement Canada’s Temporary Dispensation program. You can review <link1>the terms and conditions here<link1>. If eligible, apply for dispensation directly via the <link2>Measurement Canada website here.<link2>',

  /******************* Edit Tenant Page ***************************/
  'dashboard.tenantPage.editTenant.inActiveDescription':
    'Please note that changing Tenant Status to Inactive will also update the following:',
  'dashboard.tenantPage.editTenant.inActiveOption1Left': 'Listings Service Statuses',
  'dashboard.tenantPage.editTenant.inActiveOption1Right': 'Decommissioned',
  'dashboard.tenantPage.editTenant.inActiveOption2Left': 'Listings Access',
  'dashboard.tenantPage.editTenant.inActiveOption2Right': 'Restricted',
  'dashboard.tenantPage.editTenant.inActiveOption3Left': 'Listings Hidden from Map',
  'dashboard.tenantPage.editTenant.inActiveOption3Right': 'True',
  'dashboard.tenantPage.editTenant.inActiveOption4Left': 'OCPI Sharing',
  'dashboard.tenantPage.editTenant.inActiveOption4Right': 'False',

  'dashboard.tenantPage.editTenant.deletedDescription':
    'Please note that changing Tenant Status to Deleted will also update the following:',
  'dashboard.tenantPage.editTenant.deletedDescriptionOption1Left': 'OCPI Sharing',
  'dashboard.tenantPage.editTenant.deletedDescriptionOption1Right': 'False',

  /******************* Create Tenant Page ***************************/
  'dashboard.createnewtenantspage.heading': 'Create New Site',
  'dashboard.tenantPage.chargersTab.tenantcreated': 'has been successfully created!',

  /******************* Analytics Page *********************************/
  'dashboard.analyticsPage.heading': 'Disconnection Summary',
  'dashboard.analyticsPage.searchtenant': 'Search Site',
  'dashboard.analyticsPage.searchBtn': 'Search',
  'dashboard.analyticsPage.table.heading.avgDisconnectDuration': 'Tenant',
  'dashboard.analyticsPage.table.heading.chargerSerialNumber': 'Charger Serial Number',
  'dashboard.analyticsPage.table.heading.date': 'Date',
  'dashboard.analyticsPage.table.heading.avgDailyDisconnect': 'Avg Daily Disconnect',
  'dashboard.analyticsPage.table.heading.totalDisconnect': 'Total Disconnect',
  'dashboard.analyticsPage.table.heading.disconnectHigh': 'Disconnect High',
  'dashboardanalyticsPage.table.heading.avgDisconnectDuration': 'Avg Disconnect Duration',

  /******************* Users Page *********************************/
  'dashboard.usersPage.heading': 'Users',
  'dashboard.usersPage.searchUser': 'Search User',
  'dashboard.usersPage.authorizeDriver': 'Authorize Driver',
  'dashboard.usersPage.inviteUsersTo': 'Invite user to',
  'dashboard.usersPage.inviteUser': 'INVITE USER',
  'dashboard.usersPage.selectatenant': 'Select a tenant',
  'dashboard.usersPage.table.heading.id': 'ID',
  'dashboard.usersPage.table.heading.email': 'Email',
  'dashboard.usersPage.table.heading.name': 'Name',
  'dashboard.usersPage.table.heading.user': 'User',
  'dashboard.usersPage.table.heading.role': 'Role',
  'dashboard.usersPage.table.heading.role.user': 'User',
  'dashboard.usersPage.table.heading.role.admin': 'Admin',
  'dashboard.usersPage.table.heading.role.support': 'Support',
  'dashboard.usersPage.table.heading.tenant': 'Site Access',
  'dashboard.usersPage.table.heading.action': 'Action',
  'dashboard.usersPage.table.heading.access': 'Access',
  'dashboard.usersPage.table.heading.noaccess': 'No Access',
  'dashboard.usersPage.table.heading.access.modify': 'Modify',
  'dashboard.usersPage.table.heading.access.modify.saveBtn': 'Save',
  'dashboard.usersPage.table.heading.access.modify.cancelBtn': 'Cancel',
  'dashboard.usersPage.table.heading.access.info': "User's dashboard access to",
  'dashboard.usersPage.table.heading.action.toggleRole': 'Toggle Role',
  'dashboard.usersPage.table.nestedTable.associatedTenants': 'Associated Sites',
  'dashboard.usersPage.table.nestedTable.accessLevel': 'Access Level',
  'dashboard.usersPage.table.nestedTable.accessLevel.manager': 'Manager',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver': 'Driver',
  'dashboard.usersPage.table.nestedTable.accessLevel.driver.info': 'A user that has access to private listings in',
  'dashboard.usersPage.table.nestedTable.accessLevel.viewer': 'Viewer',
  'dashboard.usersPage.table.nestedTable.accessLevel.chargerOperator': 'Operator',
  'dashboard.usersPage.invitedUsersTo': 'Invited User to',
  'dashboard.usersPage.user.tenant.warning.info': 'Note: The user is not associated with all tenants selected',
  'dashboard.usersPage.user.tenant.warning.exapandedView': 'The user is not associated with this tenant',
  'dashboard.usersPage.emailError': `Please enter the email address of the user that has already created a ${currentSiteOwner} account.`,

  /******************* Users Page Invite User Modal Form *************************/
  'dashboard.usersPage.inviteUser.heading': 'Invite User to',
  'dashboard.usersPage.inviteUser.heading2': 'Invite User',
  'dashboard.usersPage.inviteUser.headingInfo': `Invite existing ${currentSiteOwner} users to access the dashboard and authorize drivers to access restricted listings in a site.`,
  'dashboard.usersPage.inviteUser.oktext': 'Invite',
  'dashboard.usersPage.inviteUser.sendInvite': 'SEND INVITATION',
  'dashboard.usersPage.inviteUser.addMore': 'ADD MORE',
  'dashboard.usersPage.inviteUser.cancelText': 'Cancel',
  'dashboard.usersPage.inviteUser.cancelText2': 'CANCEL',
  'dashboard.usersPage.inviteUser.email.heading': 'Email',
  'dashboard.usersPage.inviteUser.firstName.heading': 'First Name',
  'dashboard.usersPage.inviteUser.lastName.heading': 'Last Name',
  'dashboard.usersPage.inviteUser.role.heading': 'Role',
  'dashboard.usersPage.inviteUser.role.heading2': 'ROLE',
  'dashboard.usersPage.inviteUser.email.newWarning': 'Please enter a valid email address',
  'dashboard.usersPage.inviteUser.email.warning': 'Email is required!',
  'dashboard.usersPage.inviteUser.email.notValid.warning': 'Email is not a valid email!',
  'dashboard.usersPage.inviteUser.firstName.warning': 'First Name is required!',
  'dashboard.usersPage.inviteUser.lastName.warning': 'Last Name is required!',
  'dashboard.usersPage.inviteUser.role.warning': 'Role is required!',
  'dashboard.usersPage.inviteUser.tenant.heading': 'Tenant',
  'dashboard.usersPage.inviteUser.tenant.warning': 'A tenant must be selected to process',
  'dashboard.usersPage.inviteUser.tenants.heading': 'SITE',
  'dashboard.usersPage.inviteUser.driver.heading': 'DRIVER',
  'dashboard.usersPage.inviteUser.none': 'None',
  'dashboard.usersPage.inviteUser.driverTooltip':
    'Authorized drivers will gain access to restricted chargers in the site',
  'dashboard.usersPage.inviteUser.discountCode': 'Discount Code',
  'dashboard.usersPage.inviteUser.addTenants': 'ADD SITES',
  'dashboard.usersPage.inviteUser.discountPlan': 'Discount Plan',
  'dashboard.usersPage.inviteUser.addPermissions': 'ADD PERMISSIONS',

  /******************* Users Page Edit User Name Modal Form *************************/
  'dashboard.usersPage.editUserName.heading': "Edit User's Name",
  'dashboard.usersPage.editUserName.firstName.heading': 'First Name',
  'dashboard.usersPage.editUserName.lastName.heading': 'Last Name',
  'dashboard.usersPage.editUserName.cancelText': 'Cancel',
  'dashboard.usersPage.editUserName.updateText': 'Update',
  'dashboard.usersPage.editUserName.firstName.warning': 'First name is required',
  'dashboard.usersPage.editUserName.lastName.warning': 'Last name is required',
  'dashboard.usersPage.editUserName.firstName.maxlength.warning': 'First name cannot exceed 20 characters!',
  'dashboard.usersPage.editUserName.lastName.maxlength.warning': 'Last name cannot exceed 20 characters!',

  /******************* Users Page Edit User Modal Form *************************/
  'dashboard.usersPage.editUser.heading': 'Edit User',
  'dashboard.usersPage.editUser.sendInvite': 'SEND INVITATION',
  'dashboard.usersPage.editUser.save': 'SAVE',
  'dashboard.usersPage.editUser.cancelText': 'CANCEL',
  'dashboard.usersPage.editUser.tenants.heading': 'SITE',
  'dashboard.usersPage.editUser.driver.heading': 'DRIVER',
  'dashboard.usersPage.editUser.role.heading': 'ROLE',
  'dashboard.usersPage.editUser.none': 'None',
  'dashboard.usersPage.editUser.addMore': 'ADD MORE',
  'dashboard.usersPage.editUser.driverTooltip':
    'Authorized drivers will gain access to restricted chargers in the site',

  /******************* Users Page Add User To Multiple Tenants Form *************************/
  'dashboard.usersPage.selectTenants.heading': 'Add User To Multiple Tenants',
  'dashboard.usersPage.selectTenants.field': 'Select Tenants',
  'dashboard.usersPage.selectTenants.tbl.heading': 'Add User to Tenants',
  'dashboard.usersPage.selectTenants.field.warning': 'A tenant must be selected!',
  'dashboard.usersPage.selectTenants.addbtn': 'Add',
  'dashboard.usersPage.selectTenants.adduserbtn': 'Add User',
  'dashboard.usersPage.selectTenants.successmssg': 'Successfully added user to tenants',
  'dashboard.usersPage.selectTenants.failedmssg': 'Could not add user to tenants',

  /******************* Users Page Authorize Modal Form *************************/
  'dashboard.usersPage.authorizeModal.heading': 'Authorize drivers to access private listings',
  'dashboard.usersPage.authorizeModal.info.heading': 'Driver Authorization',
  'dashboard.usersPage.authorizeModal.info.p1': 'Authorized drivers will gain access to private listings at',
  'dashboard.usersPage.authorizeModal.userSearchField.userText': 'User',
  'dashboard.usersPage.authorizeModal.userSearchField.searchUserText': 'Search User',
  'dashboard.usersPage.authorizeModal.userSearchField.searchBtn': 'Search',
  'dashboard.usersPage.authorizeModal.authorizeBtn': 'Authorize User',
  'dashboard.usersPage.authorizeModal.cancelBtn': 'Cancel',
  'dashboard.usersPage.authorizeModal.warning': 'User already has access to the selected tenant',
  'dashboard.usersPage.authorizeModal.noMatchFound':
    'No match found. Please confirm the user has registered a SWTCH account with the indicated e-mail address.',

  /******************* Mobile Calendar *********************************/
  'dashboard.mobileCalendar.selectDate': 'Select Date',
  'dashboard.mobileCalendar.startDate': 'Start Date',
  'dashboard.mobileCalendar.endDate': 'End Date',
  'dashboard.mobileCalendar.title': 'Calendar',
  'dashboard.mobileCalendar.today': 'Today',
  'dashboard.mobileCalendar.month': 'Month',
  'dashboard.mobileCalendar.year': 'Year',
  'dashboard.mobileCalendar.am': 'AM',
  'dashboard.mobileCalendar.pm': 'PM',
  'dashboard.mobileCalendar.dateTimeFormat': 'MM/dd/yyyy w hh:mm',
  'dashboard.mobileCalendar.dateFormat': 'yyyy/MM/dd w',
  'dashboard.mobileCalendar.nochoose': 'No Choose',
  'dashboard.mobileCalendar.week.sun': 'Sun',
  'dashboard.mobileCalendar.week.mon': 'Mon',
  'dashboard.mobileCalendar.week.tue': 'Tue',
  'dashboard.mobileCalendar.week.wed': 'Wed',
  'dashboard.mobileCalendar.week.thu': 'Thu',
  'dashboard.mobileCalendar.week.fri': 'Fri',
  'dashboard.mobileCalendar.week.sat': 'Sat',
  'dashboard.mobileCalendar.clear': 'Clear',
  'dashboard.mobileCalendar.selectTime': 'Select Time',
  'dashboard.mobileCalendar.selectStartTime': 'Select Start Time',
  'dashboard.mobileCalendar.selectEndTime': 'Select End Time',
  'dashboard.mobileCalendar.start': 'Start',
  'dashboard.mobileCalendar.end': 'End',
  'dashboard.mobileCalendar.begin': 'Start',
  'dashboard.mobileCalendar.over': 'End',
  'dashboard.mobileCalendar.begin_over': 'S/E',
  'dashboard.mobileCalendar.confirm': 'Confirm',
  'dashboard.mobileCalendar.monthTitle': 'yyyy/MM',
  'dashboard.mobileCalendar.loadPrevMonth': 'Load Prev Month',
  'dashboard.mobileCalendar.yesterday': 'Yesterday',
  'dashboard.mobileCalendar.lastWeek': 'Last Week',
  'dashboard.mobileCalendar.lastMonth': 'Last Month',

  /******************* Mobile ListView *********************************/
  'dashboard.mobilelistview.prev': 'Prev',
  'dashboard.mobilelistview.next': 'Next',

  /******************* Disconnection Page *********************************/
  'dashboard.disconnectionpage.heading': 'Disconnection Summary',
  'dashboard.disconnectionpage.table.heading.avgDisconnectDuration': 'Tenant',
  'dashboard.disconnectionpage.table.heading.chargerSerialNumber': 'Charger Serial Number',
  'dashboard.disconnectionpage.table.heading.date': 'Date',
  'dashboard.disconnectionpage.table.heading.avgDailyDisconnect': 'Avg Daily Disconnect',
  'dashboard.disconnectionpage.table.heading.totalDisconnect': 'Total Disconnect',
  'dashboard.disconnectionpage.table.heading.disconnectHigh': 'Disconnect High',
  'dashboard.disconnectionPage.table.heading.avgDisconnectDuration': 'Avg Disconnect Duration',
  'dashboard.disconnectionpage.table.fromHeading': 'From',

  /*************************** Report Page ***************************/
  'dashboard.report.heading': 'Reports',
  'dashboard.text.reportOverview': 'Report Overview',
  'dashboard.reports.table.heading.entity': 'Entity',
  'dashboard.reports.table.heading.entityName': 'Name',
  'dashboard.reports.table.heading.entityType': 'Type',
  'dashboard.reports.table.heading.dateRange': 'Date',
  'dashboard.reports.table.heading.generator': 'Author',
  'dashboard.reports.table.heading.download': 'Download',
  'dashboard.reports.table.heading.email': 'Email',
  'dashboard.reports.table.row.download': 'Download File',
  'dashboard.reports.table.row.timeframe.quarterly': 'Quarterly',
  'dashboard.reports.table.row.type.transaction': 'Transactions',
  'dashboard.reports.table.row.type.erroredTransaction': 'Errored Transactions',
  'dashboard.reports.table.row.timeframe.yearly': 'Yearly',
  'dashboard.reports.form.sendReport.emails.label': 'Emails (comma seperated)',
  'dashboard.reports.form.sendReport.emails.warning': 'Emails are required!',
  'dashboard.reports.form.sendReport.emails.notValid': 'Email is not a valid email!',
  'dashboard.reports.form.sendReport.oktext': 'Email Report',
  'dashboard.reports.form.sendReport.generateText': 'Generate',
  'dashboard.reports.form.sendReport.cancelText': 'Cancel',
  'dashboard.reports.form.sendReport.reportFrom': 'report from',
  'dashboard.reports.modal.title.generateReport': 'Generate Report',
  'dashboard.reports.modal.title.generateErroredReport': 'Generate Errored  Report',
  'dashboard.reports.modal.generate.introText':
    'This will generate a PDF report from the previous quarter for all tenants between',
  'dashboard.reports.modal.generateErroredReport.introText':
    'This will generate Errored Transactions PDF report for all tenants between',
  'dashboard.reports.modal.generate.endingText': 'This process may take a few hours to complete once started.',
  'dashboard.reports.table.text.generator': 'system',
  'dashboard.reports.table.text.reportGeneratedBy': 'Report generated by',
  'dashboard.reports.table.text.generatedBy': 'Generated by',
  'dashboard.reports.message.generatingReport': 'Generating reports',
  'dashboard.reports.message.generatingErroredTransactionsReport': 'Generating errored transactions reports',
  'dashboard.reports.modal.title.deleteReport': 'Delete Report',
  'dashboard.reports.modal.message.deleteReport.text': 'You are about to delete. Are you sure?',
  'dashboard.reports.modal.message.deleting': 'Deleting report',
  'dashboard.reports.modal.title.financialSummaryReportTitle': 'Financial Summary Report',
  'dashboard.reports.modal.body.financialSummaryReportBody': `Displays all successful charging transactions, detailing the total amount paid by drivers, any fees collected by ${configProvider.config.siteTitle}, and the total remittance amount payable to the property on a quarterly basis.`,
  'dashboard.reports.modal.title.utilityChargeSessionReportTitle': 'Utility Charge Session Report',
  'dashboard.reports.modal.body.utilityChargeSessionReportBody':
    'Provides a comprehensive view of all charging transactions, including energy consumption, charger details, and session-specific data',
  'dashboard.reports.modal.title.utilityIntervalReportTitle': 'Utility Interval Report',
  'dashboard.reports.modal.body.utilityIntervalReportBody':
    'A report commonly requested by utility programs, offering a detailed breakdown of transactions in 15-minute intervals. It includes data on energy consumption and power, helping to analyze the efficiency and impact of charging sessions.',
  'dashboard.reports.modal.title.siteReport': 'Site Report',
  'dashboard.reports.modal.body.siteReport':
    'Includes details on charger’s activation date, current and previous operational status, and charger specific details.',
  'dashboard.reports.modal.title.uptimeReport': 'Uptime Report',
  'dashboard.reports.modal.body.uptimeReport': `Tracks the operational status of EV chargers, measuring the time they are active (i.e., online, available for use, or actively dispensing electricity). It excludes downtime caused by events outside of ${configProvider.config.siteTitle}'s control to provide an accurate reflection of uptime performance.
`,
  'dashboard.reports.modal.title.chargerUtilizationReport': 'Charger Utilization Report',
  'dashboard.reports.modal.body.chargerUtilizationReport':
    'Shows the total utilization of each charger over a selected time period. Utilization is calculated based on the time the charger was plugged in versus the total time of the period.',
  'dashboard.text.utilizationPercent.heading': 'Utilization Percent',
  'dashboard.text.utilizationPercent.description': 'Calculated as (Sum of Time in Use / Total Time in Period) x 100.',

  'dashboard.text.totalTimeInUseMinutes.heading': 'Total Time in Use (min)',
  'dashboard.text.totalTimeInUseMinutes.description':
    'The total duration the charger was plugged into a vehicle, regardless of whether energy was being drawn.',

  'dashboard.text.totalTimeInPeriodMinutes.heading': 'Total Time In Period (min)',
  'dashboard.text.totalTimeInPeriodMinutes.description': 'The total duration of the reporting period.',
  'dashboard.text.transactionId.description':
    'A unique identifier assigned to each individual charging session or transaction',
  'dashboard.text.site.description': 'The physical address/location of the EV charger(s)',
  'dashboard.text.country.description': 'The country where the EV chargers are located',
  'dashboard.text.state.description': 'The province or state where the EV chargers are located',
  'dashboard.text.stateProvince.heading': 'Province/State',
  'dashboard.text.transactionState.description':
    'This indicates whether a charging session transaction is free or paid. The possible states are "Free" (indicating a free charging transaction) and "Confirmed" (indicating a paid charging transaction)',
  'dashboard.text.transactionState.heading': 'Transaction State',
  'dashboard.text.startTimeLocal.heading': 'Start Time (Local)',
  'dashboard.text.startTimeLocal.description': 'The local time when a charging session began',
  'dashboard.text.endTimeLocal.heading': 'End Time (Local)',
  'dashboard.text.endTimeLocal.description': 'The local time when a charging session ended',
  'dashboard.text.chargingDurationInHours.heading': 'Charging Duration (hr)',
  'dashboard.text.chargingDurationInHours.description':
    'The total time, measured in hours, that a vehicle is actively charging. This does not include the time a vehicle is plugged in but not actively charging',
  'dashboard.text.energyInkWh.heading': 'Energy (kWh)',
  'dashboard.text.energyInkWh.description':
    'The total amount of energy consumed during a charging session, measured in kilowatt-hours (kWh)',
  'dashboard.text.ghgSaving.heading': 'GHG Savings(kg CO2 eq)',
  'dashboard.text.ghgSaving.description':
    'The amount of greenhouse gas (GHG) emissions saved, measured in kilograms of carbon dioxide equivalent (kg CO2 eq)',
  'dashboard.text.DriverFee.heading': 'Driver Fee',
  'dashboard.text.DriverFee.description': 'The amount paid by the driver for energy consumption',
  'dashboard.text.driverPaymentProcessingFee.heading': 'Driver Payment Processing Fee',
  'dashboard.text.driverPaymentProcessingFee.description':
    'Often referred to as Guest Fee this is a $0.50 fee charged to drivers who do not sign up for a SWTCH account and instead proceed as a guest',
  'dashboard.text.driverTaxPaid': 'Driver Tax Paid',
  'dashboard.text.driverTaxPaid.description':
    'The total tax amount on a driver transaction based on the geography of the charger and local tax regulations',
  'dashboard.text.driverTotalPaid.heading': 'Driver Total Paid',
  'dashboard.text.driverTotalPaid.description':
    'The total amount paid by the driver for the transaction. Sum of Driver Fee + Driver Payment Processing Fee + Driver Tax Paid',
  'dashboard.text.ownerTransactionFee.heading': `${currentSiteOwner} Transaction Fee`,
  'dashboard.text.ownerTransactionFee.description': `The ${currentSiteOwner} fee for the transaction`,
  'dashboard.text.ownerPaymentProcessingFee.heading': `${currentSiteOwner} Payment Processing Fee`,
  'dashboard.text.ownerPaymentProcessingFee.description':
    'Equal to the Guest Fee, also referred to as Driver Payment Processing Fee. Note: This is a $0.50 fee charged to drivers who do not sign up for a SWTCH account and instead proceed as a guest',
  'dashboard.text.ownerTax.heading': `${currentSiteOwner} Tax`,
  'dashboard.text.ownerTax.description': `Dependent on tax registration status of the property provided to ${currentSiteOwner} at onboarding. If a property is tax registered this amount is equal to the tax rate on the SWTCH Transaction Fee + Guest Fee only. If a property is not tax registered this amount is equal to the total tax amount for the transaction which is the same as Driver Tax Paid.`,
  'dashboard.text.propertySubtotal.heading': 'Property Sub-total',
  'dashboard.text.propertySubtotal.description':
    'The net earnings of the property for each transaction after subtracting applicable tax. This is the amount that will be paid at quarterly remittance if a site is NOT registered for tax',
  'dashboard.text.propertyTax.heading': 'Property Tax',
  'dashboard.text.propertyTax.description': `This is the portion of tax that will be paid to the property at remittance. Only applicable to properties who shared tax registration details with ${currentSiteOwner}.`,
  'dashboard.text.propertyTotal.heading': 'Property Total',
  'dashboard.text.propertyTotal.description': `The remittance amount a property will receive after applicable fees (${currentSiteOwner} Transaction Fee, ${currentSiteOwner} Payment Processing Fee) and taxes (${currentSiteOwner} tax) are deducted.
This is the amount that will be paid at quarterly remittance if a site is registered for tax and provided those details to ${currentSiteOwner}.`,
  'dashboard.text.connectorId.description':
    'The ID of the specific connector of that charger. Typically 1 or 2 depending on the number of connectors a charger has.',
  'dashboard.text.address.heading': 'Address',
  'dashboard.text.address.description': 'The street address of the EV chargers are located',
  'dashboard.text.city.description': 'The city where the EV chargers are located',
  'dashboard.text.connectorID.heading': 'Connector ID',
  'dashboard.text.connectorID.description':
    'The ID of the specific connector of that charger. Typically 1 or 2 depending on the number of connectors a charger has.',

  'dashboard.text.state.heading': 'State/Province',

  'dashboard.text.city.heading': 'City',

  'dashboard.text.country.heading': 'Country',

  'dashboard.text.sessionStartDate.heading': 'Session Start Date',
  'dashboard.text.sessionStartDate.description': 'The day when a session began.',

  'dashboard.text.sessionStartTime.heading': 'Session Start Time',
  'dashboard.text.sessionStartTime.description': 'The time when a session began (UTC).',

  'dashboard.text.sessionEndDate.heading': 'Session End Date',
  'dashboard.text.sessionEndDate.description': 'The day when a session ended.',

  'dashboard.text.sessionEndTime.heading': 'Session End Time',
  'dashboard.text.sessionEndTime.description': 'The time when a session ended (UTC).',

  'dashboard.text.chargingStartDate.heading': 'Charging Start Date',
  'dashboard.text.chargingStartDate.description': 'The day when charging began.',

  'dashboard.text.chargingStartTime.heading': 'Charging Start Time',
  'dashboard.text.chargingStartTime.description': 'The time when charging began (UTC).',

  'dashboard.text.chargingEndDate.heading': 'Charging End Date',
  'dashboard.text.chargingEndDate.description': 'The day when charging ended.',

  'dashboard.text.chargingEndTime.heading': 'Charging End Time',
  'dashboard.text.chargingEndTime.description': 'The time when charging ended (UTC).',

  'dashboard.text.timezone.heading': 'Timezone',
  'dashboard.text.timezone.description': 'Timezone in which the data is reported.',

  'dashboard.text.chargingDuration.heading': 'Charging Duration',
  'dashboard.text.chargingDuration.description': 'The total duration of active charging in hours, minutes and seconds.',

  'dashboard.text.sessionDuration.heading': 'Session Duration',
  'dashboard.text.sessionDuration.description': 'The total duration of session in hours, minutes and seconds.',

  'dashboard.text.loiteringDuration.heading': 'Loitering Duration',
  'dashboard.text.loiteringDuration.description':
    'The total duration of time spent loitering in hours, minutes and seconds. (if loitering penalties exist for the charger).',

  'dashboard.text.idlingDuration.heading': 'Idling Duration',
  'dashboard.text.idlingDuration.description':
    'The total duration of time not actively charging in a session in hours, minutes and seconds.',

  'dashboard.text.totalEnergyProvided.heading': 'Total Energy Provided',
  'dashboard.text.totalEnergyProvided.description': 'The total energy consumed during a transaction (kWh).',

  'dashboard.text.ghgSavings.heading': 'GHG Savings (kg CO2 eq)',
  'dashboard.text.ghgSavings.description':
    'The amount of greenhouse gas (GHG) emissions saved, measured in kilograms of carbon dioxide equivalent (kg CO2 eq).',

  'dashboard.text.averagePower.heading': 'Average Power (kW)',
  'dashboard.text.averagePower.description': 'The average output of the charger during the transaction.',

  'dashboard.text.peakPower.heading': 'Peak Power',
  'dashboard.text.peakPower.description': 'The highest recorded output of the charger during the transaction (kW).',

  'dashboard.text.chargerLifetimeEnergyDelivered.heading': 'Charger Lifetime Energy Delivered (kWh)',
  'dashboard.text.chargerLifetimeEnergyDelivered.description':
    'The total energy delivered reported by the charger in its lifetime.',

  'dashboard.text.chargerMaxOutput.heading': 'Charger Max Output (kW)',
  'dashboard.text.chargerMaxOutput.description': 'The total rated output of the charger.',

  'dashboard.text.evseVendor.heading': 'EVSE Vendor',
  'dashboard.text.evseVendor.description': 'Vendor of the charger.',

  'dashboard.text.evseModel.heading': 'EVSE Model',
  'dashboard.text.evseModel.description': 'Model number of the charger.',

  'dashboard.text.evseSerialNumber.heading': 'EVSE Serial Number',
  'dashboard.text.evseSerialNumber.description': 'Serial number of the charger.',

  'dashboard.text.connectorType.heading': 'Connector Type',
  'dashboard.text.connectorType.description': 'Type of connector of the charger.',

  'dashboard.text.chargerType.heading': 'Charger Type',
  'dashboard.text.chargerType.description': 'Type of charger, either Level 2 or DC Fast.',
  'dashboard.text.serviceTypeCode.heading': 'Service Type Code',
  'dashboard.text.serviceTypeCode.description':
    'Electricity system type: either behind-the-meter or front-of-the-meter.',

  'dashboard.text.priceType.heading': 'Price Type',
  'dashboard.text.priceType.description': 'The unit of calculating cost of charging. This is either by kWh or by hour.',

  'dashboard.text.transactionStatus.heading': 'Transaction Status',
  'dashboard.text.transactionStatus.description':
    'The status of the transaction defined as either Successful, Errored or Partially Refunded. Where Errored is defined as a transaction that did not end successfully, thus payment was not collected.',

  'dashboard.text.vehicleMake.heading': 'Vehicle Make',
  'dashboard.text.vehicleMake.description': 'Make of vehicle using the charger as defined by the user.',

  'dashboard.text.vehicleModel.heading': 'Vehicle Model',
  'dashboard.text.vehicleModel.description': 'Model of vehicle using the charger as defined by the user.',
  'dashboard.text.ratedOutput.heading': 'Rated Output (kW)',
  'dashboard.text.ratedOutput.description': 'Total rated output of the charger.',

  'dashboard.text.locationSetting.heading': 'Location Setting',
  'dashboard.text.locationSetting.description':
    'Defines the type of site where the charger is installed (e.g., multi-unit residential building, public, on-street, or workplace).',

  'dashboard.text.evseStatus.heading': 'EVSE Status',
  'dashboard.text.evseStatus.description':
    'Indicates the current status of the charger, and whether it is active or under repair.',

  'dashboard.text.effectiveStatusDate.heading': 'Effective Status Date',
  'dashboard.text.effectiveStatusDate.description': 'The date the current status of the charger went into effect.',

  'dashboard.text.effectiveStatusTime.heading': 'Effective Status Time',
  'dashboard.text.effectiveStatusTime.description':
    'The time the current status of the charger went into effect (UTC).',

  'dashboard.text.previousStatus.heading': 'Previous Status',
  'dashboard.text.previousStatus.description': 'The previous operational status update of the charger.',

  'dashboard.text.previousStatusDate.heading': 'Previous Status Date',
  'dashboard.text.previousStatusDate.description': 'The date the previous status of the charger went into effect.',

  'dashboard.text.previousStatusTime.heading': 'Previous Status Time',
  'dashboard.text.previousStatusTime.description':
    'The time the previous status of the charger went into effect (UTC).',

  'dashboard.text.activationDate.heading': 'Activation Date',
  'dashboard.text.activationDate.description': 'The date when the charger first became active on the network.',
  'dashboard.text.siteId.heading': 'Site ID',
  'dashboard.text.siteId.description': 'Unique identifier of the site',
  'dashboard.text.intervalId.heading': 'Interval ID',
  'dashboard.text.intervalId.description':
    'A unique identifier assigned to each 15 minute interval within a charging transaction',
  'dashboard.text.portNumber.heading': 'Port Number',
  'dashboard.text.portNumber.description': 'The ID of the specific connector of that charger. Typically 1 or 2.',

  'dashboard.text.intervalStartDate.heading': 'Interval Start Date (UTC)',
  'dashboard.text.intervalStartDate.description': 'The day when the 15-minute interval began.',

  'dashboard.text.intervalStartTime.heading': 'Interval Start Time (UTC)',
  'dashboard.text.intervalStartTime.description': 'The time when the 15-minute interval began.',

  'dashboard.text.intervalEndDate.heading': 'Interval End Date (UTC)',
  'dashboard.text.intervalEndDate.description': 'The day when the 15-minute interval ended.',

  'dashboard.text.intervalEndTime.heading': 'Interval End Time (UTC)',
  'dashboard.text.intervalEndTime.description': 'The time when the 15-minute interval ended.',

  'dashboard.text.energyDelivered.heading': 'Energy Delivered (kWh)',
  'dashboard.text.energyDelivered.description':
    'The total amount of energy delivered to the vehicle during the interval.',

  'dashboard.text.peakDemand.heading': 'Peak Demand (kW)',
  'dashboard.text.peakDemand.description': 'The highest recorded output of the charger during the interval.',

  'dashboard.text.averageDemand.heading': 'Average Demand (kW)',
  'dashboard.text.averageDemand.description': 'The average output of the charger during the transaction.',

  'dashboard.text.uptimePercentage.heading': 'Uptime Percentage',
  'dashboard.text.uptimePercentage.description':
    'The percentage of time a charger is in active status, calculated as [Total Time in Period − (Total Outage Time − Total Excluded Time) ] / Total Time in Period × 100',

  'dashboard.text.numberOfOutages.heading': 'Number of Outages',
  'dashboard.text.numberOfOutages.description': 'The total number of instances where a charger experienced downtime.',

  'dashboard.text.totalOutageTime.heading': 'Total Outage Time',
  'dashboard.text.totalOutageTime.description':
    'The cumulative amount of time the charger experienced downtime (minutes).',

  'dashboard.text.totalExcludedTime.heading': 'Total Excluded Time',
  'dashboard.text.totalExcludedTime.description':
    'Downtime due to factors beyond control (e.g., a city-wide outages or natural disasters) which are excluded from uptime calculations (minutes).',

  'dashboard.text.listingTitle.description': `Often referred to as Charger ID, this is the unique ID that can be found on the front of a ${configProvider.config.siteTitle} charger.`,

  /************************* Load Management Tab *************************/
  'dashboard.loadManagementTab.addMixedCircuit': 'Add Mixed Circuit',
  'dashboard.loadManagementTab.addDedicatedCircuit': 'Add Dedicated Circuit',
  'dashboard.loadManagementTab.button.sendUpdates': 'Send Updates',
  'dashboard.loadManagementTab.table.locale.unconfig': 'No more unconfigured chargers',
  'dashboard.loadManagementTab.table.locale.panel.title': 'No electrical panel is connected',
  'dashboard.loadManagementTab.table.locale.panel.subtitle': 'Please use the button on the top left to create one',
  'dashboard.loadManagementTab.table.locale.branch':
    'There is no branch connected. Please click on Add Branch button on the top right of this  circuit to create one',
  'dashboard.loadManagementTab.table.title.unconfig': 'Unconfigured Chargers',
  'dashboard.loadManagementTab.circuitName': 'Circuit Name',
  'dashboard.loadManagementTab.branchName': 'Branch Name',
  'dashboard.loadManagementTab.editCircuit': 'Edit Circuit',
  'dashboard.loadManagementTab.addCircuit': 'Add Circuit',
  'dashboard.loadManagementTab.editBranch': 'Edit Branch',
  'dashboard.loadManagementTab.addBranch': 'Add Branch',
  'dashboard.loadManagementTab.editCharger': 'Edit Charger',
  'dashboard.loadManagementTab.updateCharger': 'Update Charger',
  'dashboard.loadManagementTab.type': 'Circuit Type',
  'dashboard.loadManagementTab.loadManagement': 'Load Management',
  'dashboard.loadManagementTab.circuitBranch': 'Circuit/Branch',
  'dashboard.loadManagementTab.ipAddress': 'IP Address',
  'dashboard.loadManagementTab.ctClampConfig': 'CT Clamp Configuration for Mixed Circuit',
  'dashboard.loadManagementTab.unconfiguredChargers': 'Unconfigured Chargers',
  'dashboard.loadManagementTab.rule.existingLoad': 'Please enter a valid existing load (A)',
  'dashboard.loadManagementTab.rule.ipAddress': 'Please enter a valid IP Address',
  'dashboard.loadManagementTab.rule.ipAddress.warning':
    'A valid IP address has four decimal numbers separated by periods',
  'dashboard.loadManagementTab.rule.port': 'Please enter a valid port',
  'dashboard.loadManagementTab.rule.circuitSize': 'Please enter a valid circuit size (A)',
  'dashboard.loadManagementTab.circuitTypeField': 'Dedicated or Mixed Type',
  'dashboard.loadManagementTab.circuitName.message': 'Please enter a name for the circuit',
  'dashboard.loadManagementTab.label.circuitSize': 'Circuit Size (A)',
  'dashboard.loadManagementTab.label.existingLoad': 'Existing Load (A)',
  'dashboard.loadManagementTab.label.maxAmp': 'Max. Cap (A)',
  'dashboard.loadManagementTab.label.minAmp': 'Min. Cap (A)',
  'dashboard.loadManagementTab.label.fallbackAmp': 'Fallback (A)',
  'dashboard.loadManagementTab.label.breakerSize': 'Breaker Size (A)',
  'dashboard.loadManagementTab.label.circuitId': 'Circuit ID',
  'dashboard.loadManagementTab.cloudLoadMgmt.message':
    "This listing’s cloud load management has been disabled. To enable, click on the listing, click on 'Edit Listing' and click on 'Allow Cloud Load Management'",
  'dashboard.loadManagementTab.rule.endPoint': 'Endpoint cannot be empty!',
  'dashboard.loadManagementTab.rule.http': 'Must be either an HTTPS or HTTP protocol',
  'dashboard.loadManagementTab.text.instanceIP': 'Instance IP',
  'dashboard.loadManagementTab.text.sendUpdates': 'Send Updates',
  'dashboard.loadManagementTab.result.title.tenantAllowLoadMgmt':
    'Currently, the load management is disabled for this tenant',
  'dashboard.loadManagementTab.result.subTitle.tenantAllowLoadMgmt':
    'To allow load management, click the Enable button below',
  'dashboard.loadManagementTab.disableLoadMgmt': 'Disable Load Management',
  'dashboard.loadManagementTab.result.title.error': 'Enabling Load Management Failed',
  'dashboard.loadManagementTab.result.subtitle.error':
    'Please check if the tenant is capable of load management and contact the administrator for further help',
  'dashboard.loadManagementTab.missingListing.message':
    'This charger has no listing. To attach a listing, click on the Add Listing button',
  'dashboard.loadManagementTab.button.addListing': 'Add Listing',
  'dashboard.loadManagementTab.maxCap.message': 'Cannot be greater than the max cap',
  'dashboard.loadManagementTab.minCap.message': 'Cannot be less than the min cap',
  'dashboard.loadManagementTab.rule.branchName': 'Branch name must be different',
  'dashboard.loadManagementTab.addBranch.toolTip.message':
    'For every N unconfigured chargers, there can only be N branches',

  /******************* Plan and Subscription Page *********************/
  'dashboard.subscriptionsPage.button.title': 'Create Plan',
  'dashboard.subscriptionsPage.placeholder.searchPlan': 'Search Plan',
  'dashboard.subscriptionsPage.createPlanForm.selectPlan.warning': 'A plan must be selected to process',
  'dashboard.subscriptionsPage.createPlanForm.searchUser.warning':
    'No match found. Please confirm the user has registered a SWTCH account with the indicated e-mail address.',
  'dashboard.subscriptionsPage.inviteForm.help.start': 'The start of subscription',
  'dashboard.subscriptionsPage.inviteForm.help.end': 'The end of subscription',
  'dashboard.subscriptionsPage.invitePlanForm.user.warning': 'A user must be selected to process',
  'dashboard.plan.modal.delete': 'Are you sure you want to delete the plan ',
  'dashboard.plan.modal.archive': 'Are you sure you want to archive the plan ',
  'dashboard.plan.modal.unarchive': 'Are you sure you want to unarchive the plan ',
  'dashboard.subscriptionsPage.modal.delete': 'Are you sure you want to cancel the subscription for ',
  'dashboard.subscriptionsPage.modal.pause': 'Are you sure you want to pause the subscription for ',
  'dashboard.subscriptionsPage.modal.unpause': 'Are you sure you want to unpause the subscription for ',
  'dashboard.subscriptionsPage.editSubscription': 'Edit Subscription',
  'dashboard.subscriptionsPage.deleteSubscription': 'Delete Subscription',
  'dashboard.plan.subscriptionPlan': 'Subscription Plans',
  'dashboard.plan.subscribeUser': 'Subscribe User',
  'dashboard.plan.editPlan': 'Edit Plan',
  'dashboard.plan.togglePlan': 'Toggle Plan',
  'dashboard.text.subscribeNewUser': 'Subscribe New User',
  'dashboard.text.cancelSubscription': 'Cancel Subscription',
  'dashboard.text.pauseSubscription': 'Pause Subscription',
  'dashboard.text.unpauseSubscription': 'Unpause Subscription',

  /*************************** Create Listing Page ***************************/
  'dashboard.listing.ListingsText': 'Listings',
  'dashboard.listing.createListingRequiredText': 'Listing Title is Required',
  'dashboard.listing.listingAlreadyExistsText': 'Listing title already exists',
  'dashboard.listing.CreateNewListing': 'Create Listing',
  'dashboard.listing.ListingsTable.titleText': 'Title',
  'dashboard.listing.ListingsTable.authorText': 'Author',
  'dashboard.listing.ListingsTable.categoryText': 'Category',
  'dashboard.listing.ListingsTable.chargerText': 'Charger',
  'dashboard.listing.ListingsTable.connectorIdText': 'Connector ID',
  'dashboard.listing.ListingsTable.priceText': 'Price',
  'dashboard.listing.ListingsTable.priceTypeText': 'Price Type',
  'dashboard.listing.ListingsTable.activeText': 'Active',
  'dashboard.listing.ListingsTable.activeText.open': 'Open',
  'dashboard.listing.ListingsTable.activeText.closed': 'Closed',
  'dashboard.createNewListing.txt': 'Create a Listing',
  'dashboard.createNewListing.ListingTab': 'Listing',
  'dashboard.createNewListing.chargerTab': 'Charger',
  'dashboard.createNewListing.loiteringTab': 'Loitering',
  'dashboard.createNewListing.priceTab': 'Price',
  'dashboard.createNewListing.commissionTab': 'Commission & Fee',
  'dashboard.createNewListing.reviewTab': 'Review',
  'dashboard.createNewListing.nextBtn': 'Next',
  'dashboard.createNewListing.previousBtn': 'Previous',
  'dashboard.createNewListing.submitBtn': 'Submit',
  'dashboard.createNewListing.listingStep.heading': 'Listing Title',
  'dashboard.createNewListing.listingStep.detailedDescription.heading': 'Listing Info',
  'dashboard.createNewListing.listingStep.detailedDescription.warning': '255 characters is the limit',
  'dashboard.createNewListing.listingStep.detailedDescription.info':
    'If your description contains YouTube links, the videos will be shown below the description',
  'dashboard.createNewListing.listingStep.listingAuthor.heading': 'Listing Author',
  'dashboard.createNewListing.listingStep.listingAuthor.selectTxt': 'Select Author',
  'dashboard.createNewListing.listingStep.listingAuthor.warning': 'Listing Author is required',
  'dashboard.createNewListing.listingStep.stripeAccount.heading': 'Stripe Account is required',
  'dashboard.createNewListing.listingStep.stripeAccount.selectTxt': 'Select Stripe Account',
  'dashboard.createNewListing.listingStep.listingCategory.Txt': 'Listing Category',
  'dashboard.createNewListing.listingStep.listingCategory.Reservation': 'Reservation',
  'dashboard.createNewListing.listingStep.listingCategory.Non Reservation': 'Non Reservation',
  'dashboard.createNewListing.listingStep.listingCategory.non_reservation': 'Non Reservation',
  'dashboard.createNewListing.listingStep.listingCategory.no_reservation': 'Non Reservation',
  'dashboard.createNewListing.listingStep.listingCategory.Destination': 'Destination',
  'dashboard.createNewListing.listingStep.listingCategory.Non Network': 'Non Network',
  'dashboard.createNewListing.listingStep.listingCategory.Free': 'Free',
  'dashboard.createNewListing.listingStep.currencyCategory.txt': 'Currency',
  'dashboard.createNewListing.listingStep.walletCategory.txt': 'Wallet Functionality',
  'dashboard.createNewListing.listingStep.walletCategory.walletOption': 'Wallet',
  'dashboard.createNewListing.listingStep.walletCategory.nowalletOption': 'No Wallet',
  'dashboard.createNewListing.listingStep.listingCategory.txt': 'Access Control',
  'dashboard.createNewListing.listingStep.listingCategory.privateOption': 'Restricted',
  'dashboard.createNewListing.listingStep.listingCategory.publicOption': 'Open',
  'dashboard.createNewListing.listingStep.hiddenCategory.txt': 'Listings hidden from map',
  'dashboard.createNewListing.listingStep.carDetailsCategory.txt': 'Users need car details to book',
  'dashboard.createNewListing.chargerStep.tenantCategory.txt': 'Associated Site',
  'dashboard.createNewListing.chargerStep.tenantCategory.warning': 'Site is required',
  'dashboard.createNewListing.chargerStep.chargerOutput.txt': 'Charger Output (kW)',
  'dashboard.createNewListing.chargerStep.chargerOutput.timeOfDayCharging.txt': 'Select if time of day charging',
  'dashboard.createNewListing.chargerStep.customNotification.txt': 'Custom notification',
  'dashboard.createNewListing.chargerStep.customNotification.info':
    'Provide instructions on how to operate the charger if necessary',
  'dashboard.createNewListing.priceStep.allowClouldLoadManagement.txt': 'Allow Cloud Load Management',
  'dashboard.createNewListing.priceStep.localLoadManagementAutoStop.txt':
    'Local Load Management: Transaction Auto-stop',
  'dashboard.createNewListing.priceStep.preferredPricing.txt': 'Preferred pricing for site members',
  'dashboard.createNewListing.comissionStep.txt': 'Commission (%)',
  'dashboard.createNewListing.comissionStep.guestFee.txt': 'Guest Fee ($)',
  'dashboard.createNewListing.comissionStep.paymentLimit.txt': 'Payment Limit ($)',
  'dashboard.createNewListing.reviewStep.listing.title': 'Title',
  'dashboard.createNewListing.reviewStep.listing.category': 'Category',
  'dashboard.createNewListing.reviewStep.listing.paymentType': 'Payment Type',
  'dashboard.createNewListing.reviewStep.listing.accessType': 'Access Type',
  'dashboard.createNewListing.reviewStep.listing.hiddenFromMap': 'Hidden From Map',
  'dashboard.createNewListing.reviewStep.listing.carDetailRequired': 'Car Detail Required',
  'dashboard.createNewListing.reviewStep.listing.author': 'Author',
  'dashboard.createNewListing.reviewStep.listing.stripeAccount': 'Stripe Account',
  'dashboard.createNewListing.reviewStep.listing.currency': 'Currency',
  'dashboard.createNewListing.reviewStep.listing.description': 'Location Description',
  'dashboard.createNewListing.reviewStep.charger.chargerOutput': 'Charger Output',
  'dashboard.createNewListing.reviewStep.charger.enableTimeOfDayCharging': 'Enable Time of Day Charging',
  'dashboard.createNewListing.reviewStep.charger.customNotification': 'Custom notification',
  'dashboard.createNewListing.reviewStep.price.preferedPricingtxt': 'Prefered Price Per Unit Cents ($)',
  'dashboard.createNewListing.reviewStep.price.hourlyPricingtxt': 'Hourly pricing based on kWh ($)',
  'dashboard.createNewListing.reviewStep.price.todPriceSettingstxt': 'Time of Day Price Settings',
  'dashboard.createNewListing.listingCreatedSuccessfullyText': 'Listing -1 Created Successfully',
  'dashboard.updated.listing': 'Listing has been updated successfully',
  'dashboard.createNewListing.refreshAuthorsText': 'Refresh Authors and Stripe Accounts',
  'dashboard.listingAccess.carSettingsText': 'Car Settings',
  'dashboard.listingAccess.noCarDetailsRequiredText': 'No Car Details Required',
  'dashboard.listingAccess.carDetailsRequiredText': 'Car Details Required',
  'dashboard.listingAccess.mapSettingsText': 'Map Settings',
  'dashboard.listingAccess.hideListingText': 'Hide Listing on Map',
  'dashboard.listingAccess.displayListingText': 'Display Listing on Map',

  /*************************** Peak Shaving Programs Page ***************************/
  'dashboard.peakShaving': 'Demand Management',
  'dashboard.peakShaving.programsPage.searchPrograms': 'Search Program',
  'dashboard.peakShaving.programsPage.programStatus': 'Program Status',
  'dashboard.peakShaving.programsPage.newProgram': 'New Program',
  'dashboard.peakShaving.programsPage.status.published': 'Published',
  'dashboard.peakShaving.programsPage.status.draft': 'Draft',
  'dashboard.peakShaving.programsPage.status.archived': 'Archived',
  'dashboard.peakShaving.programsPage.status.finished': 'Finished',
  'dashboard.peakShaving.programsPage.programCreatedSuccessMessage': 'Program -1 has been created successfully',
  'dashboard.peakShaving.programsPage.programUpdatedSuccessMessage': 'Program details updated successfully',
  'dashboard.peakShaving.programPage.program': 'Program',
  'dashboard.peakShaving.programPage.programName': 'Program Name',
  'dashboard.peakShaving.programPage.program.cannotExceed': '-1 cannot exceed 255 characters!',
  'dashboard.peakShaving.programPage.aggregatorName': 'Aggregator Name',
  'dashboard.peakShaving.programPage.status': 'Status',
  'dashboard.peakShaving.programPage.participant': 'Participant',
  'dashboard.peakShaving.programPage.participants': 'Participants',
  'dashboard.peakShaving.programPage.participantID': 'Participant ID',
  'dashboard.peakShaving.programPage.allListings': 'All Listings',
  'dashboard.peakShaving.programPage.selectListings': 'Select Listings',
  'dashboard.peakShaving.programPage.selectedListings': 'Selected Listings',
  'dashboard.peakShaving.programPage.mixed': 'Mixed',
  'dashboard.peakShaving.programPage.addParticipants': 'Add Participants',
  'dashboard.peakShaving.programPage.addParticipants.success': 'Participant added successfully',
  'dashboard.peakShaving.programPage.updateParticipants.success': 'Participant updated successfully',
  'dashboard.peakShaving.programPage.editParticipants': 'Edit Participants',
  'dashboard.peakShaving.programPage.program.type': 'Type',
  'dashboard.peakShaving.programPage.program.date': 'Date',
  'dashboard.peakShaving.programPage.program.participationWindow': 'Participation Window',
  'dashboard.peakShaving.programPage.program.eventNotification': 'Event Notification',
  'dashboard.peakShaving.programPage.program.voluntary': 'Voluntary',
  'dashboard.peakShaving.programPage.program.nonVoluntary': 'Non-Voluntary',
  'dashboard.peakShaving.programPage.program.participationDetails': 'Participation Window Details',
  'dashboard.peakShaving.programPage.program.participationDetails.placeholder':
    'Participation Window Details (4-hour Window)',
  'dashboard.peakShaving.programPage.program.eventNotificationDetails': 'Event Notification Window Details',
  'dashboard.peakShaving.programPage.program.eventNotificationDetails.placeholder':
    'Event Notification Window Details (ie. 2 Hour Notice)',
  'dashboard.peakShaving.programPage.program.newEventBtn': 'New Event',
  'dashboard.peakShaving.programPage.program.archiveBtn': 'Archive Program',
  'dashboard.peakShaving.programPage.program.archiveWarning':
    'All Published events will be Cancelled and you can no longer add listings or events to the program.',
  'dashboard.peakShaving.programPage.program.programsDetailTab': 'Program Details',
  'dashboard.peakShaving.programPage.program.eventsTab': 'Events',
  'dashboard.peakShaving.programPage.program.publishProgram': 'Publish Program',
  'dashboard.peakShaving.programPage.program.publishInfoText': 'Publish program to add events',
  'dashboard.peakShaving.programPage.program.schedule': 'Schedule',
  'dashboard.peakShaving.programPage.program.schedule.updatedBy': 'Updated by',
  'dashboard.peakShaving.programPage.program.schedule.allowOptOut': 'Allow Opt Out',
  'dashboard.peakShaving.programPage.program.schedule.editSchedule': 'Edit Schedule',
  'dashboard.peakShaving.programPage.program.schedule.removeSchedule': 'Remove Schedule',
  'dashboard.peakShaving.programPage.program.eventsTable.info': 'Once an event is generated, it will appear here',
  'dashboard.peakShaving.programPage.program.eventsTable.eventDate': 'Event Date',
  'dashboard.peakShaving.programPage.program.eventsTable.eventStatus': 'Event Status',
  'dashboard.peakShaving.programPage.program.eventsTable.duplicateEvent': 'Duplicate Event',
  'dashboard.peakShaving.programPage.program.eventsTable.cancelEvent': 'Cancel Event',
  'dashboard.peakShaving.programPage.modal.publishText': 'Publish',
  'dashboard.peakShaving.programPage.modal.zoneText': 'Zone',
  'dashboard.peakShaving.programPage.modal.startTimeText': 'Start Time',
  'dashboard.peakShaving.programPage.modal.endTimeText': 'End Time',
  'dashboard.peakShaving.programPage.modal.frequencyText': 'Frequency',
  'dashboard.peakShaving.programPage.modal.eventScheduleText': 'Event Schedule',
  'dashboard.peakShaving.programPage.modal.createEventText': 'Create Event',
  'dashboard.peakShaving.programPage.modal.zone.none': 'None',
  'dashboard.peakShaving.programPage.modal.zone.primary': 'Primary Peak Zone',
  'dashboard.peakShaving.programPage.modal.zone.secondary': 'Secondary Peak Zone',
  'dashboard.peakShaving.programPage.modal.createScheduleText': 'Create Schedule',
  'dashboard.peakShaving.programPage.modal.zoneWarning': 'Please select a zone!',
  'dashboard.peakShaving.programPage.modal.startTimeWarning': 'Start Time cannot overlap!',
  'dashboard.peakShaving.programPage.modal.createNewProgramText': 'Create New Program',
  'dashboard.peakShaving.programPage.modal.updateProgramText': 'Update Program',
  'dashboard.peakShaving.programPage.modal.nameRequiredWarningText': '-1 is required!',
  'dashboard.peakShaving.programPage.modal.removeSchedule.heading': 'Remove Schedule',
  'dashboard.peakShaving.programPage.modal.removeSchedule.warning': 'Are you sure you want to remove the schedule?',
  'dashboard.peakShaving.programPage.modal.cancelEvent.heading': 'Cancel Event',
  'dashboard.peakShaving.programPage.modal.cancelEvent.warning': 'Are you sure you want to cancel the event?',
  'dashboard.peakShaving.programPage.modal.createEvent.success': 'Event has been created successfully!',
  'dashboard.peakShaving.programPage.modal.updateEvent.success': 'Event and schedule successfully updated!',
  'dashboard.peakShaving.programPage.modal.dailyEvent.update.success': 'Daily Event and schedule successfully updated!',
  'dashboard.peakShaving.programPage.modal.dailyEvent.create.success': 'Daily Schedule has been created successfully!',
  'dashboard.peakShaving.programPage.listing.helper': 'Only listings from this tenant will be added',
  'dashboard.peakShaving.programPage.listing.outputText': 'Output',
  'dashboard.peakShaving.programPage.listingsTable.info': 'Add Listings to publish program',
  'dashboard.peakShaving.programPage.participantTable.info': 'Add Participant to publish program',
  'dashboard.peakShaving.programPage.modal.listings.addListings': 'Add Listings',
  'dashboard.peakShaving.programPage.modal.listings.addListings.warning':
    'Please enter a listing in the same time zone -1 as "-2"',
  'dashboard.peakShaving.programPage.modal.listings.addListings.success':
    '-1 Listings have been added to the program successfully!',
  'dashboard.peakShaving.programPage.dailySchedule.info':
    'If a schedule is created, the system will publish and generate events based on the schedule defined.',
  'dashboard.peakShaving.programPage.removeListing': 'Remove Listing',
  'dashboard.peakShaving.programPage.removeListing.info':
    'Are you sure you want to remove the listing from the program?',
  'dashboard.peakShaving.programPage.removeParticipant': 'Remove Participant',
  'dashboard.peakShaving.programPage.removeParticipant.info':
    'Are you sure you want to remove the participant from the program?',
  'dashboard.peakShaving.programPage.removeTenant': 'Remove Tenant',
  'dashboard.peakShaving.programPage.removeTenant.info': 'Are you sure you want to remove the tenant from the program?',
  'dashboard.peakShaving.eventModal.charging': '-1 Charging',
  'dashboard.peakShaving.eventModal.fullSpeed': '-1 Full Speed',
  'dashboard.peakShaving.eventModal.warning.timeRange': 'Time range overlaps with an existing entry.',
  'dashboard.peakShaving.eventModal.editEvent': 'Edit Event',
  'dashboard.peakShaving.eventModal.oneTime': 'One Time',
  'dashboard.peakShaving.eventModal.selectDate': 'Select a Date',
  'dashboard.peakShaving.eventModal.curtailment': 'Curtailment',
  'dashboard.peakShaving.eventModal.to': 'To',
  'dashboard.peakShaving.programTab.title': 'Programs',
  'dashboard.peakShaving.programTab.programSelector': 'Program (s)',
  'dashboard.peakShaving.participantsTab.delete': 'Delete Participant',
  'dashboard.peakShaving.participantsTab.delete.warning':
    'All shaving events associated to this participant will be cancelled',
  'dashboard.peakShaving.participantsTab.edit': 'Edit Participant',
  'dashboard.peakShaving.participantsTab.new': 'New Participant',
  'dashboard.peakShaving.eventsTab.deleteEvent': 'Delete Event',
  'dashboard.peakShaving.eventsTab.deleteEvent.warning': 'Are you sure you want to delete this event?',
  'dashboard.peakShaving.eventsTab.cancelEvent': 'Are you sure you want to cancel this event?',
  'dashboard.peakShaving.eventsTab.editProgram': 'Edit Program',

  /*********************** New Feature Release Note ***********************/
  'dashboard.text.releaseNote.heading': 'Exciting Update: New Reports Page Added!',
  'dashboard.text.releaseNote.newFeature':
    "We've updated the Transactions Page for a modern, user-friendly experience, simplifying charging activity monitoring. We’ve also introduced a dedicated Reports Page, allowing you to easily access detailed usage and revenue reports, streamlining your EV charging business management.",
  'dashboard.text.releaseNote.button': 'Explore new features',
  /*************************** Support Dashboard Page ***************************/
  'dashboard.supportDashboard': 'Support Dashboard',
  /*************************** Tagging Page ***************************/
  'dashboard.tagging': 'Tagging',
  'dashboard.tags': 'Tag (s)',
  'dashboard.tagging.createTag': 'Create Tag',
  'dashboard.tagging.addNewTag': 'Add New Tag',
  'dashboard.tagging.table.id': 'ID',
  'dashboard.tagging.table.name': 'Name',
  'dashboard.tagging.table.object': 'Object',
  'dashboard.tagging.table.status': 'Status',
  'dashboard.tagging.table.type': 'Type',
  'dashboard.tagging.table.createdAt': 'Created At',
  'dashboard.tagging.table.createdBy': 'Created By',
  'dashboard.tagging.table.note': 'Note',
  'dashboard.tagging.table.edit': 'Edit',
  'dashboard.tagging.table.inactivate': 'Inactivate',

  'dashboard.tagging.modal.createTag': 'Create Tag',
  'dashboard.tagging.modal.editTag': 'Edit Tag',
  'dashboard.tagging.modal.active': 'Active',
  'dashboard.tagging.modal.inactive': 'Inactive',
  'dashboard.tagging.modal.system': 'System',
  'dashboard.tagging.modal.admin': 'Admin',
  'dashboard.tagging.modal.customer': 'Customer',

  'dashboard.tagging.modal.createSuccess': 'Tag -1 has been created successfully',
  'dashboard.tagging.modal.editSuccess': 'Tag has been updated successfully',

  /*************************** Discount Schema ***************************/
  'dashboard.discountSchema.archiveDescription': 'Archiving Discount Plan?',
  'dashboard.discountSchema.actionDescription': 'This action cannot be undone.',
  'dashboard.discountSchema.promoCodeDescription': 'Promo code will no longer work',
  'dashboard.discountSchema.usersRemovedDescription': 'All users will be removed in this discount plan',
  'dashboard.discountSchema.promoCode': 'Promo Code',
  'dashboard.discountSchema.skipDiscountPlan': 'Skip Discount Plan',
  'dashboard.discountSchema.flatFeeInfo': 'Flat fee amount is determined by base plan. Choose this amount or $0.00',
  'dashboard.discountSchema.promoCodeInfo': 'Each code should be unique, only with letters & numbers.',
  'dashboard.discountSchema.discountPlanName': 'Discount Plan Name',
  'dashboard.discountSchema.planName': 'Plan Name',
  'dashboard.discountSchema.basePricingPlan': 'Base Pricing Plan',
  'dashboard.discountSchema.noLoiteringInfoText':
    "Loitering adjustment is unavailable because there's no loitering in base plan.",
  'dashboard.discountSchema.noFlatFeeText': 'No Flat Fee',
  'dashboard.discountSchema.noLoiteringText': 'No Loitering',
  'dashboard.discountSchema.discountPlanInfoText': 'Discount plan cannot be higher than base plan.',
  'dashboard.discountSchema.gracePeriodText': 'Grace Period',
  'dashboard.discountSchema.timeLimittext': 'Time Limit',
  'dashboard.discountSchema.selectPricingPlanText':
    'Select a pricing plan where the new discount plan will be located.',
  'dashboard.discountSchema.discountPlanWorksWithPricingPlanText': 'A discount plan works inside a pricing plan.',
  'dashboard.discountSchema.pricingPlan': 'Pricing Plan:',
  'dashboard.discountSchema.discountPlan': 'Discount Plan:',
  'dashboard.discountSchema.discountPlanTxt': 'Discount Plan',
  'dashboard.discountSchema.listings': 'Listings:',
  'dashboard.discountSchema.timeAdded': 'Time Added',
  'dashboard.discountSchema.action': 'Action',
  'dashboard.discountSchema.updatedBy': 'Updated By',
  'dashboard.discountSchema.manageUsers': 'Manage Users',
  'dashboard.discountSchema.allUsersSelected': 'All -1 users on this page are selected.',
  'dashboard.discountSchema.usersSelected': 'Users Selected',
  'dashboard.discountSchema.selectAll': 'Select all -1 users in this discount plan',
  'dashboard.discountSchema.removingUsers': 'Removing Users?',
  'dashboard.discountSchema.remove': 'Remove',
  'dashboard.discountSchema.currentUsers': '-1 Users',
  'dashboard.discountSchema.userAuthorizationHistory': 'User Authorization history',
  'dashboard.discountSchema.viewHistory': 'View history',
  'dashboard.discountSchema.usersRemoved': 'Users removed',
  'dashboard.discountSchema.usersAdded': 'Users added',
  'dashboard.discountSchema.flatFee': 'Flat Fee',
  'dashboard.discountSchema.discountPlanModalTitle': 'Discount Plan Modal',

  /*************************** Incidences ***************************/
  'dashboard.incidences.zendeskId': 'Zendesk ID',
  'dashboard.incidences.listingTitle': 'Listing Title',
  'dashboard.incidences.rootCause': 'Root Cause',
  'dashboard.incidences.rootCauses': 'Root Causes',
  'dashboard.incidences.downtime': 'Downtime',
  'dashboard.incidences.resolution': 'Resolution',
  'dashboard.incidences.resolutions': 'Resolutions',
  'dashboard.incidences.startTIme': 'Start Time',
  'dashboard.incidences.endTime': 'End Time',
  'dashboard.incidences.duration': 'Duration',
  'dashboard.incidences.include': 'Included',
  'dashboard.incidences.exclude': 'Excluded',
  'dashboard.incidences.editIncidence': 'Edit Incidence',
  'dashboard.incidence.updateSuccess': 'Incidence update successful',

  /*************************** Access Plan ***************************/
  'dashboard.accessPlan.createAnAccessPlan': 'Create an Access Plan',
  'dashboard.accessPlan.accessPlanName': 'Access Plan Name',
  'dashboard.accessPlan.customDays': 'Custom Days',
  'dashboard.accessPlan.noRestrictionsOnAccess': 'No restrictions on access to charger',
  'dashboard.accessPlan.restrictedAccess': 'Restricted access via access code or email domain',
  'dashboard.accessPlan.restrictBasedOn': 'Restrict Based On',
  'dashboard.accessPlan.assignedDriversFromUsers':
    'Assigned drivers from user page automatically get access. Change access on users page if needed.',
  'dashboard.accessPlan.usersGetAccessByAccessCode': 'Users get access by entering this access code',
  'dashboard.accessPlan.usersGetAccessByEmail': `Users get access by creating a ${currentSiteOwner} account with this email domain (e.g., someschool.edu)`,
  'dashboard.accessPlan.emailDomain': 'Email Domain',
  'dashboard.accessPlan.drivers': 'Drivers',
  'dashboard.accessPlan.nameToKeepTrack': 'A name to help you keep track',
  'dashboard.accessPlan.chargerIds': 'Charger ID(s)',
  'dashboard.accessPlan.enterEmailDomains': 'Enter email domains',
  'dashboard.accessPlan.addTimeSchedule': 'ADD TIME SCHEDULE',
  'dashboard.accessPlan.currentAssignedDrivers': '-1 Assigned Drivers',
  'dashboard.accessPlan.accessDetails': 'Access Details',
  'dashboard.accessPlan.accessPlanFlex':
    'Access plans allow you to control access to chargers by different times and/or by different groups of drivers',
  'dashboard.accessPlan.chargersNotLinked': 'Chargers Not Linked to Any Access Plans',
  'dashboard.accessPlan.assignedDrivers': 'Assigned Drivers',
  'dashboard.accessPlan.allAssigned': 'All Assigned',
  'dashboard.accessPlan.addAccessPlanStart': 'Add an Access Plan to Start',
  'dashboard.accessPlan.conflictingAccessPlan': 'Conflicting Access Plan',
  'dashboard.accessPlan.chargerInUse': 'Charger {id} is already in another access plan with a conflicting schedule',
  'dashboard.accessPlan.moveChargerSched': 'Move {id} from {name}, or go back and adjust the schedule.',
  'dashboard.accessPlan.moveCharger': 'MOVE CHARGER',
  'dashboard.accessPlan.chargersToolTip':
    'Chargers that are not linked to an access plan will not be accessible at anytime.\nAdd these chargers to an existing access plan or create a new access plan to ensure drivers have access.',
  'dashboard.accessPlan.noAccessPlanLinked': 'No Access Plan Linked with {listing}',
  'dashboard.accessPlan.removeListingAnyways':
    '{listing} has been removed from this access plan and is no longer linked to any access plans. Are you sure you want to continue?',
  'dashboard.accessPlan.chargersOverlap':
    'Chargers in this plan currently belong to existing Access Plans that have an overlapping schedule.',
  'dashboard.accessPlan.moveChargers':
    'Move these chargers from all the existing conflicting Access Plans, or go back and adjust the schedule',
  'dashboard.text.accessPlanDetails': 'Access Plan Details',
  'dashboard.text.viewSchedules': 'View Schedules',
  'dashboard.text.noAccessPlan': 'No Access Plan',
  'dashboard.text.accessPlanFor': 'Access Plans for {listingTitle}',
  'dashboard.accessPlan.accessPlanCreated': 'Access Plan Created',
  'dashboard.accessPlan.accessPlanUpdated': 'Access Plan Updated',
  'dashboard.accessPlan.accessCodeAlreadyInUse': 'Access code already in use',
  'dashboard.accessPlan.accessCode.characterLength': 'Access Code must be between {min} to {max} characters',
}
