import { useEffect, useState } from 'react'
import { ChargerSetting, Tenant, TenantContact, TenantOverview, TenantPayload } from 'models/tenant'
import { MasterAccountRef } from 'models/master-account'
import { Form } from 'antd'
import { useTenantTranslation } from './translation/useTenantTranslation'
import { useUserTranslation } from './translation/useUserTranslation'
import { useFormatMessage } from 'helpers/intl'
import { UpdateMasterAccount } from 'services/data-provider/master-account'
import { getChangedValues } from 'helpers/form'
import { useSelector } from './useSelector'
import { useNotifications } from './useNotification'

export const useTenantTabOverview = (tenant: Tenant, onSave: (tenant: TenantPayload) => void) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>(tenant.masterAccount)
  const [onsiteContact, setOnsiteContact] = useState<TenantContact | undefined>()
  const [disabled, setDisabled] = useState(true)
  const [editAccountDetailModal, setEditAccountDetailModal] = useState(false)
  const [editTenantStatusModal, setEditTenantStatusModal] = useState(false)
  const [remittanceEmails, setRemittanceEmails] = useState(
    tenant.masterAccount ? tenant.masterAccount.remittanceEmails : [],
  )
  const [remittanceFrequency, setRemittanceFrequency] = useState(tenant.masterAccount?.remittanceFrequency)
  const [status, setStatus] = useState(tenant.status)
  const [statusOnHold, setStatusOnHold] = useState('')
  const [chargerSettings, setChargerSettings] = useState<ChargerSetting[]>([])
  const { noneText } = useUserTranslation()
  const { siteLabelCleaner } = useSelector()
  const tenantName = siteLabelCleaner(tenant.combineName || tenant.displayName || tenant.name)
  const tenantUpdatedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.tenantUpdatedMessage',
    '-1 has been updated!',
  ).replace('-1', tenantName)

  const { openSuccessNotification } = useNotifications()

  const {
    monthlyText,
    quarterlyText,
    annuallyText,
    arenaText,
    campgroundText,
    fleetText,
    gasStationText,
    governmentText,
    hospitalText,
    hotelText,
    multiUniResidentialText,
    onStreetText,
    parkingLotText,
    parkingGarageText,
    publicText,
    retailText,
    schoolText,
    singleFamilyResidentialText,
    workplaceText,
    inActiveDescriptionText,
    inActiveOption1LeftText,
    inActiveOption1RightText,
    inActiveOption2LeftText,
    inActiveOption2RightText,
    inActiveOption3LeftText,
    inActiveOption3RightText,
    inActiveOption4LeftText,
    inActiveOption4RightText,
    deletedDescriptionText,
    deletedDescriptionOption1LeftText,
    deletedDescriptionOption1RightText,
  } = useTenantTranslation()

  const inActiveOptions = [
    { left: inActiveOption1LeftText, right: inActiveOption1RightText },
    { left: inActiveOption2LeftText, right: inActiveOption2RightText },
    { left: inActiveOption3LeftText, right: inActiveOption3RightText },
    { left: inActiveOption4LeftText, right: inActiveOption4RightText },
  ]

  const deletedOptions = [{ left: deletedDescriptionOption1LeftText, right: deletedDescriptionOption1RightText }]

  const reminderTexts = {
    inactive: {
      description: inActiveDescriptionText,
      options: inActiveOptions,
    },
    deleted: {
      description: deletedDescriptionText,
      options: deletedOptions,
    },
  }

  useEffect(() => {
    if (tenant.chargerSetting) {
      const chargerSettingArr: ChargerSetting[] = tenant.chargerSetting
        .split(',')
        .map((setting) => setting.trim() as ChargerSetting)
      setChargerSettings(chargerSettingArr)
    }
    tenant.tenantContacts.map((contact) => {
      if (contact.typeOfContact === 'onsite') {
        setOnsiteContact({
          namePrimary: contact.namePrimary,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
        })
        form.setFieldsValue({
          namePrimary: contact.namePrimary,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
        })
      }
      return contact
    })
  }, [])

  useEffect(() => {
    setRemittanceEmails(masterAccount?.remittanceEmails)
    setRemittanceFrequency(masterAccount?.remittanceFrequency)
    form.setFieldsValue({
      remittanceEmails: masterAccount?.remittanceEmails?.join(','),
      remittanceFrequency: masterAccount?.remittanceFrequency ? renderText(masterAccount?.remittanceFrequency) : null,
    })
  }, [masterAccount])

  useEffect(() => {
    form.setFieldsValue({
      remittanceEmails: remittanceEmails?.join(','),
      remittanceFrequency: remittanceFrequency ? renderText(remittanceFrequency) : null,
    })
  }, [remittanceEmails, remittanceFrequency])

  useEffect(() => {
    form.setFieldsValue({
      status: status,
    })
  }, [status])

  const renderText = (text: string) => {
    const textMap: { [key: string]: string } = {
      not_applicable: noneText,
      monthly: monthlyText,
      quarterly: quarterlyText,
      annually: annuallyText,
      arena_or_stadium: arenaText,
      campground: campgroundText,
      fleet: fleetText,
      gas_station: gasStationText,
      government: governmentText,
      hospital: hospitalText,
      hotel: hotelText,
      multi_unit_residential: multiUniResidentialText,
      on_street: onStreetText,
      parking_lot: parkingLotText,
      parking_garage: parkingGarageText,
      public: publicText,
      retail: retailText,
      school_university: schoolText,
      single_family_residential: singleFamilyResidentialText,
      workplace: workplaceText,
    }
    return textMap[text] || text
  }

  const initialValues = {
    displayName: tenant.displayName,
    status: tenant.status,
    serviceLevel: tenant.serviceLevel,
    omOptin: String(tenant.omOptin),
    masterAccount: tenant.masterAccount,
    taxRegistration: tenant.taxRegistration,
    taxNumber: tenant.taxNumber,
    chargerSetting: tenant.chargerSetting ? tenant.chargerSetting : null,
    remittanceEmails: remittanceEmails?.join(','),
    remittanceFrequency: remittanceFrequency ? renderText(remittanceFrequency) : null,
    namePrimary: onsiteContact?.namePrimary,
    email: onsiteContact?.email,
    phoneNumber: onsiteContact?.phoneNumber,
    notes: tenant.notes,
    partnerIds: tenant.tenantPartners?.map((partner) => partner.partnerId),
    revSharePartner: tenant?.revSharePartner,
    revSharePartnerPercent: tenant?.revSharePartnerPercent || 1,
  }

  const updateTenantOverview = async (values: TenantOverview) => {
    const changedValues: any = { ...getChangedValues(initialValues, values as any), id: tenant.id }

    changedValues.revSharePartnerTaggingId = values?.revSharePartner?.id
    changedValues.revSharePartner = values.revSharePartner

    if (masterAccount) {
      changedValues.masterAccountId = masterAccount.id
      UpdateMasterAccount(masterAccount)
        .then(() => {})
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
    }

    if (onsiteContact) {
      changedValues.tenantContact = {
        ...onsiteContact,
        typeOfContact: 'onsite',
      }
    }

    if (changedValues.taxRegistration) {
      const { taxRegistration, taxNumber } = values
      changedValues.taxNumber = ['joint_elected', 'registered'].includes(taxRegistration) ? taxNumber : null
    }

    if (changedValues.chargerSetting) {
      changedValues.chargerSetting = chargerSettings.length === 0 ? 'multi_unit_residential' : chargerSettings
    }
    setLoading(true)
    onSave({ ...changedValues, status })
    openSuccessNotification(tenantUpdatedText)
    setLoading(false)
    setDisabled(true)
  }

  const handleStatus = (newStatus: string) => {
    if (newStatus === 'inactive' || newStatus === 'deleted') {
      setStatusOnHold(newStatus)
    } else {
      setStatus(newStatus)
    }
  }

  const onReminderOk = () => {
    setStatus(statusOnHold)
    setStatusOnHold('')
    setEditTenantStatusModal(false)
  }

  const onReminderCanel = () => setStatusOnHold('')

  return {
    loading,
    initialValues,
    form,
    disabled,
    editTenantStatusModal,
    onsiteContact,
    editAccountDetailModal,
    masterAccount,
    remittanceEmails,
    remittanceFrequency,
    status,
    chargerSettings,
    setOnsiteContact,
    setEditTenantStatusModal,
    updateTenantOverview,
    setMasterAccount,
    setEditAccountDetailModal,
    setDisabled,
    setStatus,
    setRemittanceEmails,
    setRemittanceFrequency,
    handleStatus,
    reminderTexts,
    statusOnHold,
    onReminderOk,
    onReminderCanel,
    setChargerSettings,
  }
}
