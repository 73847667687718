import { Col, Row } from 'antd'
import { useDashboardTranslation } from '../../../hooks/translation/useDashboardTranslation'
import { SubSection } from '../../../atom/box'
import { UtlizationInsightContainer } from '../ContainerStyles/homepageStyles'
import { EmptyData } from '../../../components/redesign/emptyData'
import { HomePageUtilizationInsightsChart } from '../charts/active-users/utilization-insights'
import { HomePageGaugeChartUtlizationInsight } from '../charts/active-users/gauge-utilization-insight'
import { ChargerProfile, TrxSummary } from '../../../models/transaction'
import { useMediaQuery } from 'react-responsive'
import { useRef } from 'react'
import CoreTabs from 'components/tabs/core-tabs'
import configProvider from 'config'

interface props {
  utilizationProfileEmpty: boolean
  utilizationInsightChargerProfiles: ChargerProfile[] | undefined
  utlizationInsightTabKey: string
  timeZone: string
  trxProfileSummary: TrxSummary | undefined
  utilizationGauageEmpty: boolean
  utlizationInsightGaugeTabKey: string
  handleTabChangeUtilizationInsightTab: (activeKey: string) => void
  handleTabUtilizationInsightChargingDurationTab: (activeKey: string) => void
}

const UtilizationInsightsSection: React.FC<props> = ({
  utilizationProfileEmpty,
  utilizationInsightChargerProfiles,
  utlizationInsightTabKey,
  timeZone,
  trxProfileSummary,
  utilizationGauageEmpty,
  utlizationInsightGaugeTabKey,
  handleTabChangeUtilizationInsightTab,
  handleTabUtilizationInsightChargingDurationTab,
}) => {
  const showBorderBottom = useMediaQuery({ minWidth: 0, maxWidth: 991 })
  const {
    utilizationInsightsText,
    numberOfConnectorsPluggedInText,
    allText,
    weekdayText,
    weekendText,
    levelTwoText,
  } = useDashboardTranslation()
  const chartComponent = useRef(null)
  const chartOnClickCSV = () => {
    // @ts-ignore
    const series1 = chartComponent.current.chart.series[0]
    series1.setVisible(!series1.visible)
    setTimeout(() => {
      series1.setVisible(!series1.visible)
    }, 500)
  }

  const chargerProfileInfos = [
    {
      title: allText,
      key: 'All',
      content: utilizationProfileEmpty ? (
        <EmptyData />
      ) : (
        utilizationInsightChargerProfiles && (
          <HomePageUtilizationInsightsChart
            tabKey={utlizationInsightTabKey}
            chargerProfile={utilizationInsightChargerProfiles}
            timezone={timeZone}
            chartRef={chartComponent}
          />
        )
      ),
    },
    {
      title: weekdayText,
      key: 'Weekday',
      content: utilizationProfileEmpty ? (
        <EmptyData />
      ) : (
        utilizationInsightChargerProfiles && (
          <HomePageUtilizationInsightsChart
            tabKey={utlizationInsightTabKey}
            chargerProfile={utilizationInsightChargerProfiles}
            timezone={timeZone}
            chartRef={chartComponent}
          />
        )
      ),
    },
    {
      title: weekendText,
      key: 'Weekend',
      content: utilizationProfileEmpty ? (
        <EmptyData />
      ) : (
        utilizationInsightChargerProfiles && (
          <HomePageUtilizationInsightsChart
            tabKey={utlizationInsightTabKey}
            chargerProfile={utilizationInsightChargerProfiles}
            timezone={timeZone}
            chartRef={chartComponent}
          />
        )
      ),
    },
  ]

  const chargingDurationInfo = [
    {
      title: allText,
      key: 'All',
      content: utilizationGauageEmpty ? (
        <EmptyData />
      ) : (
        trxProfileSummary && (
          <HomePageGaugeChartUtlizationInsight
            trxSummary={trxProfileSummary}
            activeKey={utlizationInsightGaugeTabKey}
          />
        )
      ),
    },
    {
      title: levelTwoText,
      key: 'Level2',
      content: utilizationGauageEmpty ? (
        <EmptyData />
      ) : (
        trxProfileSummary && (
          <HomePageGaugeChartUtlizationInsight
            trxSummary={trxProfileSummary}
            activeKey={utlizationInsightGaugeTabKey}
          />
        )
      ),
    },
    ...(configProvider.config.network === 'itsElectric'
      ? []
      : [
          {
            title: 'DCFC',
            key: 'DCFC',
            content: utilizationGauageEmpty ? (
              <EmptyData />
            ) : (
              trxProfileSummary && (
                <HomePageGaugeChartUtlizationInsight
                  trxSummary={trxProfileSummary}
                  activeKey={utlizationInsightGaugeTabKey}
                />
              )
            ),
          },
        ]),
  ]

  return (
    <SubSection>
      <Row align="middle" justify="start" gutter={[15, 15]} style={{ width: '100%' }}>
        <Col span={24} style={{ width: '100%' }}>
          <h5 className="section-sub-header" style={{ marginBottom: '15px' }}>
            {utilizationInsightsText}
          </h5>
        </Col>
      </Row>
      <UtlizationInsightContainer className={`${utilizationProfileEmpty ? 'empty-utilization-insight-container' : ''}`}>
        <Row justify="start" gutter={[0, 16]} className="content">
          <Col
            xs={24}
            md={24}
            lg={16}
            xl={18}
            xxl={18}
            style={{
              borderRight: showBorderBottom ? '' : '1px solid #dedede',
              borderBottom: showBorderBottom ? '1px solid #dedede' : '',
            }}
          >
            {utilizationInsightChargerProfiles && (
              <CoreTabs
                title={numberOfConnectorsPluggedInText}
                className={'right-padding-tab'}
                downloadable={{ chart: chartComponent, onClickCSV: chartOnClickCSV }}
                tabs={chargerProfileInfos}
                onChange={handleTabChangeUtilizationInsightTab}
                activeKey={utlizationInsightTabKey}
              />
            )}
          </Col>
          <Col xs={24} md={24} lg={8} xl={6} xxl={6}>
            <>
              {trxProfileSummary && (
                <CoreTabs
                  centerTabs
                  onChange={handleTabUtilizationInsightChargingDurationTab}
                  activeKey={utlizationInsightGaugeTabKey}
                  tabs={chargingDurationInfo}
                />
              )}
            </>
          </Col>
        </Row>
      </UtlizationInsightContainer>
    </SubSection>
  )
}

export default UtilizationInsightsSection
