import { Button, Form, Radio } from 'antd'
import { NewListing } from '../../models/listing'
import { useAppState } from '../../state'
import { MustBeRequired } from '../rules/rules'
import { layout } from '../../atom/form/modal-layout'
import { AlertError } from '../error'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { useListingAccessTranslation } from 'hooks/translation/useListingAccessTranslation'
import { useListingAccess } from 'hooks/useListingAccess'

interface props {
  listings: NewListing[]
  onOk: () => void
}

export const ListingCarAndMapSettings: React.FC<props> = ({ listings, onOk }) => {
  const { language } = useAppState()
  const { error, loading, initialValues, onFinish } = useListingAccess(listings, onOk)
  const { applyText } = useGeneralTranslation()
  const {
    carSettingsText,
    noCarDetailsRequiredText,
    carDetailsRequiredText,
    mapSettingsText,
    hideListingText,
    displayListingText,
  } = useListingAccessTranslation()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  return (
    <>
      <AlertError error={error} />
      <Form {...chooseLayout()} onFinish={onFinish} initialValues={initialValues}>
        <Form.Item name="car_settings" label={carSettingsText} rules={[MustBeRequired()]} className="car-settings">
          <Radio.Group buttonStyle="outline">
            <Radio value="not_required" className="radio-button" disabled={loading}>
              {noCarDetailsRequiredText}
            </Radio>
            <Radio value="required" className="radio-button" disabled={loading}>
              {carDetailsRequiredText}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="map_settings" label={mapSettingsText} rules={[MustBeRequired()]} className="map-settings">
          <Radio.Group buttonStyle="outline">
            <Radio value="hide" className="radio-button" disabled={loading}>
              {hideListingText}
            </Radio>
            <Radio value="display" className="radio-button" disabled={loading}>
              {displayListingText}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {applyText}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
