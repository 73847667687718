import { Button, Divider, Form, Input, Row, Select, Space } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { Box } from 'atom/box'
import { InputWrapper } from 'atom/chargers'
import { renderFormatMessage } from 'helpers/intl'

import { Tenant, TenantPayload } from 'models/tenant'

import { useTenantTabPlatform } from 'hooks/useTenantTabPlatform'

import { CannotHaveSpecialCharacters } from 'components/rules/rules'
import { theme } from 'theme'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

interface TenantTabDetailProps {
  tenant: Tenant
  onSave: (tenant: TenantPayload) => void
}

export const TenantTabPartnerIntegration: React.FC<TenantTabDetailProps> = ({ tenant, onSave }) => {
  const {
    form,
    disabled,
    partnersOptions,
    isDuplicate,
    handlePartnersOptionChange,
    handleFormPlatformSubmit,
    handleToggleDisabled,
  } = useTenantTabPlatform(tenant, onSave)

  const initialValues = {
    tenant_partners: tenant.tenantPartners?.map(({ partnerId, partnerName, pmsCode }) => ({
      partnerId,
      partnerName,
      pmsCode,
    })),
  }

  const { modifyText, cancelText, saveText } = useGeneralTranslation()

  return (
    <Box padding>
      <Row align="middle" justify="center">
        <Form
          form={form}
          requiredMark={false}
          name={`tenant-${tenant?.id}`}
          onFinish={handleFormPlatformSubmit}
          initialValues={initialValues}
        >
          <InputWrapper>
            <Form.List name="tenant_partners">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <>
                      <Space key={key} align="baseline" style={{ display: 'flex' }} wrap>
                        <Form.Item hidden {...restField} name={[name, 'partnerId']} label="Partner ID">
                          <Input placeholder="Partner ID" disabled={disabled} />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          className="align-center"
                          name={[name, 'partnerName']}
                          label="Platform Name"
                          rules={[{ required: true, message: 'Please select a platform' }]}
                          style={{ marginBottom: 0 }}
                        >
                          <Select
                            showSearch
                            placeholder="Select a platform"
                            style={{ minWidth: 250, width: '100%' }}
                            disabled={disabled}
                            onChange={(value) => handlePartnersOptionChange(value, name)}
                          >
                            {partnersOptions.map((partner) => (
                              <Select.Option key={partner.partnerId} value={partner.partnerName}>
                                {partner.partnerName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>

                        {/* Conditionally disable or hide pmsCode based on partnerName */}
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.partners?.[name]?.partnerName !== curValues.partners?.[name]?.partnerName
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            const partnerName = getFieldValue(['tenant_partners', name, 'partnerName'])
                            return partnerName.toLowerCase() === 'greystar' ? (
                              <Form.Item
                                {...restField}
                                className="align-center"
                                name={[name, 'pmsCode']}
                                label={renderFormatMessage('dashboard.text.propertyManagementSoftwareCode', 'PMS Code')}
                                rules={[
                                  CannotHaveSpecialCharacters,
                                  { required: true, message: 'Missing property management software code' },
                                ]}
                              >
                                <Input placeholder="123ABC" disabled={disabled} />
                              </Form.Item>
                            ) : null
                          }}
                        </Form.Item>
                        <Form.Item>
                          <DeleteOutlined
                            onClick={(e) => {
                              if (disabled) {
                                e.stopPropagation() // Prevents click events when disabled
                              } else {
                                remove(name)
                              }
                            }}
                            style={{
                              fontSize: theme.fontSizes[2],
                              color: disabled ? theme.colors.grey4 : 'inherit', // Grayed out if disabled
                              cursor: disabled ? 'not-allowed' : 'pointer',
                            }}
                          />
                        </Form.Item>
                      </Space>
                      <Divider style={{ margin: '6px 0 24px 0' }} />
                    </>
                  ))}

                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={disabled}
                      icon={<PlusOutlined />}
                      onClick={() => add({ partnerId: '', partnerName: '', pmsCode: '' })}
                    >
                      {renderFormatMessage('dashboard.text.addPlatform', 'Add Platform')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </InputWrapper>

          <Form.Item>
            <Row justify="center">
              <Space>
                <Button htmlType="submit" type="primary" disabled={disabled || isDuplicate}>
                  {saveText}
                </Button>
                {disabled ? (
                  <Button htmlType="button" onClick={handleToggleDisabled} disabled={!disabled} type="primary">
                    {modifyText}
                  </Button>
                ) : (
                  <Button
                    danger
                    htmlType="button"
                    onClick={() => {
                      handleToggleDisabled()
                      form.resetFields()
                    }}
                  >
                    {cancelText}
                  </Button>
                )}
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </Box>
  )
}
