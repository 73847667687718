import { TrxSummary } from 'models/transaction'

export const operationRevenuesForWorkplaceCommercial = [
  60165,
  87570,
  93870,
  85365,
  83475,
  101745,
  137655,
  125055,
  118755,
  105525,
  78750,
  68985,
]
export const operationTxnCountsForWorkplaceCommercial = [191, 278, 298, 271, 265, 323, 437, 397, 377, 335, 250, 219]
export const operationEnergiesForWorkplaceCommercial = [
  4202000,
  6116000,
  6556000,
  5962000,
  5830000,
  7106000,
  9614000,
  8734000,
  8294000,
  7370000,
  5500000,
  4818000,
]

export const chargerUtilizationInUseAllWeekForWorkplaceCommercial = [
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  2,
  4,
  6,
  7,
  6,
  5,
  6,
  7,
  7,
  8,
  8,
  6,
  5,
  5,
  5,
  0,
  0,
]

export const chargerUtilizationInUseWeekdayForWorkplaceCommercial = [
  0,
  0,
  0,
  0,
  0,
  0,
  1,
  2,
  4,
  8,
  10,
  10,
  9,
  9,
  10,
  10,
  10,
  10,
  5,
  8,
  8,
  8,
  0,
  0,
]
export const chargerUtilizationInUseWeekendForWorkplaceCommercial = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  4,
  4,
  4,
  2,
  0,
  3,
  3,
  3,
  6,
  6,
  6,
  2,
  2,
  1,
  0,
  0,
]

export const consumptionWeekForWorkplaceCommercial = [
  0,
  0,
  0,
  0,
  0,
  0,
  4000,
  11000,
  29000,
  43000,
  50000,
  43000,
  32000,
  43000,
  47000,
  47000,
  58000,
  58000,
  40000,
  36000,
  36000,
  32000,
  0,
  0,
]

export const consumptionWeekdayForWorkplaceCommercial = [
  0,
  0,
  0,
  0,
  0,
  0,
  7,
  7,
  29000,
  58000,
  72000,
  72000,
  65000,
  65000,
  72000,
  72000,
  72000,
  72000,
  36000,
  58000,
  58000,
  58000,
  0,
  0,
]
export const consumptionWeekendForWorkplaceCommercial = [
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  14000,
  29000,
  29000,
  29000,
  14000,
  0,
  22000,
  22000,
  22000,
  43000,
  43000,
  43000,
  14000,
  14000,
  7000,
  0,
  0,
]

export const summaryForWorkplaceCommercial = {
  txnCount: 3641,
  txnAfterHr: 801.02,
  txnOnDays: 3204.08,
  revenueTotal: 11469015,
  revenueDemandResponse: 0,
  revenueCharging: 11469015,
  revenueLoitering: 0,
  energyConsumeTotal: 80102000,
  energyConsumeInPeakHrs: 33653220,
  ghgSavingTotal: 72038250,
  chargerAverageDayDuration: 22980,
  chargerAverageDayLevel2Duration: 20220,
  chargerAverageDayDcfcDuration: 2815,
  chargerAverageDayPlugin: 54720,
  chargerAverageDayLevel2Plugin: 51863,
  chargerAverageDayDcfcPlugin: 2857,
  usersTotal: 30,
  avgTxnDay: 11,
  avgEnergyConsumePerTxn: 15120,
  avgUserSpend: 315,
  stationActivation: {
    mobile: 87,
    web: 2,
    rfid: 187,
    roaming: 0,
  },
} as TrxSummary
