import moment from 'moment'

import { Transaction } from '../models/transaction'
import { TransactionResponse, TransactionResponsev2 } from '../models/http'

import { mockUserRef } from './user-mock'
import { mockCarRef } from './car-mock'
import { mockListing } from './listing-mock'
import { mockCharger } from './charger-mock'
import { financialMock, generateFinancialMock } from './financial-mock'
import { mockTenantsRef } from 'services/data-provider/mock'
import { mockConnectorById } from './connector-mock'

import { TransactionFilter } from 'services/data-provider/transaction'

export const mockTransactions = [
  {
    id: 1411122,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 15180,
    startAt: '2024-01-12T08:45:05.000-04:00',
    chargeStartAt: '2024-01-12T08:45:05.000-04:00',
    chargeEndAt: '2024-01-12T12:58:05.000-04:00',
    endAt: '2024-01-12T12:58:05.000-04:00',
    timezone: 'America/Toronto',
    duration: 14912,
    energy: 22000,
    tenant: mockTenantsRef[0],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[0],
    listing: mockListing['L6652'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411123,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'confirmed',
    currentChargingDuration: 9960,
    startAt: '2024-02-05T09:30:13.000-04:00',
    endAt: '2024-02-05T12:16:59.000Z',
    chargeStartAt: '2024-02-05T09:30:13.000-04:00',
    chargeEndAt: '2024-02-05T12:16:59.000Z',
    timezone: 'America/Toronto',
    duration: 15653,
    energy: 31000,
    tenant: mockTenantsRef[0],
    financial: financialMock[1],
    charger: {
      id: 69,
      chargePointSerialNumber: '1EC1-1-1824-00009',
      chargePointVendor: 'Phi-Phong',
      chargingType: 'Level 2',
    },
    user: mockUserRef[1],
    listing: mockListing['L6653'],
    connector: {
      id: 72,
      connectorId: 1,
      connectorType: 'CHAdeMO',
    },
    car: mockCarRef,
  },
  {
    id: 1411124,
    startMethod: 'website',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 20460,
    startAt: '2024-03-09T11:15:48.000-04:00',
    endAt: '2024-03-09T16:56:09.000Z',
    chargeStartAt: '2024-03-09T11:15:48.000-04:00',
    chargeEndAt: '2024-03-09T16:56:09.000Z',
    timezone: 'America/Toronto',
    duration: 0,
    energy: 31000,
    tenant: mockTenantsRef[0],
    financial: financialMock[0],
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L6655'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411125,
    startMethod: 'mobile',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 15120,
    startAt: '2024-04-03T14:00:00.000-04:00',
    endAt: '2024-04-03T18:12:00.000-04:00',
    chargeStartAt: '2024-04-03T14:00:00.000-04:00',
    chargeEndAt: '2024-04-03T18:12:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 15120,
    energy: 22000,
    tenant: mockTenantsRef[1],
    financial: financialMock[2],
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[3],
    listing: mockListing['L6657'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: mockCarRef,
  },
  {
    id: 1411126,
    startMethod: 'mobile',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 7920,
    startAt: '2024-05-15T10:30:00.000-04:00',
    endAt: '2024-05-15T12:46:00.000-04:00',
    chargeStartAt: '2024-05-15T10:30:00-04:00',
    chargeEndAt: '2024-05-15T12:46:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 13000,
    tenant: mockTenantsRef[0],
    financial: financialMock[2],
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[3],
    listing: mockListing['L6654'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411127,
    startMethod: 'mobile',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 15000,
    startAt: '2024-06-21T13:00:00.000-04:00',
    endAt: '2024-06-21T17:10:00.000-04:00',
    chargeStartAt: '2024-06-21T13:00:00.000-04:00',
    chargeEndAt: '2024-06-21T17:10:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 22000,
    tenant: mockTenantsRef[1],
    financial: financialMock[2],
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[3],
    listing: mockListing['L6656'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411128,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 13680,
    startAt: '2024-07-08T15:45:00.000-04:00',
    endAt: '2024-07-08T19:33:00.000-04:00',
    chargeStartAt: '2024-07-08T15:45:00.000-04:00',
    chargeEndAt: '2024-07-08T19:33:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 15000,
    tenant: mockTenantsRef[0],
    financial: financialMock[3],
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L6653'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411129,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 14580,
    startAt: '2024-08-17T14:30:00.000-04:00',
    endAt: '2024-08-17T20:33:00.000-04:00',
    chargeStartAt: '2024-08-17T14:30:00.000-04:00',
    chargeEndAt: '2024-08-17T20:33:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 22000,
    tenant: mockTenantsRef[1],
    financial: financialMock[2],
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L6658'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411130,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 11700,
    startAt: '2024-09-22T19:00:00.000-04:00',
    endAt: '2024-09-22T22:15:00.000-04:00',
    chargeStartAt: '2024-09-22T19:00:00.000-04:00',
    chargeEndAt: '2024-09-22T22:15:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 15000,
    tenant: mockTenantsRef[0],
    financial: generateFinancialMock(465),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L6652'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411131,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 19920,
    startAt: '2024-10-10T09:00:00.000-04:00',
    endAt: '2024-10-10T14:32:00.000-04:00',
    chargeStartAt: '2024-10-10T09:00:00.000-04:00',
    chargeEndAt: '2024-10-10T14:32:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 31000,
    tenant: mockTenantsRef[0],
    financial: generateFinancialMock(465),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L6654'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411132,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 24120,
    startAt: '2024-11-04T10:15:00.000-04:00',
    endAt: '2024-11-05T16:57:00.000-04:00',
    chargeStartAt: '2024-10-10T09:00:00.000-04:00',
    chargeEndAt: '2024-10-10T14:32:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 41000,
    tenant: mockTenantsRef[0],
    financial: generateFinancialMock(615),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L6653'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411146,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 15180,
    startAt: '2024-01-12T08:45:00.000-04:00',
    endAt: '2024-01-12T12:58:00.000-04:00',
    chargeStartAt: '2024-10-10T09:00:00.000-04:00',
    chargeEndAt: '2024-10-10T14:32:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 22000,
    tenant: mockTenantsRef[2],
    financial: generateFinancialMock(550),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L7715'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 1411147,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 9960,
    startAt: '2024-02-05T09:30:00.000-04:00',
    endAt: '2024-02-05T12:16:00.000-04:00',
    chargeStartAt: '2024-02-05T09:30:00.000-04:00',
    chargeEndAt: '2024-02-05T12:16:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 13000,
    tenant: mockTenantsRef[2],
    financial: generateFinancialMock(325),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L7716'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: mockCarRef,
  },
  {
    id: 1411148,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 20460,
    startAt: '2024-03-09T11:15:00.000-04:00',
    endAt: '2024-03-09T16:56:00.000-04:00',
    chargeStartAt: '2024-03-09T11:15:00.000-04:00',
    chargeEndAt: '2024-03-09T16:56:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 31000,
    tenant: mockTenantsRef[2],
    financial: generateFinancialMock(775),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L7718'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: mockCarRef,
  },
  {
    id: 1411149,
    startMethod: 'mobile',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 15120,
    startAt: '2024-04-03T02:00:00.000-04:00',
    endAt: '2024-04-03T18:12:00.000-04:00',
    chargeStartAt: '2024-04-03T02:00:00.000-04:00',
    chargeEndAt: '2024-04-03T18:12:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 3,
    energy: 31000,
    tenant: mockTenantsRef[2],
    financial: generateFinancialMock(775),
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'LITE-ON',
      chargingType: 'Level 2',
    },
    user: mockUserRef[2],
    listing: mockListing['L7716'],
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: mockCarRef,
  },
  {
    id: 1411150,
    startMethod: 'mobile app',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 7920, // 2 hours 12 minutes converted to seconds
    startAt: '2024-05-15T10:30:00.000-04:00',
    chargeStartAt: '2024-05-15T10:30:00.000-04:00',
    chargeEndAt: '2024-05-15T12:46:00.000-04:00',
    endAt: '2024-05-15T12:46:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 7920, // Duration in seconds (2:12 -> 7920 seconds)
    energy: 13000, // 13 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7723'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411151,
    startMethod: 'mobile app',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 15000, // 4 hours 10 minutes converted to seconds
    startAt: '2021-06-21T13:00:00.000-04:00',
    chargeStartAt: '2021-06-21T13:00:00.000-04:00',
    chargeEndAt: '2021-06-21T17:10:00.000-04:00',
    endAt: '2021-06-21T17:10:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 15000, // Duration in seconds (4:10 -> 15000 seconds)
    energy: 22000, // 22 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7717'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411152,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 13680, // 3 hours 48 minutes converted to seconds
    startAt: '2024-07-08T15:45:00.000-04:00',
    chargeStartAt: '2024-07-08T15:45:00.000-04:00',
    chargeEndAt: '2024-07-08T19:33:00.000-04:00',
    endAt: '2024-07-08T19:33:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 13680, // Duration in seconds (3:48 -> 13680 seconds)
    energy: 15000, // 15 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7721'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411153,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 14580, // 4 hours 3 minutes converted to seconds
    startAt: '2024-08-17T16:30:00.000-04:00',
    chargeStartAt: '2024-08-17T16:30:00.000-04:00',
    chargeEndAt: '2024-08-17T20:33:00.000-04:00',
    endAt: '2024-08-17T20:33:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 14580, // Duration in seconds (4:03 -> 14580 seconds)
    energy: 22000, // 22 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7716'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411154,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 11700, // 3 hours 15 minutes converted to seconds
    startAt: '2024-09-22T19:00:00.000-04:00',
    chargeStartAt: '2024-09-22T19:00:00.000-04:00',
    chargeEndAt: '2024-09-22T22:15:00.000-04:00',
    endAt: '2024-09-22T22:15:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 11700, // Duration in seconds (3:15 -> 11700 seconds)
    energy: 15000, // 15 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7723'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411155,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 19920, // 5 hours 32 minutes converted to seconds
    startAt: '2024-10-10T09:00:00.000-04:00',
    chargeStartAt: '2024-10-10T09:00:00.000-04:00',
    chargeEndAt: '2024-10-10T14:32:00.000-04:00',
    endAt: '2024-10-10T14:32:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 19920, // Duration in seconds (5:32 -> 19920 seconds)
    energy: 31000, // 31 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7718'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411156,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 24120, // 6 hours 42 minutes converted to seconds
    startAt: '2024-11-04T10:15:00.000-04:00',
    chargeStartAt: '2024-11-04T10:15:00.000-04:00',
    chargeEndAt: '2024-11-04T16:57:00.000-04:00',
    endAt: '2024-11-04T16:57:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 24120, // Duration in seconds (6:42 -> 24120 seconds)
    energy: 41000, // 41 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[0],
    listing: mockListing['L7722'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411157,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 20400, // 5 hours 40 minutes converted to seconds
    startAt: '2024-12-01T11:30:00.000-04:00',
    chargeStartAt: '2024-12-01T11:30:00.000-04:00',
    chargeEndAt: '2024-12-01T17:10:00.000-04:00',
    endAt: '2024-12-01T17:10:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 20400, // Duration in seconds (5:40 -> 20400 seconds)
    energy: 31000, // 31 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7724'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411158,
    startMethod: 'mobile app',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 18660, // 5 hours 11 minutes converted to seconds
    startAt: '2024-01-24T12:00:00.000-05:00',
    chargeStartAt: '2024-01-24T12:00:00.000-05:00',
    chargeEndAt: '2024-01-24T17:11:00.000-05:00',
    endAt: '2024-01-24T17:11:00.000-05:00',
    timezone: 'America/Toronto',
    duration: 18660, // Duration in seconds (5:11 -> 18660 seconds)
    energy: 30000, // 30 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7716'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411159,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 9360, // 2 hours 36 minutes converted to seconds
    startAt: '2024-02-12T15:00:00.000-05:00',
    chargeStartAt: '2024-02-12T15:00:00.000-05:00',
    chargeEndAt: '2024-02-12T17:36:00.000-05:00',
    endAt: '2024-02-12T17:36:00.000-05:00',
    timezone: 'America/Toronto',
    duration: 9360, // Duration in seconds (2:36 -> 9360 seconds)
    energy: 13000, // 13 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7718'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411160,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 7920, // 2 hours 12 minutes converted to seconds
    startAt: '2024-03-18T18:00:00.000-04:00',
    chargeStartAt: '2024-03-18T18:00:00.000-04:00',
    chargeEndAt: '2024-03-18T20:12:00.000-04:00',
    endAt: '2024-03-18T20:12:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 7920, // Duration in seconds (2:12 -> 7920 seconds)
    energy: 13000, // 13 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7721'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411161,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 14760, // 4 hours 6 minutes converted to seconds
    startAt: '2024-04-25T08:30:00.000-04:00',
    chargeStartAt: '2024-04-25T08:30:00.000-04:00',
    chargeEndAt: '2024-04-25T12:36:00.000-04:00',
    endAt: '2024-04-25T12:36:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 14760, // Duration in seconds (4:06 -> 14760 seconds)
    energy: 22000, // 22 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7724'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411162,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 16020, // 4 hours 27 minutes converted to seconds
    startAt: '2024-05-29T09:45:00.000-04:00',
    chargeStartAt: '2024-05-29T09:45:00.000-04:00',
    chargeEndAt: '2024-05-29T14:12:00.000-04:00',
    endAt: '2024-05-29T14:12:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 16020, // Duration in seconds (4:27 -> 16020 seconds)
    energy: 23000, // 23 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7719'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411163,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 14400, // 4 hours 0 minutes converted to seconds
    startAt: '2024-06-14T13:30:00.000-04:00',
    chargeStartAt: '2024-06-14T13:30:00.000-04:00',
    chargeEndAt: '2024-06-14T17:30:00.000-04:00',
    endAt: '2024-06-14T17:30:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 14400, // Duration in seconds (4:00 -> 14400 seconds)
    energy: 22000, // 22 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7720'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411164,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 18000, // 5 hours 0 minutes converted to seconds
    startAt: '2024-07-24T13:30:00.000-04:00',
    chargeStartAt: '2024-07-24T13:30:00.000-04:00',
    chargeEndAt: '2024-07-24T18:30:00.000-04:00',
    endAt: '2024-07-24T18:30:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 18000,
    energy: 30000, // 30 kWh converted to Wh
    tenant: mockTenantsRef[1],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7720'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411165,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 7200, // 2 hours 0 minutes converted to seconds
    startAt: '2024-08-05T17:15:00.000-04:00',
    chargeStartAt: '2024-08-05T17:15:00.000-04:00',
    chargeEndAt: '2024-08-05T19:15:00.000-04:00',
    endAt: '2024-08-05T19:15:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 7200,
    energy: 13000, // 13 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7719'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411166,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 10800, // 3 hours 0 minutes converted to seconds
    startAt: '2024-09-30T14:45:00.000-04:00',
    chargeStartAt: '2024-09-30T14:45:00.000-04:00',
    chargeEndAt: '2024-09-30T17:45:00.000-04:00',
    endAt: '2024-09-30T17:45:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 10800,
    energy: 15000, // 15 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7724'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411167,
    startMethod: 'mobile app',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 18000, // 5 hours 0 minutes converted to seconds
    startAt: '2024-10-20T09:05:00.000-04:00',
    chargeStartAt: '2024-10-20T09:05:00.000-04:00',
    chargeEndAt: '2024-10-20T14:05:00.000-04:00',
    endAt: '2024-10-20T14:05:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 18000,
    energy: 30000, // 30 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7718'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411168,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 18840, // 5 hours 14 minutes converted to seconds
    startAt: '2024-11-11T11:12:00.000-05:00',
    chargeStartAt: '2024-11-11T11:12:00.000-05:00',
    chargeEndAt: '2024-11-11T16:26:00.000-05:00',
    endAt: '2024-11-11T16:26:00.000-05:00',
    timezone: 'America/Toronto',
    duration: 18840,
    energy: 30000, // 30 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7720'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411169,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 28800, // 8 hours 0 minutes converted to seconds
    startAt: '2024-12-10T07:58:00.000-05:00',
    chargeStartAt: '2024-12-10T07:58:00.000-05:00',
    chargeEndAt: '2024-12-10T15:58:00.000-05:00',
    endAt: '2024-12-10T15:58:00.000-05:00',
    timezone: 'America/Toronto',
    duration: 28800,
    energy: 56000, // 56 kWh converted to Wh
    tenant: mockTenantsRef[2],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00061'],
    user: mockUserRef[2],
    listing: mockListing['L7723'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
  {
    id: 1411170,
    startMethod: 'rfid',
    percent: 0,
    percentStart: null,
    currentState: 'completed',
    currentChargingDuration: 16020, // 4 hours 27 minutes converted to seconds
    startAt: '2024-05-29T09:45:00.000-04:00',
    chargeStartAt: '2024-05-29T09:45:00.000-04:00',
    chargeEndAt: '2024-05-29T14:12:00.000-04:00',
    endAt: '2024-05-29T14:12:00.000-04:00',
    timezone: 'America/Toronto',
    duration: 16020,
    energy: 23000, // 23 kWh converted to Wh
    tenant: mockTenantsRef[3],
    financial: financialMock[0],
    charger: mockCharger['1EC0-1-1823-00063'],
    user: mockUserRef[2],
    listing: mockListing['L7720'],
    connector: mockConnectorById['71'],
    car: mockCarRef,
  },
] as Transaction[]

export const mockTransactionResp = (filters?: TransactionFilter) => {
  let filteredTransactions = mockTransactions

  // Filter by listing title
  if (filters?.listingTitle && filters.listingTitle.length > 0) {
    const listingTitles = filters.listingTitle.map((title) => title)
    filteredTransactions = mockTransactions.filter((t) => listingTitles.includes(t.listing.title))
  }

  // Filter by tenant
  if (filters?.tenants && filters.tenants.length > 0) {
    const tenantIds = filters.tenants.map((t) => t.id)
    filteredTransactions = filteredTransactions.filter((t) => tenantIds.includes(t.tenant.id))
  }

  // filter by serial number(s)
  if (filters?.chargers && filters.chargers.length > 0) {
    const chargerSerialNumbers = Array.from(new Set(filters.chargers.map((c) => c)))

    filteredTransactions = filteredTransactions.filter((t) =>
      chargerSerialNumbers.includes(t.charger.chargePointSerialNumber),
    )
  }
  // Filter by user's email
  if (filters?.email) {
    filteredTransactions = filteredTransactions.filter((t) => t.user.email === filters.email)
  }

  // filter by start method
  if (filters?.startMethod) {
    filteredTransactions = filteredTransactions.filter((t) => t.startMethod === filters.startMethod)
  }

  // filter by state
  if (filters?.state) {
    filteredTransactions = filteredTransactions.filter((t) => t.currentState === filters.state)
  }

  // filter by user name
  if (filters?.userName) {
    filteredTransactions = filteredTransactions.filter((t) => t.user.name === filters.userName)
  }

  // filter by master accounts
  if (filters?.masterAccounts && filters.masterAccounts.length > 0) {
    const masterAccountIds = filters.masterAccounts.map((m) => m.id)
    filteredTransactions = filteredTransactions.filter((t) => masterAccountIds.includes(Number(t.tenant.id)))
  }

  // filter by transaction id
  if (filters?.transactionId) {
    filteredTransactions = filteredTransactions.filter((t) => t.id === filters.transactionId)
  }

  return {
    data: filteredTransactions,
    pagination: {
      currentPage: filters?.page,
      totalEntries: filteredTransactions.length,
      perPage: 20,
    },
    filters: {},
  } as TransactionResponse
}

export const calculateTransactionMetrics = (mockTransactionsResp: TransactionResponse) => {
  const totalTransactions = mockTransactionsResp.data.length
  const totalEnergy = mockTransactionsResp.data.reduce((acc, t) => acc + t.energy, 0)
  const totalDuration = mockTransactionsResp.data.reduce((acc, t) => acc + t.currentChargingDuration, 0)
  // Count unique users
  const uniqueUsers = new Set(mockTransactionsResp.data.map((transaction) => transaction.user.id))
  const uniqueUserCount = uniqueUsers.size
  const totalRevenue = mockTransactionsResp.data.reduce((acc, t) => acc + t.financial.total, 0)

  return {
    revenue: totalRevenue,
    count: totalTransactions,
    energy: totalEnergy / 1000,
    duration: totalDuration,
    uniqueUsers: uniqueUserCount,
    errorCount: 0,
    cancelCount: 0,
    timestamp: moment(),
    date: moment().format('YYYY-MM-DD'),
    humanizeTimestamp: moment().format('MMMM Do YYYY, h:mm:ss a'),
  }
}

export const mockTransactionHomePage = [
  {
    txnId: 1411122,
    startMethod: 'RFID',
    plugInDuration: 15180,
    connected: '2024-01-12T08:45:05.000-04:00',
    disconnected: '2024-01-12T12:58:05.000-04:00',
    energy: 22000,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411123,
    startMethod: 'RFID',
    plugInDuration: 9960,
    connected: '2024-02-05T09:30:13.000-04:00',
    disconnected: '2024-02-05T12:16:59.000Z',
    energy: 31000,
    state: 'confirmed',
    total: 1180,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411124,
    startMethod: 'website',
    plugInDuration: 20460,
    connected: '2024-03-09T11:15:48.000-04:00',
    disconnected: '2024-03-09T16:56:09.000Z',
    energy: 31000,
    state: 'completed',
    total: 330,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411125,
    startMethod: 'Mobile',
    plugInDuration: 15120,
    connected: '2024-04-03T14:00:00.000-04:00',
    disconnected: '2024-04-03T18:12:00.000-04:00',
    energy: 22000,
    state: 'completed',
    total: 330,
    username: mockTenantsRef[1].displayName,
  },
  {
    txnId: 1411126,
    startMethod: 'Mobile',
    plugInDuration: 7920,
    connected: '2024-05-15T10:30:00.000-04:00',
    disconnected: '2024-05-15T12:46:00.000-04:00',
    energy: 13000,
    state: 'completed',
    total: 330,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411127,
    startMethod: 'Mobile',
    plugInDuration: 15000,
    connected: '2024-06-21T13:00:00.000-04:00',
    disconnected: '2024-06-21T17:10:00.000-04:00',
    energy: 22000,
    state: 'completed',
    total: 330,
    username: mockTenantsRef[1].displayName,
  },
  {
    txnId: 1411128,
    startMethod: 'RFID',
    plugInDuration: 6329,
    connected: '2024-07-08T15:45:00.000-04:00',
    disconnected: '2024-07-08T19:33:00.000-04:00',
    energy: 15000,
    state: 'completed',
    total: 225,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411129,
    startMethod: 'RFID',
    plugInDuration: 14580,
    connected: '2024-08-17T14:30:00.000-04:00',
    disconnected: '2024-08-17T20:33:00.000-04:00',
    energy: 22000,
    state: 'completed',
    total: 330,
    username: mockTenantsRef[1].displayName,
  },
  {
    txnId: 1411130,
    startMethod: 'RFID',
    plugInDuration: 11700,
    connected: '2024-09-22T19:00:00.000-04:00',
    disconnected: '2024-09-22T22:15:00.000-04:00',
    energy: 15000,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411131,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[0].displayName,
  },
  {
    txnId: 1411132,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[2].displayName,
  },
  {
    txnId: 1411133,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[2].displayName,
  },
  {
    txnId: 1411134,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[2].displayName,
  },
  {
    txnId: 1411135,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[2].displayName,
  },
  {
    txnId: 1411136,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[2].displayName,
  },
  {
    txnId: 1411137,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[2].displayName,
  },
  {
    txnId: 1411138,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[3].displayName,
  },
  {
    txnId: 1411139,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[3].displayName,
  },
  {
    txnId: 1411140,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[3].displayName,
  },
  {
    txnId: 1411141,
    startMethod: 'RFID',
    plugInDuration: 19920,
    connected: '2024-10-10T09:00:00.000-04:00',
    disconnected: '2024-10-10T14:32:00.000-04:00',
    energy: 19920,
    state: 'completed',
    total: 465,
    username: mockTenantsRef[3].displayName,
  },
]

export const mockTransactionRespv2 = (filters: TransactionFilter) => {
  let filteredTransactionsHome = mockTransactionHomePage

  if (filters.tenants && filters.tenants.length) {
    const tenantIds = filters.tenants.map((t) => t.displayName)
    filteredTransactionsHome = mockTransactionHomePage.filter((t) => tenantIds.includes(t.username))
  }

  return {
    txnCount: filteredTransactionsHome.length,
    pageCount: 1,
    nextPage: 2,
    perPage: 10,
    transactions: filteredTransactionsHome,
  } as TransactionResponsev2
}
