import { TrxSummary } from 'models/transaction'

export const operationRevenuesForMultiFamily1 = [
  39150,
  56925,
  60975,
  55575,
  54225,
  66150,
  89550,
  81225,
  77175,
  68400,
  51075,
  44775,
]
export const operationTxnCountsForMultiFamily1 = [174, 253, 271, 247, 241, 294, 398, 361, 343, 304, 227, 199]
export const operationEnergiesForMultiFamily1 = [
  2610000,
  3795000,
  4065000,
  3705000,
  3615000,
  4410000,
  5970000,
  5415000,
  5145000,
  4560000,
  3405000,
  2985000,
]

export const chargerUtilizationInUseAllWeekForMultiFamily1 = [
  3,
  2,
  2,
  2,
  1,
  1,
  1,
  1,
  2,
  3,
  3,
  3,
  4,
  5,
  9,
  10,
  11,
  12,
  12,
  12,
  10,
  8,
  7,
  5,
]

export const chargerUtilizationInUseWeekdayForMultiFamily1 = [
  4,
  2,
  2,
  2,
  1,
  1,
  1,
  1,
  1,
  2,
  2,
  2,
  4,
  4,
  8,
  9,
  10,
  11,
  12,
  12,
  12,
  10,
  8,
  6,
]
export const chargerUtilizationInUseWeekendForMultiFamily1 = [
  0,
  2,
  2,
  1,
  1,
  1,
  1,
  1,
  1,
  2,
  3,
  3,
  3,
  4,
  6,
  10,
  10,
  11,
  12,
  12,
  12,
  8,
  6,
  6,
  4,
]

export const consumptionWeekForMultiFamily1 = [
  50000,
  50000,
  40000,
  32000,
  25000,
  4000,
  4000,
  4000,
  7000,
  14000,
  14000,
  14000,
  18000,
  29000,
  43000,
  43000,
  54000,
  58000,
  72000,
  76000,
  86000,
  75000,
  65000,
  57000,
]

export const consumptionWeekdayForMultiFamily1 = [
  86000,
  86000,
  72000,
  58000,
  43000,
  0,
  0,
  0,
  0,
  7000,
  7000,
  7000,
  7000,
  14000,
  14000,
  14000,
  29000,
  29000,
  58000,
  65000,
  86000,
  86000,
  86000,
  86000,
]
export const consumptionWeekendForMultiFamily1 = [
  86000,
  63000,
  43000,
  29000,
  14000,
  14000,
  7000,
  7000,
  7000,
  7000,
  7000,
  7000,
  14000,
  22000,
  22000,
  22000,
  29000,
  43000,
  72000,
  72000,
  79000,
  86000,
  86000,
  86000,
]

export const summaryForMultiFamily1 = {
  txnCount: 3312,
  txnAfterHr: 1490.4,
  txnOnDays: 2583.36,
  revenueTotal: 745200,
  revenueDemandResponse: 0,
  revenueCharging: 745200,
  revenueLoitering: 0,
  energyConsumeTotal: 49680000,
  energyConsumeInPeakHrs: 5961600,
  ghgSavingTotal: 36202,
  chargerAverageDayDuration: 13620,
  chargerAverageDayLevel2Duration: 5460,
  chargerAverageDayDcfcDuration: 0,
  chargerAverageDayPlugin: 49320,
  chargerAverageDayLevel2Plugin: 13620,
  chargerAverageDayDcfcPlugin: 0,
  usersTotal: 20,
  avgTxnDay: 13,
  avgEnergyConsumePerTxn: 15000,
  avgUserSpend: 225,
  stationActivation: {
    mobile: 87,
    web: 2,
    rfid: 187,
    roaming: 0,
  },
} as TrxSummary

export const operationRevenuesForMultiFamily2 = [
  39150,
  56925,
  60975,
  55575,
  54225,
  66150,
  89550,
  81225,
  77175,
  68400,
  51075,
  44775,
]
export const operationTxnCountsForMultiFamily2 = [174, 253, 271, 247, 241, 294, 398, 361, 343, 304, 227, 199]
export const operationEnergiesForMultiFamily2 = [
  2610000,
  3795000,
  4065000,
  3705000,
  3615000,
  4410000,
  5970000,
  5415000,
  5145000,
  4560000,
  3405000,
  2985000,
]

export const chargerUtilizationInUseAllWeekForMultiFamily2 = [
  3,
  2,
  2,
  2,
  1,
  1,
  1,
  1,
  2,
  3,
  3,
  3,
  4,
  5,
  9,
  10,
  11,
  12,
  12,
  12,
  10,
  8,
  7,
  5,
]

export const chargerUtilizationInUseWeekdayForMultiFamily2 = [
  4,
  2,
  2,
  2,
  1,
  1,
  1,
  1,
  1,
  2,
  2,
  2,
  4,
  4,
  8,
  9,
  10,
  11,
  12,
  12,
  12,
  10,
  8,
  6,
]
export const chargerUtilizationInUseWeekendForMultiFamily2 = [
  0,
  2,
  2,
  1,
  1,
  1,
  1,
  1,
  1,
  2,
  3,
  3,
  3,
  4,
  6,
  10,
  10,
  11,
  12,
  12,
  12,
  8,
  6,
  6,
  4,
]

export const consumptionWeekForMultiFamily2 = [
  50000,
  50000,
  40000,
  32000,
  25000,
  4000,
  4000,
  4000,
  7000,
  14000,
  14000,
  14000,
  18000,
  29000,
  43000,
  43000,
  54000,
  58000,
  72000,
  76000,
  86000,
  75000,
  65000,
  57000,
]

export const consumptionWeekdayForMultiFamily2 = [
  86000,
  86000,
  72000,
  58000,
  43000,
  0,
  0,
  0,
  0,
  7000,
  7000,
  7000,
  7000,
  14000,
  14000,
  14000,
  29000,
  29000,
  58000,
  65000,
  86000,
  86000,
  86000,
  86000,
]
export const consumptionWeekendForMultiFamily2 = [
  86000,
  63000,
  43000,
  29000,
  14000,
  14000,
  7000,
  7000,
  7000,
  7000,
  7000,
  7000,
  14000,
  22000,
  22000,
  22000,
  29000,
  43000,
  72000,
  72000,
  79000,
  86000,
  86000,
  86000,
]

export const summaryForMultiFamily2 = {
  txnCount: 3312,
  txnAfterHr: 1490.4,
  txnOnDays: 2583.36,
  revenueTotal: 745200,
  revenueDemandResponse: 0,
  revenueCharging: 745200,
  revenueLoitering: 0,
  energyConsumeTotal: 49680000,
  energyConsumeInPeakHrs: 5961600,
  ghgSavingTotal: 36202,
  chargerAverageDayDuration: 13620,
  chargerAverageDayLevel2Duration: 5460,
  chargerAverageDayDcfcDuration: 0,
  chargerAverageDayPlugin: 49320,
  chargerAverageDayLevel2Plugin: 13620,
  chargerAverageDayDcfcPlugin: 0,
  usersTotal: 20,
  avgTxnDay: 13,
  avgEnergyConsumePerTxn: 15000,
  avgUserSpend: 225,
  stationActivation: {
    mobile: 87,
    web: 2,
    rfid: 187,
    roaming: 0,
  },
} as TrxSummary
