import { useFormatMessage } from 'helpers/intl'

export const usePlatformTranslation = () => {
  const selectPartnerText = useFormatMessage('dashboard.text.selectPartner', 'Select a partner')

  const platformSuccessText = useFormatMessage('dashboard.text.platformSuccess', 'Platform updated successfully')

  const platformCodeText = useFormatMessage('dashboard.text.platformCode', 'Platform Code')

  return {
    selectPartnerText,
    platformCodeText,
    platformSuccessText,
  }
}
