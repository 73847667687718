import { useState } from 'react'

import { NewListing } from 'models/listing'
import { useAppState } from 'state'
import { convertStringifyNumberToNumberType, formatCentsPrice } from 'helpers/number-format'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'

import { handlePreferredPrice, isItHourOrkWh } from 'helpers/price/priceHelper'

import { FreeListing } from '../prices/FreeListing'
import { FlatFeeOnly } from '../prices/FlatFeeOnly'
import { PriceWithFlatFee } from '../prices/PriceWithFlatFee'
import { TimeOfDayPrice } from '../prices/TimeOfDayPrice'
import TimeOfDayModal from '../modal/TimeOfDayModal'
import ListingLoiteringStatus from './loitering'

interface Props {
  listing: NewListing
  term?: string
}

const HandlePrice: React.FC<Props> = ({ listing, term }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()
  const [selectedListing, setSelectedListing] = useState<NewListing | undefined>()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const showTimeOfDayModal = (listing: NewListing) => setSelectedListing(listing)
  const handleModal = () => setSelectedListing(undefined)

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  const { feeText, timeOfDayText } = usePricingSchemasTranslation()

  const parsePrice = (listing: NewListing, term?: string) => {
    if (term === 'preferredPrice') {
      return handlePreferredPrice(listing.price)
    }
    return parseGeneralPrice(listing)
  }

  const parseGeneralPrice = (listing: NewListing) => {
    const { price } = listing
    const { enableTimeOfDayPricing, flatFee, priceType, pricePerUnitCents } = price

    const numberFlatFee = convertStringifyNumberToNumberType(flatFee)

    if (enableTimeOfDayPricing) {
      return (
        <TimeOfDayPrice
          listing={listing}
          flatFee={numberFlatFee}
          isLargeScreen={isLargeScreen}
          isSmallScreen={isSmallScreen}
          showTimeOfDayModal={showTimeOfDayModal}
          timeOfDayText={timeOfDayText}
          feeText={feeText}
        />
      )
    }

    if (numberFlatFee === 0 && pricePerUnitCents === 0) {
      return <FreeListing isLargeScreen={isLargeScreen} isSmallScreen={isSmallScreen} />
    }

    if (numberFlatFee === 0 && pricePerUnitCents > 0) {
      return (
        <div className={isLargeScreen}>
          <div className={isSmallScreen}>{`${formatCentsPrice(pricePerUnitCents)}/${isItHourOrkWh(priceType)}`}</div>
        </div>
      )
    }

    if (numberFlatFee > 0 && pricePerUnitCents === 0) {
      return <FlatFeeOnly flatFee={numberFlatFee} isLargeScreen={isLargeScreen} isSmallScreen={isSmallScreen} />
    }

    if (numberFlatFee > 0 && pricePerUnitCents > 0) {
      return (
        <PriceWithFlatFee
          pricePerUnitCents={pricePerUnitCents}
          priceType={priceType}
          flatFee={numberFlatFee}
          isLargeScreen={isLargeScreen}
          isSmallScreen={isSmallScreen}
        />
      )
    }
  }

  return (
    <div className="flex-and-gap-below">
      {parsePrice(listing, term)}
      {selectedListing && (
        <TimeOfDayModal
          title={selectedListing.title}
          priceType={isItHourOrkWh(selectedListing.price.priceType)}
          isModalOpen={!!selectedListing}
          flatFee={Number(selectedListing.price.flatFee)}
          onModalOpenClose={handleModal}
          timeOfDayPriceWeekend={selectedListing.price.timeOfDayPriceWeekend}
          timeOfDayPriceWeekday={selectedListing.price.timeOfDayPriceWeekday}
        />
      )}
      <span>{!listing || !listing.id ? null : <ListingLoiteringStatus listing={listing} />}</span>
    </div>
  )
}

export default HandlePrice
