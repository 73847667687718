import { apiMetabaseClient } from './client'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { MetabaseReportsResponse } from '../../models/reports'
import { reportsQuery } from '../../helpers/filter/reports'
import { ReportFilter } from '../../models/filter'

export async function GetMetabaseReport(
  isMockUpEnabled: boolean,
  params: ReportFilter,
): Promise<MetabaseReportsResponse> {
  let url = '/report/get-metabase-report-url'

  const queryParams = reportsQuery(params)

  url = addQueryString(url, queryParams)

  log('GetMetabaseReport', url)

  if (isMockUpEnabled) {
    url = `${url}&isMockUpEnabled=true&metabase_dashboard_id=89`
    // ! use this to avoid metabase call locally
    // return {
    //   data: [{ url: 'https://google.ca' }],
    //   code: 200,
    // }
  }

  return apiMetabaseClient(url, {})
}
