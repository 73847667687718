import { Modal, Table } from 'antd'

import { AlignedContentLeft } from 'components/wrapper'
import { renderFormatMessage } from 'helpers/intl'
import { describeSchedule } from 'helpers/schedule'

import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { useListingAccessTranslation } from 'hooks/translation/useListingAccessTranslation'

import { AccessPlanChargersPage, AccessPlanSchedule, AccessPlanType } from 'models/access-plan'

interface ChargerAccessModalProps {
  open: boolean
  listingTitle: string
  accessPlans: AccessPlanChargersPage[]
  onClose: () => void
}

const ChargerAccessModal: React.FC<ChargerAccessModalProps> = ({ open, listingTitle, accessPlans, onClose }) => {
  const { accessCodeText, emailDomainText, unrestrictedText } = useAccessPlanTranslation()
  const { privateRestrictedText } = useListingAccessTranslation()

  const renderAccessSchedules = (schedule: AccessPlanSchedule) => describeSchedule(schedule)

  const renderAccessCode = (accessCode: string) => (
    <AlignedContentLeft>
      {accessCode}
      <span className="paragraph-04-light opacity-06">{accessCodeText}</span>
    </AlignedContentLeft>
  )

  const renderEmailDomains = (emailDomains: string[]) => (
    <AlignedContentLeft>
      {emailDomains.map((email) => (
        <div key={email}>{email}</div>
      ))}
      <span className="paragraph-04-light opacity-06">{emailDomainText}</span>
    </AlignedContentLeft>
  )

  const renderAccessDetails = ({ access_code, email_domains }: AccessPlanChargersPage) => (
    <>
      {access_code && renderAccessCode(access_code)}
      <br />
      {email_domains.length > 0 && renderEmailDomains(email_domains)}
    </>
  )

  const renderAccessType = (accessType: AccessPlanType) =>
    accessType === 'restricted' ? privateRestrictedText : unrestrictedText

  return (
    <Modal
      bodyStyle={{ padding: '0' }}
      width={800}
      title={renderFormatMessage('dashboard.text.accessPlansFor', 'Access Plans for {listingTitle}', {
        listingTitle,
      })}
      visible={open}
      footer={null}
      onOk={onClose}
      onCancel={onClose}
      closable={true}
    >
      <Table
        rowKey="id"
        bordered
        pagination={false}
        dataSource={accessPlans}
        columns={[
          {
            title: 'Access Plan',
            dataIndex: 'name',
            width: '20%',
          },
          {
            title: 'Access Type',
            dataIndex: 'access_type',
            width: '15%',
            render: renderAccessType,
          },
          {
            title: 'Schedule',
            dataIndex: 'schedules',
            width: '45%',
            render: renderAccessSchedules,
          },
          {
            title: 'Access Details',
            key: 'accessDetails',
            width: '20%',
            render: renderAccessDetails,
          },
        ]}
      />
    </Modal>
  )
}

export default ChargerAccessModal
