import { Button, Form, Modal, Select } from 'antd'
import { AugmentedUser, UserPermissions, UserRole, userRole } from '../../models/user'
import { useEffect, useState } from 'react'
import { TenantRef } from '../../models/tenant'
import { FindTenants } from '../../services/data-provider/tenants'
import { SwtchError } from '../../models/error'
import { useUserTranslation } from '../../hooks/translation/useUserTranslation'
import UserBasicInfo from './modal/UserBasicInfo'
import { NewUpdateUser } from '../../services/data-provider/users'
import { AlertError } from '../../components/error'
import EditForm from './modal/user-edit-form'
import { ButtonTextSpacing, FooterLayout, Heading } from '../../atom/user-edit'
import { useAppState } from '../../state'
import { StyledDrawer } from '../../atom/drawer'
import { MobileLineContainer } from '../../atom/user-invite'
import { capitalizeFirstLetter } from '../../helpers/users'
import { HorizontalLine } from '../../atom/horizontal-line'
import { DiscountSchema } from 'models/discount'
import { ActiveDiscountForUser, AddUsersToDiscounts, RemoveUsersFromDiscounts } from 'services/data-provider/discount'
import { LocalDiscount } from 'models/price'
import { useSelector } from 'hooks/useSelector'
import { useNotifications } from 'hooks/useNotification'
import { AccessPlan, AccessPlanPayload } from 'models/access-plan'
import { GetAllAccessPlans } from 'services/data-provider/access-plan'

interface props {
  selectedUser: AugmentedUser
  user: AugmentedUser
  onCancel: (e: React.FormEvent<EventTarget>) => void
  onUserEdit: () => void
}

interface TempObj {
  [key: number]: TenantRef[]
}

export interface AccessPlanObj {
  [key: string]: AccessPlan[]
}

export const UserEditForm: React.FC<props> = ({ onCancel, selectedUser, onUserEdit }) => {
  const [form] = Form.useForm()
  const { siteLabelCleaner } = useSelector()
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [tenantInputs, setTenantInputs] = useState(0)
  const [error, setError] = useState<SwtchError>()
  const [loading, setLoading] = useState(false)
  const [tenantDropdownData, setTenantDropdownData] = useState<{ [index: number]: any }>({})
  const [userPermissions, setUserPermissions] = useState<UserPermissions[]>([])
  const [name, setName] = useState(selectedUser.name)
  const [note, setNote] = useState(selectedUser.note)
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>()
  const [disableSave, setDisableSave] = useState(true)
  const [changed, setChanged] = useState(false)
  const { editUserText, saveText, cancelText, updateText } = useUserTranslation()
  const { currentUser, IsMobile, isMockUpEnabled } = useAppState()
  const isMobile = IsMobile()
  const [idsWithOperatorRole, setIdsWithOperatorRole] = useState<string[]>([]) //id with operator role
  const [allAccessPlans, setAllAccessPlans] = useState<AccessPlanObj>({})
  const [existingDiscounts, setExistingDiscounts] = useState<LocalDiscount[]>([])
  const { openErrorNotification, openSuccessNotification } = useNotifications()

  const permissionTransform = {
    'tenant.none': 'none',
    'tenant.viewer': 'viewer',
    'tenant.manager': 'manager',
    'tenant.charger_operator': currentUser?.role === 'admin' ? 'charger_operator' : 'manager',
  }

  const determineUserRole = () => {
    if (selectedUser.isAdmin) return 'admin'
    if (selectedUser.isSupport) return 'support'
    if (selectedUser.isUser) return 'user'
  }

  useEffect(() => {
    setSelectedUserRole(determineUserRole())
    setLoading(true)
    FindTenants(false, isMockUpEnabled)
      .then(async (tenants) => {
        const tenantsArr = tenants.filter((tenant) => tenant.combineName)
        setTenants(tenantsArr)

        const currentDiscount = await ActiveDiscountForUser(selectedUser.email, isMockUpEnabled)
        const parsedDiscount = currentDiscount.map(({ id, name }) => ({ id, name }))
        setExistingDiscounts(parsedDiscount)

        await handleUserAccess(tenantsArr, currentDiscount)
      })
      .catch((err: SwtchError) => {
        setTenants([])
        openErrorNotification(err.description)
      })
  }, [])

  useEffect(() => {
    const role = selectedUser.isAdmin ? 'admin' : selectedUser.isSupport ? 'support' : selectedUser.isUser ? 'user' : ''

    const canClickSaveButton =
      name !== selectedUser.name || note !== selectedUser.note || role !== selectedUserRole || changed

    if (canClickSaveButton) {
      setDisableSave(false)
    } else {
      setDisableSave(true)
    }
  }, [name, note, selectedUserRole, changed])

  const handleUserAccess = async (tenantData: TenantRef[], tenantDiscount: DiscountSchema[]) => {
    let objArr: any[] = []
    let match = 0
    let input = 0
    let tempObj: TempObj = {}
    let idWithOperatorRole: string[] = []
    selectedUser.accesses.forEach((access) => {
      if (access.resourceType === 'AccessPlan') return
      tenantData.forEach((tenant) => {
        if (tenant.id === access.resourceId) {
          match += 1
          input += 1
          tempObj[input] = [...tenantData]
          let driver = false
          let roles = 'none'

          access.permissions.forEach((permission) => {
            if (permission === 'tenant.driver') {
              driver = true
            } else {
              roles = getUpdatedRole(permission, roles) // Update roles with permissions
            }
            if (permission === 'tenant.charger_operator') {
              idWithOperatorRole.push(tenant.id)
            }
          })
          const tenantName = siteLabelCleaner(
            `${access.displayName ? `${access.displayName} ` : ''}(${access.display || ''})`,
          )
          // Get all access plans for this tenant
          const accessPlansForTenant = selectedUser.accessPlans.filter((plan) => plan.tenant_id === Number(tenant.id))

          // Use addOrUpdateTenantEntry to manage objArr entries
          addOrUpdateTenantEntry(objArr, tenant, tenantName, roles, driver, accessPlansForTenant)

          setTenantInputs(match)
          setTenantDropdownData({ ...tempObj })
        }
      })
    })
    tenantDiscount.forEach((discount) => {
      const discountTenantId = discount.tenantId
      const existingTenantIndex = objArr.findIndex((obj: any) => obj.id === discountTenantId)
      if (existingTenantIndex > -1) {
        objArr[existingTenantIndex] = {
          ...objArr[existingTenantIndex],
          discountPlan: [...(objArr[existingTenantIndex]?.discountPlan ?? []), discount],
        }
      } else {
        const newTenantData = tenantData.find((tenant) => parseFloat(tenant.id) === discountTenantId)
        const updatedTenantDataIndex = objArr.length + 1
        if (newTenantData) {
          tempObj[updatedTenantDataIndex] = [...tenantData]
          setTenantInputs(updatedTenantDataIndex)
          setTenantDropdownData({ ...tempObj })
        }
        const accessPlansForTenant = selectedUser.accessPlans.filter((plan) => plan.tenant_id === discountTenantId)

        objArr.push({
          access: 'none',
          driver: false,
          id: discountTenantId,
          discountPlan: [discount],
          name: newTenantData?.combineName,
          accessPlan: [...accessPlansForTenant],
        })
      }
    })

    // Ensure all access plans are accounted for
    selectedUser.accessPlans.forEach((accessPlan) => {
      const accessPlanTenantId = accessPlan.tenant_id
      const existingTenantIndex = objArr.findIndex((obj: any) => obj.id === accessPlanTenantId)
      if (existingTenantIndex === -1) {
        const newTenantData = tenantData.find((tenant) => parseFloat(tenant.id) === accessPlanTenantId)
        const updatedTenantDataIndex = objArr.length + 1

        if (newTenantData) {
          tempObj[updatedTenantDataIndex] = [...tenantData]
          setTenantInputs(updatedTenantDataIndex)
          setTenantDropdownData({ ...tempObj })
        }

        objArr.push({
          access: 'none',
          driver: false,
          id: accessPlanTenantId,
          discountPlan: [],
          name: newTenantData?.combineName,
          accessPlan: [accessPlan],
        })
      } else {
        // Append the access plan if tenant exists and is not already included
        const alreadyExists = objArr[existingTenantIndex].accessPlan.some(
          (plan: AccessPlanPayload) => plan.id === accessPlan.id,
        )
        if (!alreadyExists) objArr[existingTenantIndex].accessPlan.push(accessPlan)
      }
    })
    // Final state updates
    setIdsWithOperatorRole(idWithOperatorRole)
    setUserPermissions(objArr)
    setLoading(false)
  }

  const fetchAllAccessPlansForTenants = async () => {
    const uniqueTenantIds = Array.from(
      new Set([
        ...(selectedUser?.accesses || [])
          .map(({ resourceId, resourceType }) => (resourceType === 'Tenant' ? resourceId : ''))
          .filter((val) => !!val),
        ...(selectedUser?.accessPlans || []).map(({ tenant_id }) => tenant_id),
      ]),
    )
    try {
      // Fetch all access plans concurrently
      const responses = await Promise.all(
        uniqueTenantIds.map(async (tenantId) => {
          const resp = await GetAllAccessPlans(isMockUpEnabled, Number(tenantId))
          return { tenantId, resp }
        }),
      )

      // Filter out responses with errors and create the new state object
      const accessPlans = responses.reduce<AccessPlanObj>((acc, { tenantId, resp }) => {
        if (!('error' in resp)) {
          acc[tenantId] = resp
        }
        return acc
      }, {})

      // Update state once with the combined access plans
      setAllAccessPlans((prev) => ({ ...prev, ...accessPlans }))
    } catch (error) {
      console.error('Failed to fetch access plans:', error)
    }
  }

  useEffect(() => {
    if (!selectedUser) return
    fetchAllAccessPlansForTenants()
  }, [selectedUser])

  // Helper function to update roles based on permissions
  const getUpdatedRole = (permission: string, currentRole: string): string => {
    const role = permissionTransform[permission as keyof typeof permissionTransform]
    if (currentRole === 'none') return role
    if (currentRole === 'viewer' && role !== 'none') return role
    if (currentRole === 'manager' && role !== 'none' && role !== 'viewer') return role
    return currentRole
  }

  // Helper function to add or update tenant entries in objArr
  const addOrUpdateTenantEntry = (
    objArr: any[], // Assuming TenantObj is your type for tenant entries in objArr
    tenant: TenantRef,
    tenantName: string,
    roles: string,
    driver: boolean,
    accessPlansForTenant: AccessPlanPayload[],
  ) => {
    // Find the existing tenant by ID
    const existingTenantIndex = objArr.findIndex((obj) => obj.id === tenant.id)

    if (existingTenantIndex === -1) {
      // If tenant is not in objArr, create a new entry
      objArr.push({
        name: tenantName,
        id: tenant.id,
        access: roles,
        driver: driver,
        accessPlan: [...accessPlansForTenant], // Start with provided access plans
      })
    } else {
      // If tenant exists, update the entry
      const existingTenant = objArr[existingTenantIndex]

      // Update roles if necessary (using getUpdatedRole function or similar logic)
      existingTenant.access = getUpdatedRole(roles, existingTenant.access)

      // Update driver status if applicable
      existingTenant.driver = existingTenant.driver || driver

      // Merge access plans, ensuring no duplicates
      const updatedAccessPlans = [
        ...existingTenant.accessPlan,
        ...accessPlansForTenant.filter(
          (plan) => !existingTenant.accessPlan.some((existingPlan: AccessPlanPayload) => existingPlan.id === plan.id),
        ),
      ]

      existingTenant.accessPlan = updatedAccessPlans
    }
  }

  const handleUserRoleChange = (role: UserRole) => {
    setSelectedUserRole(role)
  }

  const afterClose = () => {
    form.resetFields()
  }
  const onOk = () => {
    let updateName = ''
    let updateNote = ''
    if (selectedUser.name !== name) updateName = name
    if (selectedUser.note !== note) updateNote = note
    const userToUpdate = {
      id: selectedUser.id,
      email: selectedUser.email,
      name: updateName,
      note: updateNote,
      role: selectedUserRole,
    }

    setLoading(true)
    const updatedUserPermissions = [...userPermissions]

    const updatedDiscounts: LocalDiscount[] = []

    userPermissions.forEach((user, index) => {
      //If the user is not admin and didn't update the tenant with operator role
      if (currentUser?.role !== 'admin' && idsWithOperatorRole.includes(user.id) && user.access === 'manager') {
        updatedUserPermissions.splice(index, 1, { ...user, access: 'charger_operator' })
      }

      user.discountPlan?.forEach((discount) => {
        updatedDiscounts.push({ id: discount.id, name: discount.name })
      })
    })

    const currentUserArray = [{ email: selectedUser?.email || '' }]

    if (existingDiscounts.length && updatedDiscounts.length) {
      RemoveUsersFromDiscounts(isMockUpEnabled, existingDiscounts, currentUserArray)
        .then((res) => {
          AddUsersToDiscounts(isMockUpEnabled, updatedDiscounts, currentUserArray)
            .then((res) => console.log('res', res))
            .catch((err) => setError(err))
        })
        .catch((err) => setError(err))
    } else if (existingDiscounts.length) {
      RemoveUsersFromDiscounts(isMockUpEnabled, existingDiscounts, currentUserArray)
        .then((res) => console.log('res', res))
        .catch((err) => setError(err))
    } else if (updatedDiscounts.length) {
      AddUsersToDiscounts(isMockUpEnabled, updatedDiscounts, currentUserArray)
        .then((res) => console.log('res', res))
        .catch((err) => setError(err))
    }

    const accessPlanIds = updatedUserPermissions
      .flatMap((item) => item.accessPlan?.map((plan) => plan.id))
      .filter((id): id is number => id !== undefined)

    NewUpdateUser(isMockUpEnabled, updatedUserPermissions, accessPlanIds, userToUpdate)
      .then((resp) => {
        openSuccessNotification('Updated info')
        return onUserEdit()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <AlertError error={error} />
      {!isMobile ? (
        <Modal
          title={<Heading>{editUserText}</Heading>}
          closable={true}
          okText={updateText}
          cancelText={cancelText}
          visible={true}
          onCancel={onCancel}
          onOk={onOk}
          afterClose={afterClose}
          width={543}
          className="fixed-row-modal"
          footer={
            <FooterLayout>
              <Button style={{ width: '240px' }} loading={loading} onClick={onCancel}>
                <span className="paragraph-02-regular">{cancelText}</span>
              </Button>
              <Button style={{ width: '240px' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
                <ButtonTextSpacing disabled={disableSave}>
                  <span className="paragraph-02-regular">{saveText}</span>
                </ButtonTextSpacing>
              </Button>
            </FooterLayout>
          }
        >
          {currentUser?.role === 'admin' && (
            <>
              <Select
                style={{ width: '100%' }}
                value={selectedUserRole}
                onChange={(value) => handleUserRoleChange(value)}
              >
                {userRole.map((role) => (
                  <Select.Option value={role}>{capitalizeFirstLetter(role)}</Select.Option>
                ))}
              </Select>
              <HorizontalLine />
            </>
          )}
          <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
          <EditForm
            tenantInputs={tenantInputs}
            tenants={tenants}
            tenantDropdownData={tenantDropdownData}
            userPermissions={userPermissions}
            loading={loading}
            allAccessPlans={allAccessPlans}
            setTenantInputs={setTenantInputs}
            setTenantDropdownData={setTenantDropdownData}
            setUserPermissions={setUserPermissions}
            setChanged={setChanged}
            setAllAccessPlans={setAllAccessPlans}
          />
        </Modal>
      ) : (
        <StyledDrawer
          placement="bottom"
          closable={false}
          onClose={onCancel}
          visible={true}
          key="bottom"
          footer={
            <Button style={{ width: '100%' }} type="primary" loading={loading} onClick={onOk} disabled={disableSave}>
              <ButtonTextSpacing disabled={disableSave}>
                <span className="paragraph-02-regular">{saveText}</span>
              </ButtonTextSpacing>
            </Button>
          }
          footerStyle={{ boxShadow: '0px -3px 5px 0px rgba(0, 0, 0, 0.06)', padding: '25px 15px' }}
        >
          <MobileLineContainer />
          <Heading>{editUserText}</Heading>
          <HorizontalLine />
          {currentUser?.role === 'admin' && (
            <>
              <Select
                style={{ width: '100%' }}
                value={selectedUserRole}
                onChange={(value) => handleUserRoleChange(value)}
              >
                {userRole.map((role) => (
                  <Select.Option value={role}>{capitalizeFirstLetter(role)}</Select.Option>
                ))}
              </Select>
              <HorizontalLine />
            </>
          )}
          <UserBasicInfo editUser={selectedUser} note={note} name={name} setName={setName} setNote={setNote} />
          <EditForm
            tenantInputs={tenantInputs}
            tenants={tenants}
            tenantDropdownData={tenantDropdownData}
            userPermissions={userPermissions}
            loading={loading}
            allAccessPlans={allAccessPlans}
            setTenantInputs={setTenantInputs}
            setTenantDropdownData={setTenantDropdownData}
            setUserPermissions={setUserPermissions}
            setChanged={setChanged}
            setAllAccessPlans={setAllAccessPlans}
          />
        </StyledDrawer>
      )}
    </>
  )
}
