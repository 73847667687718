import moment from 'moment'

import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { calculateTransactionMetrics, mockTransactionResp, mockTransactions } from '../../mock/transaction-mock'
import { TransactionMetric } from '../../models/analytics'
import { ChargerRef } from '../../models/charger'
import { Connector } from '../../models/connector'
import { TransactionResponse } from '../../models/http'
import { ListingRef } from '../../models/listing'
import { TenantRef } from '../../models/tenant'
import {
  Transaction,
  TransactionStartMethod,
  TransactionStateAlias,
  TransactionStateV1ToV2,
} from '../../models/transaction'
import { apiClient } from './client'
import { returnMock } from './mock'
import { MasterAccountRef } from 'models/master-account'

export interface TransactionFilter {
  page?: number
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  charger?: ChargerRef
  chargers?: string[]
  serialNumber?: string[]
  connector?: Connector
  startMethod?: TransactionStartMethod
  state?: TransactionStateV1ToV2
  transactionId?: string | number
  listing?: ListingRef
  userName?: string
  userEmail?: string
  email?: string
  ocppTransactionId?: string
  listingTitle?: string[]
  masterAccounts?: MasterAccountRef[]
}

const filterToQueryParams = (
  f: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start: `${startDate.format('DD-MM-YYYY')}`,
    end: `${endDate.format('DD-MM-YYYY')}`,
  }
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.defaultTenant) {
    queryParams['tenant_id'] = `${f.defaultTenant.id}`
  }

  if (f.tenants && f.tenants.length > 0) {
    queryParams['tenant_id'] = `${f.tenants.map((t) => t.id)}`
  }
  if (f.charger) {
    queryParams['charger_id'] = `${f.charger.id}`
  }
  if (f.connector) {
    queryParams['connector_id'] = `${f.connector.id}`
  }
  if (f.startMethod) {
    queryParams['start_method'] = `${f.startMethod}`
  }
  switch (f.state) {
    case 'completed':
      queryParams['state'] = `${TransactionStateAlias['completed']}`
      break
    case 'ongoing':
      queryParams['state'] = `${TransactionStateAlias['ongoing']}`
      break
    case undefined:
      break
    default:
      queryParams['state'] = `${f.state}`
  }
  if (f.transactionId) {
    queryParams['transaction_id'] = `${f.transactionId}`
  }
  if (f.listing) {
    queryParams['listing_id'] = `${f.listing.id}`
  }
  if (f.userName) {
    queryParams['user_name'] = `${f.userName}`
  }
  if (f.userEmail || f.email) {
    queryParams['user_email'] = `${f.userEmail || f.email}`
  }
  if (f.ocppTransactionId) {
    queryParams['ocpp_transaction_id'] = `${f.ocppTransactionId}`
  }
  if (f.listingTitle && f.listingTitle.length > 0) {
    queryParams['listing_title'] = `${f.listingTitle.map((listingTitle) => listingTitle)}`
  }
  if (f.serialNumber && f.serialNumber.length > 0) {
    queryParams['serial_number'] = `${f.serialNumber.map((serial) => serial)}`
  }
  if (f.chargers && f.chargers.length > 0) {
    queryParams['serial_number'] = `${f.chargers.map((serialNumber) => serialNumber)}`
  }
  if (f.masterAccounts && f.masterAccounts.length > 0) {
    queryParams['master_account_ids'] = `${f.masterAccounts.map((masterAccount) => masterAccount.id)}`
  }

  return queryParams
}

export async function GetTransactions(
  isMockUpEnabled: boolean,
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): Promise<TransactionResponse> {
  let url = '/transactions'

  const queryParams = filterToQueryParams({ ...props }, startDate, endDate)
  url = addQueryString(url, queryParams)

  log('fetching transactions', { url })

  if (isMockUpEnabled) {
    return returnMock(mockTransactionResp(props))
  }
  return apiClient(url, {})
}

export async function GetTransaction(transactionId: number): Promise<Transaction> {
  const url = `/transactions/${transactionId}`
  log('fetching a transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    const index = mockTransactions.findIndex((mockTransaction) => {
      return mockTransaction.id === transactionId
    })
    return returnMock({ ...mockTransactions[index] })
  }
  return apiClient(url, {})
}

export async function GetTransactionsStats(
  isMockUpEnabled: boolean,
  props: TransactionFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): Promise<TransactionMetric> {
  let url = '/transactions/total'

  const queryParams = filterToQueryParams({ ...props }, startDate, endDate)
  url = addQueryString(url, queryParams)
  log('fetching transactions total', { url })
  if (isMockUpEnabled) {
    return returnMock(calculateTransactionMetrics(mockTransactionResp(props)))
  }
  return apiClient(url, {})
}

export async function StartTransaction(transaction: Transaction): Promise<Transaction> {
  const url = `/transactions/${transaction.id}/start`
  log('remote start transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...mockTransactions[0], id: transaction.id, currentState: 'no_reservation' })
  }
  return apiClient(url, { method: 'POST' })
}

export async function ErrorTransaction(transaction: Transaction): Promise<Transaction> {
  const url = `/transactions/${transaction.id}/error`
  log('remote start transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...mockTransactions[0], id: transaction.id, currentState: 'errored' })
  }
  return apiClient(url, { method: 'POST' })
}

export async function StopTransaction(transaction: Transaction): Promise<Transaction> {
  const url = `/transactions/${transaction.id}/stop`
  log('remote stop transaction', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock({ ...mockTransactions[0], id: transaction.id, currentState: 'confirmed' })
  }
  return apiClient(url, { method: 'POST' })
}
