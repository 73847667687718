import {
  //DeleteOutlined, EditOutlined,
  DownCircleOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons'
import { ConfigProvider, Empty, Row, Table } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { TenantRef } from 'models/tenant'
import { useEffect, useState } from 'react'
import { peakShavingInfoSVG } from '../../../assets/svg/peak-shaving-info-icon'
import { AlertError } from '../../../components/error'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchError } from '../../../models/error'
import { PeakShavingParticipantsFilter } from '../../../models/filter'
import { PaginationMeta } from '../../../models/pagination'
import {
  PeakShavingParticipants,
  PeakShavingParticipantsListings,
  PeakShavingParticipantsWithIndex,
} from '../../../models/peak-shaving'
import { GetAllParticipantsForProgram } from '../../../services/data-provider/peak-shaving'
import { styled, theme } from '../../../theme'
import { RemoveFromProgramModal } from '../modal/remove-participant-from-program-modal'
import ListingTitle from 'features/listings/functions/listing-title'
import ListingChargerAndConnector from 'features/listings/functions/charger'

interface props {
  programId: number
  publishedButtonDisabled: (isDisabled: boolean) => void
  setParticipantToEdit: (val: Store) => void
  programListingsTerm: string
}

const ProgramListingsTableContainer = styled.div<{ isEmpty: boolean }>`
  .ant-table-thead .ant-table-cell {
    font-weight: 700;
    font-size: 13px;
    line-height: 15.6px;
    color: ${(props) => props.theme.colors.softBlack};
  }

  .ant-table-tbody > tr > td {
    padding: 3.5px 16px;
    height: 68.75px;
  }

  .ant-empty-image {
    height: auto;
    margin: 0px;
  }
  .ant-table-wrapper {
    background: ${(props) => props.theme.colors.white};
    box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 17px 0px;
    border-radius: 5px;
  }

  table {
    min-height: ${(props) => (props.isEmpty ? '179px' : 'auto')};
  }

  .charging-0 {
    color: ${(props) => props.theme.colors.dangerPrimary};
  }
  .charging-20,
  .charging-40,
  .charging-60,
  .charging-80 {
    color: ${(props) => props.theme.colors.orangePeel};
  }
  .charging-100 {
    color: ${(props) => props.theme.colors.neonGreen};
  }
`

const PeakShavingProgramListingOutputContainer = styled.div`
  margin-top: 10px;
  .paragraph-02-regular {
    margin-bottom: 7px;
  }
  .paragraph-04-light {
    opacity: 0.6;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    font-size: 20px;
  }
`

export const ProgramListingsTable: React.FC<props> = ({
  programId,
  publishedButtonDisabled,
  //setParticipantToEdit, TODO: this will be added once edit participant api is done
  programListingsTerm,
}) => {
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [filter, setFilter] = useState<PeakShavingParticipantsFilter>({ page: 1, perPage: 20 })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [removePariticpantFromProgramModalVisible, setRemoveFromProgramModalVisible] = useState(false)
  const [participants, setParticipants] = useState<PeakShavingParticipants[]>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [participantToRemove, setParticipantToRemove] = useState<TenantRef | undefined>(undefined)

  const handleRemoveFromProgramModal = () => setRemoveFromProgramModalVisible(!removePariticpantFromProgramModalVisible)

  const onRemoveFromProgram = () => {
    handleRemoveFromProgramModal()
    loadPariticpantsInfo()
  }
  // TODO: this will be added once delete tenant from participant api is done
  // const onSelectParticipantToRemove = (tenant: TenantRef) => () => {
  //   setParticipantToRemove(tenant)
  //   handleRemoveFromProgramModal()
  // }

  const onExpand = (expanded: boolean, record: PeakShavingParticipantsWithIndex) => {
    setExpandedRowKeys(
      expanded ? [...expandedRowKeys, `${record.index}`] : expandedRowKeys.filter((key) => key !== `${record.index}`),
    )
  }

  useEffect(() => {
    if (!removePariticpantFromProgramModalVisible) {
      setParticipantToRemove(undefined)
    }
  }, [removePariticpantFromProgramModalVisible])

  useEffect(() => {
    participants && participants.length > 0 ? publishedButtonDisabled(false) : publishedButtonDisabled(true)
  }, [participants])

  const {
    listingText,
    tenantText,
    serialNumberText,
    outputText,
    participantInfoText,
    participantsText,
    participantIDText,
    allListingsText,
    selectedListingsText,
    mixedText,
  } = usePeakShavingTranslation()

  const participantsColumns = [
    {
      title: tenantText,
      dataIndex: 'tenant',
      render: (tenant: TenantRef) => (
        <Row justify="space-between">
          {tenant.name}
          {/*
          TODO: this will be added once delete tenant from participant api is done
          <DeleteOutlined style={{ fontSize: '20px' }} onClick={onSelectParticipantToRemove(tenant)} /> */}
        </Row>
      ),
      width: '33%',
    },
    {
      title: participantsText,
      dataIndex: 'allListings',
      render: (allListings: boolean) => (allListings ? allListingsText : selectedListingsText),
      width: '33%',
    },
    {
      title: participantIDText,
      render: (participant: PeakShavingParticipants) =>
        participant.allListings ? participant?.listings[0]?.participantId : mixedText,
    },
    Table.EXPAND_COLUMN,
  ]

  const listingColumns = [
    {
      title: listingText,
      render: (listing: PeakShavingParticipantsListings) => <ListingTitle listing={listing} />,
    },
    {
      title: serialNumberText,
      render: (listing: PeakShavingParticipantsListings) =>
        listing.charger || listing.connector ? (
          <ListingChargerAndConnector charger={listing?.charger} connector={listing?.connector} hideAdditionalInfo />
        ) : null,
    },
    {
      title: outputText,
      render: ({ charger }: PeakShavingParticipantsListings) => {
        return (
          <PeakShavingProgramListingOutputContainer>
            {charger && (
              <>
                <p className="paragraph-02-regular">{charger?.chargingType}</p>
                <p className="paragraph-04-light">{charger?.maxWatt ? `${charger.maxWatt / 1000} kW` : ''}</p>
              </>
            )}
          </PeakShavingProgramListingOutputContainer>
        )
      },
    },
    {
      title: participantIDText,
      dataIndex: 'participantId',
    },
  ]

  const loadPariticpantsInfo = () => {
    setLoading(true)
    GetAllParticipantsForProgram(filter, programId)
      .then(({ data, pagination }) => {
        setParticipants(data)
        setPagination(pagination)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadPariticpantsInfo()
  }, [programId, filter, programListingsTerm])

  const onPaginationChange = (page: number) => {
    setFilter({ ...filter, page })
  }

  return (
    <ProgramListingsTableContainer isEmpty={participants.length === 0}>
      <RemoveFromProgramModal
        visible={removePariticpantFromProgramModalVisible}
        onCancel={handleRemoveFromProgramModal}
        onSubmit={onRemoveFromProgram}
        tenant={participantToRemove}
        programId={programId}
      />
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={peakShavingInfoSVG}
            description={
              <p className="paragraph-02-regular" style={{ color: theme.colors.darkGray }}>
                {participantInfoText}
              </p>
            }
          />
        )}
      >
        <AlertError error={error} />
        <Table
          columns={participantsColumns}
          dataSource={participants.map((participant, index) => ({ ...participant, index }))}
          loading={loading}
          rowKey={(participant: PeakShavingParticipantsWithIndex) => `${participant.index}`}
          expandable={{
            onExpand,
            expandedRowKeys,
            expandedRowRender: (selectedData: PeakShavingParticipantsWithIndex) => (
              <Table
                columns={listingColumns}
                dataSource={selectedData.listings}
                loading={loading}
                rowKey={(listing: PeakShavingParticipantsListings) => listing.id}
                pagination={false}
                className="nested-table"
              />
            ),
            expandIcon: ({ expandable, expanded, record, onExpand }) => (
              <IconContainer>
                {/*
                TODO: this will be added once edit participant api is done
                <EditOutlined
                  onClick={() =>
                    setParticipantToEdit({
                      ...record,
                      allListings: record.allListings ? 'allListings' : 'selectListings',
                    })
                  }
                /> */}
                {expandable ? (
                  expanded ? (
                    <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <LeftCircleOutlined onClick={(e) => onExpand(record, e)} />
                  )
                ) : undefined}
              </IconContainer>
            ),
          }}
          pagination={{
            position: ['bottomCenter'],
            total: pagination?.totalEntries,
            current: pagination?.currentPage,
            pageSize: pagination?.perPage,
            showSizeChanger: false,
            onChange: onPaginationChange,
          }}
        />
      </ConfigProvider>
    </ProgramListingsTableContainer>
  )
}
