import { AccessPlan, AccessPlanChargersPage } from 'models/access-plan'
import { mockAccessPlanSchedule } from './access-plan-schedule-mock'
import { mockListing } from './listing-mock'
import { ListingAccessPlan } from 'models/listing'

export const mockAccessPlanCommonProperties = {
  hasOperationHours: true,
  enableAccessCode: true,
  enableEmailDomains: true,
  accessCode: 'qwerty',
  driverEmails: ['bbb.com', 'bbb.com'],
}

export const mockAccessPlanRefs: { [key: string]: ListingAccessPlan } = {
  restrictedWithEverydaySchedule: {
    id: 1,
    name: 'Private Chargers 1',
    accessType: 'restricted',
    accessCode: 'qwerty',
    emailDomains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.everydayBetweenStartAndEndDate,
  },
  unrestrictedWithCustomSchedule: {
    id: 2,
    name: 'Public Chargers 1',
    accessType: 'unrestricted',
    accessCode: '456DEF',
    emailDomains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.customSchedule,
  },
  restrictedWithWeekdayAndWeekendSchedule: {
    id: 3,
    name: 'Private Chargers 2',
    accessType: 'restricted',
    accessCode: '456DEF',
    emailDomains: ['swtchenergy.com'],
    schedules: mockAccessPlanSchedule.weekdayAndWeekend,
  },
  restrictedWith24_7Schedule: {
    id: 4,
    name: 'Private Chargers 3',
    accessType: 'restricted',
    accessCode: '456DEF',
    emailDomains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.twentyfour_seven,
  },
  unrestrictedWithEverydaySchedule: {
    id: 5,
    name: 'Public Chargers 2',
    accessType: 'unrestricted',
    accessCode: '456DEF',
    emailDomains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.everydayBetweenStartAndEndDate,
  },
  unrestrictedWithWeekdayAndAllDayWeekendSchedule: {
    id: 6,
    name: 'Public Chargers 3',
    accessType: 'unrestricted',
    accessCode: '456DEF',
    emailDomains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.weekdayAndAllDayWeekend,
  },
}

export const mockAccessPlans = [
  {
    id: 1,
    name: 'another access plan name 14',
    tenantId: 1,
    listingIds: [mockListing['L6652'].id],
    listings: [mockListing['L6652']],
    accessType: 'unrestricted',
    emailDomains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.customSchedule,
    ...mockAccessPlanCommonProperties,
  },
  {
    id: 2,
    name: 'another access plan name 15',
    tenantId: 1,
    listingIds: [mockListing['L6653'].id],
    listings: [mockListing['L6653']],
    accessType: 'unrestricted',
    emailDomains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.everydayBetweenStartAndEndDate,
    ...mockAccessPlanCommonProperties,
    driverEmails: ['swtchenergy.com'],
  },
  {
    id: 3,
    name: 'another access plan name 16',
    tenantId: 1,
    listingIds: [mockListing['L6654'].id],
    listings: [mockListing['L6654']],
    accessType: 'unrestricted',
    emailDomains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.weekdayAndWeekend,
    ...mockAccessPlanCommonProperties,
    driverEmails: ['swtchenergy.com'],
  },
  {
    id: 4,
    name: 'another access plan name 17',
    tenantId: 1,
    listingIds: [mockListing['L6654'].id],
    listings: [mockListing['L6654']],
    accessType: 'unrestricted',
    emailDomains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.twentyfour_seven,
    ...mockAccessPlanCommonProperties,
    driverEmails: ['swtchenergy.com'],
  },
] as AccessPlan[]

export const mockAccessPlanResponse = {
  data: mockAccessPlans,
  pagination: {
    currentPage: 1,
    totalEntries: mockAccessPlans.length,
    perPage: 10,
  },
}

export const mockAccessPlanRefsChargersPage: { [key: string]: AccessPlanChargersPage } = {
  restrictedWithMondaySchedule: {
    id: 0,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Private Chargers 0',
    access_type: 'restricted',
    access_code: 'qwerty',
    email_domains: ['gmail.com'],
    schedules: mockAccessPlanSchedule.onlyMonday,
  },
  restrictedWithEverydaySchedule: {
    id: 1,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Private Chargers 1',
    access_type: 'restricted',
    access_code: 'qwerty',
    email_domains: [],
    schedules: mockAccessPlanSchedule.everydayBetweenStartAndEndDate,
  },
  unrestrictedWithCustomSchedule: {
    id: 2,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Public Chargers 1',
    access_type: 'unrestricted',
    access_code: '456DEF',
    email_domains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.customSchedule,
  },
  restrictedWithWeekdayAndWeekendSchedule: {
    id: 3,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Private Chargers 2',
    access_type: 'restricted',
    access_code: '456DEF',
    email_domains: ['swtchenergy.com'],
    schedules: mockAccessPlanSchedule.weekdayAndWeekend,
  },
  restrictedWith24_7Schedule: {
    id: 4,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Private Chargers 3',
    access_type: 'restricted',
    access_code: '456DEF',
    email_domains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.twentyfour_seven,
  },
  unrestrictedWithEverydaySchedule: {
    id: 5,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Public Chargers 2',
    access_type: 'unrestricted',
    access_code: '456DEF',
    email_domains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.everydayBetweenStartAndEndDate,
  },
  unrestrictedWithWeekdayAndAllDayWeekendSchedule: {
    id: 6,
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    name: 'Public Chargers 3',
    access_type: 'unrestricted',
    access_code: '456DEF',
    email_domains: ['swtchenergy.com', 'gmail.com'],
    schedules: mockAccessPlanSchedule.weekdayAndAllDayWeekend,
  },
}

export const mockAccessPlansForChargersPage = [
  {
    id: 1,
    name: 'another access plan name 14',
    tenant_id: 1,
    listing_ids: [mockListing['L6652'].id],
    access_type: 'unrestricted',
    email_domains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.customSchedule,
    access_code: '123123',
  },
  {
    id: 2,
    name: 'another access plan name 15',
    tenant_id: 1,
    listing_ids: [mockListing['L6653'].id],
    access_type: 'unrestricted',
    email_domains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.everydayBetweenStartAndEndDate,
    access_code: '123123',
  },
  {
    id: 3,
    name: 'another access plan name 16',
    tenant_id: 1,
    listing_ids: [mockListing['L6654'].id],
    access_type: 'unrestricted',
    email_domains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.weekdayAndWeekend,
    access_code: '123123',
  },
  {
    id: 4,
    name: 'another access plan name 17',
    tenant_id: 1,
    listing_ids: [mockListing['L6654'].id],
    access_type: 'unrestricted',
    email_domains: ['swtchenergy.com', 'amazon.com'],
    schedules: mockAccessPlanSchedule.twentyfour_seven,
    access_code: '123123',
  },
] as AccessPlanChargersPage[]

export const mockAccessPlanNames = ['Plan1', 'Plan2', 'Plan3', 'Plan4', 'Plan5', 'Plan6', 'Plan7', 'Plan8', 'Plan9']
