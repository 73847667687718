import { formatCentsPrice } from 'helpers/number-format'
import { Price } from 'models/price'

export const iskWhOrHour = (priceType: string, price: string) => (priceType === 'kWh' ? `${price}/kWh` : `${price}/hr`)

export const handlePreferredPrice = (price: Price) => {
  if (price.preferedPricePerUnitCents && price.preferedPricePerUnitCents > 0) {
    const formattedPrice = formatCentsPrice(price.preferedPricePerUnitCents)
    return iskWhOrHour(price.preferedPricingPriceType, formattedPrice)
  }
  return '---'
}

export const isItHourOrkWh = (priceType: string) => (priceType === 'Hour' ? 'hr' : 'kWh')
