import { Button, Modal } from 'antd'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { ConflictingPlanCustomFooterLayout } from 'atom/chargers'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { renderFormatMessage } from 'helpers/intl'
import { ConflictingPlan } from 'models/access-plan'
import { describeSchedule } from 'helpers/schedule'
import { theme } from 'theme'
import { ErrorTitleSectionModal } from './error-title-section'

interface props {
  visible: boolean
  conflictingData?: ConflictingPlan[]
  onCancel: () => void
  setVisible: (visible: boolean) => void
  updateAndSave: (setVisible: (visible: boolean) => void) => void
}

export const ConflictingAccessPlanModal: React.FC<props> = ({
  visible,
  conflictingData,
  onCancel,
  setVisible,
  updateAndSave,
}) => {
  const {
    accessPlanNameText,
    conflictingAccessPlanText,
    moveChargerText,
    chargersOverlapText,
    moveChargersText,
  } = useAccessPlanTranslation()
  const { cancel } = useListingBasicTrans()

  const renderConflictMessage = () => {
    if (conflictingData?.length === 1) {
      const listingNames = conflictingData[0].listing_names.join(', ')
      return renderFormatMessage(
        'dashboard.accessPlan.chargerInUse',
        'Charger {id} is already in another access plan with a conflicting schedule',
        { id: listingNames },
      )
    }
    return chargersOverlapText
  }

  const renderConflictDetails = () => {
    if (conflictingData?.length === 1) {
      const { name, existing_schedule } = conflictingData[0]
      return (
        <>
          <p className="paragraph-03-regular" style={{ marginBottom: '16px' }}>
            {accessPlanNameText}: {name}
          </p>
          <div className="paragraph-03-regular">{describeSchedule(existing_schedule)}</div>
        </>
      )
    }
    return conflictingData?.map(({ name, listing_names }, index) => (
      <p
        className="paragraph-03-regular"
        style={{ marginBottom: index === conflictingData.length - 1 ? '0px' : '16px' }}
        key={name}
      >
        {name}: {listing_names.join(', ')}
      </p>
    ))
  }

  const renderActionMessage = () => {
    if (conflictingData?.length === 1) {
      const { name, listing_names } = conflictingData[0]
      return renderFormatMessage(
        'dashboard.accessPlan.moveChargerSched',
        'Move {id} from {name}, or go back and adjust the schedule.',
        { id: listing_names.join(', '), name },
      )
    }
    return moveChargersText
  }

  return (
    <Modal visible={visible} onCancel={onCancel} width={450} footer={null} closable={false} centered={true}>
      <ErrorTitleSectionModal text={conflictingAccessPlanText} />
      <br />
      <div className="paragraph-01-regular">{renderConflictMessage()}</div>
      <div
        style={{
          background: theme.colors.cultered,
          padding: '12px',
          marginTop: '8px',
          marginBottom: '16px',
          borderRadius: '8px',
        }}
      >
        {renderConflictDetails()}
      </div>
      <div className="paragraph-01-regular">{renderActionMessage()}</div>
      <ConflictingPlanCustomFooterLayout>
        <Button type="ghost" className="half-width-button" onClick={onCancel}>
          {cancel}
        </Button>
        <Button
          className="ant-btn-default ant-btn-dangerous half-width-button"
          type="primary"
          onClick={() => updateAndSave(setVisible)}
          style={{ marginLeft: '12px' }}
        >
          {moveChargerText}
        </Button>
      </ConflictingPlanCustomFooterLayout>
    </Modal>
  )
}
