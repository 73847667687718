import { useState } from 'react'
import { timeConvert } from '../../../helpers/duration'
import { formatCentsPrice, formatPrice } from '../../../helpers/number-format'
import { NewListing } from '../../../models/listing'
import { Loitering } from '../../../models/loitering'
import { useAppState } from '../../../state'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'
import { renderFormatMessage } from 'helpers/intl'
import TimeOfDayModal from '../modal/TimeOfDayModal'

interface props {
  listing: NewListing
}

const ListingLoiteringStatus: React.FC<props> = ({ listing }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()
  const { timeOfDayText } = usePricingSchemasTranslation()

  const [showTimeOfDay, setShowTimeOfDay] = useState(false)

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const { loitering } = listing

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  const { timeOfDayLoiteringWeekday, timeOfDayLoiteringWeekend } = loitering

  const showTimeOfDayModal = () => setShowTimeOfDay(true)

  const handleModal = () => setShowTimeOfDay(false)

  const renderTimeBased = (loitering: Loitering, isSmallScreen: string, isLargeScreen: string) => {
    const isTODPriceWeekdaySame = timeOfDayLoiteringWeekday?.every((value) => value === timeOfDayLoiteringWeekday[0])
    const isTODPriceWeekendSame = timeOfDayLoiteringWeekend?.every((value) => value === timeOfDayLoiteringWeekend[0])

    const samePenaltyAllday =
      ((isTODPriceWeekdaySame === undefined && isTODPriceWeekendSame === undefined) ||
        (isTODPriceWeekdaySame && isTODPriceWeekendSame)) &&
      timeOfDayLoiteringWeekday?.[0] === timeOfDayLoiteringWeekend?.[0]
    return (
      <div className={isLargeScreen}>
        <div
          onClick={!samePenaltyAllday ? showTimeOfDayModal : undefined}
          className={`${isSmallScreen} ${!samePenaltyAllday ? 'pointer require-underline' : ''}`}
        >
          {renderFormatMessage('dashboard.text.loitering', 'Loitering')}:{' '}
          {samePenaltyAllday
            ? `${
                timeOfDayLoiteringWeekday?.[0]
                  ? formatCentsPrice(timeOfDayLoiteringWeekday[0])
                  : formatPrice(Number(loitering.loiteringPenalty))
              }/hr`
            : timeOfDayText}
        </div>
        {renderTimeLimitIdling(loitering)}
      </div>
    )
  }

  const renderStatusBased = (loitering: Loitering, isSmallScreen: string, isLargeScreen: string) => (
    <div className={isLargeScreen}>
      {renderPenalty(isSmallScreen, loitering)}
      {renderGracePeriodCharging(loitering)}
    </div>
  )

  const renderPenalty = (isSmallScreen: string, loitering: Loitering) => (
    <div className={isSmallScreen}>{`Loitering: ${formatPrice(Number(loitering.loiteringPenalty))}/hr`}</div>
  )

  const renderTimeLimitIdling = (loitering: Loitering) => (
    <div className="opacity-06 paragraph-04-light">
      {loitering.loiteringTimeLimit ? `After ${timeConvert(loitering.loiteringTimeLimit)} of charging` : ''}
    </div>
  )

  const renderGracePeriodCharging = (loitering: Loitering) => (
    <div className="opacity-06 paragraph-04-light">
      {loitering.loiteringGracePeriod ? `After ${timeConvert(loitering.loiteringGracePeriod)} of idling` : null}
    </div>
  )
  return (
    <>
      {showTimeOfDay && timeOfDayLoiteringWeekend && timeOfDayLoiteringWeekday && (
        <TimeOfDayModal
          title={listing.title}
          priceType={'hr'}
          loitering
          isModalOpen={showTimeOfDay}
          onModalOpenClose={handleModal}
          timeOfDayPriceWeekend={timeOfDayLoiteringWeekend}
          timeOfDayPriceWeekday={timeOfDayLoiteringWeekday}
        />
      )}
      {loitering.loiteringStatus === 'no_penalty' &&
        renderFormatMessage('dashboard.text.noLoiteringPenalty', 'No Loitering Penalty')}
      {loitering.loiteringStatus === 'time_based' && renderTimeBased(loitering, isSmallScreen, isLargeScreen)}
      {loitering.loiteringStatus === 'status_based' && renderStatusBased(loitering, isSmallScreen, isLargeScreen)}
    </>
  )
}

export default ListingLoiteringStatus
