import { Input, Modal, Table } from 'antd'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { AccessPlan } from 'models/access-plan'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { CreateAccessPlanModalWrapper } from 'atom/chargers'

interface props {
  visible: boolean
  selectedAccessPlanDrivers: AccessPlan | undefined
  onCancel: () => void
}

export const AccessPlanDriversModal: React.FC<props> = ({ visible, selectedAccessPlanDrivers, onCancel }) => {
  const { accessPlanNameText, currentAssignedDriversText, assignedDriversText } = useAccessPlanTranslation()
  const { emailTxt } = useDiscountSchemaTranslation()
  const pageSize = 7

  const EmailTableColumn = [
    {
      title: emailTxt,
      dataIndex: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
  ]

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={500}
      bodyStyle={{ padding: '0px' }}
      footer={null}
      title={<div className="heading-05-regular">{assignedDriversText}</div>}
    >
      <CreateAccessPlanModalWrapper>
        <div className="modal-body">
          <div className="heading-06-regular" style={{ marginBottom: '16px' }}>
            {accessPlanNameText}
          </div>
          <div style={{ marginBottom: '32px' }}>
            <Input disabled value={selectedAccessPlanDrivers?.name} />
          </div>
          <span className="heading-06-regular">
            {currentAssignedDriversText.replace('-1', `${selectedAccessPlanDrivers?.driverEmails.length}`)}
          </span>
          <Table
            style={{ marginTop: '16px' }}
            columns={EmailTableColumn}
            dataSource={selectedAccessPlanDrivers?.driverEmails.map((email) => ({ email }))}
            pagination={{
              hideOnSinglePage: true,
              position: ['bottomCenter'],
              pageSize: pageSize,
            }}
            className="shadow-table"
            rowKey="userEmail"
          />
        </div>
      </CreateAccessPlanModalWrapper>
    </Modal>
  )
}
