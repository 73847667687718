import { loiteringStatusType } from '../models/loitering'
import { Loitering } from '../models/loitering'

export const mockLoiterings: { [key: string]: Loitering } = {
  noPenalty: {
    loiteringStatus: loiteringStatusType[0], // no penalty
    loiteringTimeLimit: 500,
    loiteringGracePeriod: 300,
    loiteringPenalty: 400,
    timeOfDayLoiteringWeekday: [...Array(48).fill(100)],
    timeOfDayLoiteringWeekend: [...Array(48).fill(100)],
  },
  timeBasedWithCustomTimeOfDay: {
    loiteringStatus: loiteringStatusType[1], // time-based
    loiteringTimeLimit: 500,
    loiteringGracePeriod: 300,
    loiteringPenalty: 400,
    timeOfDayLoiteringWeekday: [...Array(20).fill(100), ...Array(20).fill(200), ...Array(8).fill(300)],
    timeOfDayLoiteringWeekend: [...Array(20).fill(100), ...Array(20).fill(200), ...Array(8).fill(300)],
  },
  timeBasedWithEverydayTOD: {
    loiteringStatus: loiteringStatusType[1], // time-based
    loiteringTimeLimit: 6000,
    loiteringGracePeriod: 400,
    loiteringPenalty: 400,
    timeOfDayLoiteringWeekday: [...Array(48).fill(100)],
    timeOfDayLoiteringWeekend: [...Array(48).fill(300)],
  },
  statusBased: {
    loiteringStatus: loiteringStatusType[1], // status_based
    loiteringTimeLimit: 500,
    loiteringGracePeriod: 300,
    loiteringPenalty: 400,
    timeOfDayLoiteringWeekday: [...Array(48).fill(100)],
    timeOfDayLoiteringWeekend: [...Array(48).fill(100)],
  },
  timeBasedWithoutTOD: {
    loiteringStatus: loiteringStatusType[1], // time_based
    loiteringTimeLimit: 200,
    loiteringGracePeriod: 500,
    loiteringPenalty: 6,
  },
}
