import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Col, Space } from 'antd'

import { editSVG } from 'assets/svg/edit'
import { ButtonModal } from 'atom/button'
import CoreBadge from 'components/badge/Badge'
import { convertMinutesToTime } from 'helpers/date-format'
import { describeSchedule } from 'helpers/schedule'
import { renderFormatMessage } from 'helpers/intl'
import { useAccessPlanTranslation } from 'hooks/translation/useAccessPlanTranslation'
import { useListingAccessTranslation } from 'hooks/translation/useListingAccessTranslation'
import { AccessPlan, AccessPlanSchedule, AccessPlanType } from 'models/access-plan'
import { ListingRef } from 'models/listing'
import { theme } from 'theme'

export const AccessPlanColumns = (
  expandableRowKeys: { [dataKey: string]: boolean },
  expanded: { [dataKey: string]: boolean },
  handleRowExpand: (rowId: number) => () => void,
  setSelectedAccessPlanDrivers: (accessPlan: AccessPlan) => void,
  openEditModal: (accessPlan: AccessPlan) => void,
) => {
  const { accessCodeText, emailDomainText, unrestrictedText } = useAccessPlanTranslation()
  const { privateRestrictedText } = useListingAccessTranslation()

  const checkExceedsTwoLines = (row: any): boolean => {
    // Safely access properties with optional chaining and provide default values as needed
    const lineCounts = [
      determineLineCount(row.name ?? ''),
      determineLineCount(row.accessType ?? ''),
      determineLineCount(row.accessCode ?? ''),
      row.emailDomains?.length > 0 && row.accessCode
        ? row.emailDomains?.length + determineLineCount(row.accessCode ?? '')
        : determineLineCount(row.accessCode ?? '') + (row.emailDomains?.length ?? 0),
      row.listingIds?.length ?? 0,
    ]

    // Assume schedule is a complex structure that may require more careful handling
    if (row.schedule) {
      lineCounts.push(
        ...Object.values(row.schedule).flatMap((d: any) =>
          d.map((r: any) => determineLineCount(convertMinutesToTime(r[0]) + ' - ' + convertMinutesToTime(r[1]))),
        ),
      )
    }
    return lineCounts.some((count) => count > 2)
  }

  const determineLineCount = (text: string) => {
    const element = document.createElement('div')
    element.style.visibility = 'hidden'
    element.style.width = '200px' // Assume width based on your column width
    element.innerText = text
    document.body.appendChild(element)
    const lineCount = element.scrollHeight / parseFloat(getComputedStyle(element).lineHeight)
    document.body.removeChild(element)
    return lineCount
  }

  return [
    {
      title: renderFormatMessage('dashboard.text.name', 'Name'),
      dataIndex: 'name',
      key: 'name',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (text: string) => text,
    },
    {
      title: renderFormatMessage('dashboard.createNewListing.reviewStep.listing.accessType', 'Access Type'),
      dataIndex: 'accessType',
      key: 'accessType',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (accessType: AccessPlanType) => (accessType === 'restricted' ? privateRestrictedText : unrestrictedText),
    },
    {
      title: renderFormatMessage('dashboard.peakShaving.programPage.program.schedule', 'Schedule'),
      dataIndex: 'schedules',
      key: 'schedules',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (schedule: AccessPlanSchedule) => <div>{describeSchedule(schedule)}</div>,
    },
    {
      title: renderFormatMessage('dashboard.accessPlan.accessDetails', 'Access Details'),
      key: 'accessDetails',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (accessPlan: AccessPlan, row: any) =>
        accessPlan.enableAccessCode || accessPlan.enableEmailDomains ? (
          <div>
            {accessPlan.enableAccessCode && (
              <div>
                <div>{accessPlan.accessCode}</div>
                <div className="paragraph-02-regular" style={{ color: theme.colors.softBlackShade, marginTop: '3px' }}>
                  {accessCodeText}
                </div>
              </div>
            )}
            {accessPlan.enableEmailDomains && (
              <div style={{ marginTop: accessPlan.accessCode ? '16px' : '0px' }}>
                {accessPlan.emailDomains.map((email) => (
                  <div>{email}</div>
                ))}
                <div className="paragraph-02-regular" style={{ color: theme.colors.softBlackShade, marginTop: '3px' }}>
                  {emailDomainText}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={checkExceedsTwoLines(row) ? 'multi-line' : 'single-line'}>-</div>
        ),
    },
    {
      title: renderFormatMessage('dashboard.accessPlan.drivers', 'Drivers'),
      key: 'driverEmails',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (accessPlan: AccessPlan) =>
        accessPlan.driverEmails.length === 0 ? (
          `-`
        ) : (
          <span
            onClick={() => setSelectedAccessPlanDrivers(accessPlan)}
            style={{ color: theme.colors.deepGreen, cursor: 'pointer' }}
          >
            {accessPlan.driverEmails.length}
          </span>
        ),
    },
    {
      title: renderFormatMessage('dashboard.text.chargerId', 'Charger ID'),
      key: 'listings',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (accessPlan: AccessPlan) => (
        <Space wrap>
          {accessPlan.listings
            ?.slice(0, expandableRowKeys[accessPlan.id] && !expanded[accessPlan.id] ? 5 : accessPlan.listings.length)
            .map((listing: ListingRef) => (
              <CoreBadge key={listing.id} title={listing.title} />
            ))}
        </Space>
      ),
    },
    {
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (accessPlan: AccessPlan) =>
        expandableRowKeys[accessPlan.id] && (
          <Col>
            {expanded[accessPlan.id] ? (
              <MinusCircleOutlined onClick={handleRowExpand(accessPlan.id)} />
            ) : (
              <PlusCircleOutlined onClick={handleRowExpand(accessPlan.id)} />
            )}
          </Col>
        ),
    },
    {
      title: '',
      key: 'action',
      width: '8%',
      onCell: (record: any) => ({
        className: checkExceedsTwoLines(record) ? 'multi-line' : 'single-line',
      }),
      render: (accessPlan: AccessPlan) => (
        <ButtonModal type="link" icon={editSVG} click={() => openEditModal(accessPlan)} />
      ),
    },
  ]
}
