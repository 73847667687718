import { Button, PageHeader } from 'antd'
import { Link } from 'react-router-dom'

import { Box } from 'atom/box'
import { BackgroundContainer } from 'atom/layout/backgroundContainer'

import { AlertError } from 'components/error'
import { Links } from 'components/routes/paths'
import { withAuthenticatedLayout } from 'components/layouts/layout'

import TenantsTable from 'features/tenants/TenantsTable'
import TenantsAllSelectors from 'features/tenants/TenantsAllSelectors'

import { useTenants } from 'hooks/useTenants'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'

const TenantsBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')

  const { createNewTenantBtn } = useTenantTranslation()

  const {
    loading,
    tenants,
    error,
    pagination,
    filter,
    handleFilterChange,
    fetchTenants,
    handlePaginationChange,
    handleLocationChange,
  } = useTenants()

  const renderTitle = () => (
    <TenantsAllSelectors
      loading={loading}
      filter={filter}
      tenants={tenants}
      onFilterChange={handleFilterChange}
      fetchData={fetchTenants()}
      onLocationChange={handleLocationChange}
      dropdownClassName="not-in-modal-selector"
    />
  )

  return (
    <BackgroundContainer>
      <PageHeader
        title={renderTitle()}
        extra={
          <Button type="primary" disabled={loading}>
            <Link to={Links.tenantsCreate()}> {createNewTenantBtn}</Link>
          </Button>
        }
      />
      <AlertError error={error} />
      <Box>
        <TenantsTable
          loading={loading}
          tenants={tenants}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
        />
      </Box>
    </BackgroundContainer>
  )
}

export const TenantsPage = withAuthenticatedLayout(TenantsBasePage)
