import { Steps } from 'antd'
import { StepsWrapper } from 'atom/price'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

interface props {
  current: number
  onlyPricing?: boolean
}

export const StepperTitle: React.FC<props> = ({ current, onlyPricing = false }) => {
  const { Step } = Steps
  const { discountPlanText, pricingPlanText } = useGeneralTranslation()
  const normalStatus = current === 0 ? 'process' : current > 0 ? 'wait' : 'process'
  const status = onlyPricing ? 'process' : normalStatus
  return (
    <StepsWrapper>
      <Steps current={current}>
        <Step title={pricingPlanText} status={status} />
        {!onlyPricing && <Step title={discountPlanText} />}
      </Steps>
    </StepsWrapper>
  )
}
