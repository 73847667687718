import { useEffect, useState } from 'react'
import moment from 'moment'

import { TenantRef } from '../models/tenant'
import { SwtchError } from '../models/error'

import { GetMetabaseReport } from '../services/data-provider/reports'
import { MasterAccountRef } from 'models/master-account'
import { useAppState } from 'state'

export const useReports = () => {
  const { isMockUpEnabled } = useAppState()

  const [startDate, setStartDate] = useState<moment.Moment>(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState<moment.Moment>(moment())
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [url, setUrl] = useState('')

  const [filters, setFilters] = useState({
    tenants: [] as TenantRef[],
    listingTitles: [] as string[],
    selectedCountries: [] as string[],
    selectedProvinces: [] as string[],
    masterAccounts: [] as MasterAccountRef[],
  })

  useEffect(() => {
    getReportsFromMetabase()
  }, [])

  const getReportsFromMetabase = () => {
    setError(undefined)
    setLoading(true)

    GetMetabaseReport(isMockUpEnabled, {
      startDate: startDate,
      endDate: endDate,
      tenants: filters.tenants,
      listingTitles: filters.listingTitles,
      selectedCountries: filters.selectedCountries,
      selectedProvinces: filters.selectedProvinces,
      masterAccounts: filters.masterAccounts,
    })
      .then((resp) => setUrl(resp.data[0].url))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) setStartDate(dates[0])
    if (dates[1] !== endDate) setEndDate(dates[1])
  }

  // Example of how to update filters state
  const handleUpdateFilters = (newFilters: Partial<typeof filters>) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }))
  }

  const handleLocation = (chosenCountries: string[], chosenProvinces: string[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedCountries: chosenCountries,
      selectedProvinces: chosenProvinces,
    }))
  }

  return {
    error,
    loading,
    url,
    startDate,
    endDate,
    filters,
    getReportsFromMetabase,
    handleChangeDate,
    handleLocation,
    handleUpdateFilters,
  }
}
