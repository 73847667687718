import CoreTabs from 'components/tabs/core-tabs'
import { useRef } from 'react'
import { Col, Row } from 'antd'
import { useDashboardTranslation } from '../../../hooks/translation/useDashboardTranslation'
import { SubSection } from '../../../atom/box'
import { ConsumptionInsightContainer, ConsumptionInsightMetaContainer } from '../ContainerStyles/homepageStyles'
import { EmptyData } from '../../../components/redesign/emptyData'
import { DailyProfile, TrxSummary } from '../../../models/transaction'
import { dollarSVG } from '../../../assets/svg/currency'
import { uniqueUserSVG } from '../../../assets/svg/user'
import { HomePageComumptionInsightLoadProfileChart } from '../charts/active-users/consumption-insight-load-profile'
import { exchangeSVG } from '../../../assets/svg/exchange'
import { boltSVG } from '../../../assets/svg/bolt'
import { useMediaQuery } from 'react-responsive'
import CoreConsumptionCard from './CoreConsumptionCard'

interface props {
  loading: boolean
  timeZone: string
  trxProfileSummary: TrxSummary | undefined
  averageDailyLoadProfileEmpty: boolean
  consumptionInsightLoadProfileTabKey: string
  dailyProfiles: DailyProfile[] | undefined
  handleTabChangeConsumptionInsightTab: (activeKey: string) => void
}

const ConsumptionInsightsSection: React.FC<props> = ({
  loading,
  timeZone,
  trxProfileSummary,
  averageDailyLoadProfileEmpty,
  consumptionInsightLoadProfileTabKey,
  dailyProfiles,

  handleTabChangeConsumptionInsightTab,
}) => {
  const {
    dayText,
    consumptionInsightsText,
    averageDailyLoadProfileText,
    uniqueUsersText,
    averageNumberOfTransactionsText,
    allText,
    weekdayText,
    weekendText,
    averageEnergyConsumptionText,
    averageTransactionAmountText,
  } = useDashboardTranslation()
  const isResponsiveBorderStyling = useMediaQuery({ minWidth: 0, maxWidth: 991 })
  const chartComponent = useRef(null)
  const consumptionInfo = [
    {
      title: allText,
      key: 'All',
      content: averageDailyLoadProfileEmpty ? (
        <EmptyData />
      ) : (
        dailyProfiles && (
          <HomePageComumptionInsightLoadProfileChart
            tabKey={consumptionInsightLoadProfileTabKey}
            dailyProfile={dailyProfiles}
            timezone={timeZone}
            chartRef={chartComponent}
          />
        )
      ),
    },
    {
      title: weekdayText,
      key: 'Weekday',
      content: averageDailyLoadProfileEmpty ? (
        <EmptyData />
      ) : (
        dailyProfiles && (
          <HomePageComumptionInsightLoadProfileChart
            tabKey={consumptionInsightLoadProfileTabKey}
            dailyProfile={dailyProfiles}
            timezone={timeZone}
            chartRef={chartComponent}
          />
        )
      ),
    },
    {
      title: weekendText,
      key: 'Weekend',
      content: averageDailyLoadProfileEmpty ? (
        <EmptyData />
      ) : (
        dailyProfiles && (
          <HomePageComumptionInsightLoadProfileChart
            tabKey={consumptionInsightLoadProfileTabKey}
            dailyProfile={dailyProfiles}
            timezone={timeZone}
            chartRef={chartComponent}
          />
        )
      ),
    },
  ]

  const consumptionCardInfos = [
    {
      title: uniqueUsersText,
      icon: uniqueUserSVG,
      subTitle: trxProfileSummary && trxProfileSummary.usersTotal,
    },
    {
      title: averageNumberOfTransactionsText,
      icon: exchangeSVG,
      subTitle: trxProfileSummary && trxProfileSummary.avgTxnDay,
      suffix: `/ ${dayText}`,
    },
    {
      title: averageEnergyConsumptionText,
      icon: boltSVG,
      subTitle:
        trxProfileSummary &&
        `${Number.parseFloat(`${trxProfileSummary.avgEnergyConsumePerTxn / 1000}`).toFixed(2)} kWh`,
      suffix: `/ transaction`,
    },
    {
      title: averageTransactionAmountText,
      icon: dollarSVG,
      subTitle: trxProfileSummary && `$${trxProfileSummary.avgUserSpend / 100}`,
      suffix: `/ transaction`,
      hideBorderBottom: true,
    },
  ]
  return (
    <SubSection>
      <Row
        align="middle"
        justify="start"
        gutter={[15, 15]}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
      >
        <Col span={24}>
          <h5 className="section-sub-header" style={{ marginBottom: '14px' }}>
            {consumptionInsightsText}
          </h5>
        </Col>
      </Row>
      <ConsumptionInsightContainer className={averageDailyLoadProfileEmpty ? 'empty-consumption-container' : ''}>
        <Row justify="start" gutter={[0, 16]} className="content">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={18}
            xl={18}
            xxl={18}
            style={{
              borderRight: isResponsiveBorderStyling ? '' : '1px solid #dedede',
              borderBottom: !isResponsiveBorderStyling ? '' : '1px solid #dedede',
              backgroundColor: '#fff',
            }}
          >
            <CoreTabs
              title={averageDailyLoadProfileText}
              className="utlization-insight-tabs consumption right-padding-tab"
              downloadable={{ chart: chartComponent }}
              onChange={handleTabChangeConsumptionInsightTab}
              activeKey={consumptionInsightLoadProfileTabKey}
              tabs={consumptionInfo}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
            <ConsumptionInsightMetaContainer>
              {consumptionCardInfos.map((info, index) => (
                <CoreConsumptionCard
                  key={index}
                  icon={info.icon}
                  title={info.title}
                  loading={loading}
                  subTitle={info.subTitle}
                  suffix={info?.suffix}
                  hideBorderBottom={info?.hideBorderBottom}
                />
              ))}
            </ConsumptionInsightMetaContainer>
          </Col>
        </Row>
      </ConsumptionInsightContainer>
    </SubSection>
  )
}

export default ConsumptionInsightsSection
